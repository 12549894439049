import React, { useEffect, useState } from "react";
import axios from "../../axiosInstance";
import {
  Button,
  Input,
  Autocomplete,
  AutocompleteItem,
  Spacer,
} from "@nextui-org/react";
import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function SnusForm() {
  const [snusOptions, setSnusOptions] = useState({});
  const [newFlavor, setNewFlavor] = useState("");
  const [newFlavorStr, setNewFlavorStr] = useState();
  const [newBrand, setNewBrand] = useState("");

  useEffect(() => {
    const fetchSnus = async () => {
      try {
        const response = await axios.get("/snus");
        // Organize locations by area
        const areaGroups = response.data.reduce((acc, snus) => {
          acc[snus.brand] = acc[snus.brand] || [];
          acc[snus.brand].push(snus);
          return acc;
        }, {});
        setSnusOptions(areaGroups);
      } catch (error) {
        console.error("Failed to fetch locations:", error);
      }
    };

    fetchSnus();
  }, []);

  // Initialize state to store maxWidth
  const [maxWidth, setMaxWidth] = useState(
    window.innerWidth > 768 ? "600px" : "300px"
  );

  // Effect to add window resize listener
  useEffect(() => {
    const handleResize = () => {
      // Update maxWidth based on window width
      setMaxWidth(window.innerWidth > 768 ? "600px" : "300px");
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array means this effect runs once on mount

  const handleNewFlavorSubmit = async () => {
    if (newFlavor === "" || !newFlavorStr || newBrand === "") {
      toast.error("Please fill out all fields", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "dark",
      });
      return;
    }

    const formData = new FormData();
    formData.append("newBrand", newBrand);
    formData.append("newFlavor", newFlavor);
    formData.append("newFlavorStr", newFlavorStr);

    try {
      const response = await axios.post("/snus/createFlavor", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Submission successful", response.data);
      toast.success("Successfully created new flavor", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "dark",
      });
      setTimeout(() => {
        window.location.href = "/snusoclock";
      }, 500);
    } catch (error) {
      console.error("Failed to submit form:", error);

      // Extracting error message from the response
      const errorMessage =
        error.response && error.response.data
          ? error.response.data
          : "Unknown error occurred";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const onInputChange = (value) => {
    setNewBrand(value);
  };

  return (
    <div>
      <Navbar active={""} />
      <div
        style={{
          maxWidth: maxWidth,
          margin: "0 auto",
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div style={{ margin: "5rem 0" }}>
          <h1
            style={{
              fontSize: "2rem",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            New flavor
          </h1>
          <form>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Autocomplete
                type="text"
                label="Select a brand"
                allowsCustomValue
                onInputChange={onInputChange}
                listboxProps={{ className: "dark text-white" }}
                popoverProps={{ className: "dark" }}
              >
                {Object.keys(snusOptions).map((brand) => (
                  <AutocompleteItem key={brand} value={brand}>
                    {brand}
                  </AutocompleteItem>
                ))}
              </Autocomplete>
              <Input
                type="text"
                label="New flavor"
                value={newFlavor}
                onChange={(e) => setNewFlavor(e.target.value)}
                listboxProps={{ className: "dark text-white" }}
                popoverProps={{ className: "dark" }}
              />
              <Input
                type="number"
                placeholder="mg/g"
                label="Strength"
                value={newFlavorStr}
                onChange={(e) => setNewFlavorStr(e.target.value)}
                isRequired
                listboxProps={{ className: "dark text-white" }}
                popoverProps={{ className: "dark" }}
              />
            </div>
            <Spacer y={5} />
            <Button
              color="primary"
              variant="solid"
              onPress={handleNewFlavorSubmit}
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SnusForm;
