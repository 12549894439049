import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import axios from "../../axiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import {
  CircularProgress,
  Modal,
  ModalContent,
  ModalBody,
  useDisclosure,
  ModalFooter,
  ModalHeader,
  Chip,
  Avatar,
} from "@nextui-org/react";
import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleXmark,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const PhotoLib = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [pageable, setPageable] = useState({ page: 0, size: 15 });
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Track if more images are available
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [openImageIndex, setOpenImageIndex] = useState(null);
  const [images, setImages] = useState([]);
  const [jayCons, setJayCons] = useState(null);
  const [users, setUsers] = useState(() => {
    const savedUsers = localStorage.getItem("users");
    return savedUsers ? JSON.parse(savedUsers) : [];
  });
  const [profilePics, setProfilePics] = useState({}); // State to hold profile pictures

  const fetchImageInfo = async (imageId) => {
    setJayCons(null);
    try {
      const response = await axios.get(`/getJayConsByImage?id=${imageId}`);
      setJayCons(response.data); // assuming the response contains the jayCons object
    } catch (error) {
      console.error("Failed to fetch image info", error);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/getAllUsers");
        setUsers(response.data);
        localStorage.setItem("users", JSON.stringify(response.data));
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchProfilePics = async () => {
      const profilePicsUpdates = {};
      for (const user of users) {
        const storedPic = localStorage.getItem(user + "_profilePic");
        profilePicsUpdates[user] = storedPic
          ? "data:image/jpeg;base64," + storedPic
          : null;
      }

      setProfilePics(profilePicsUpdates);

      for (const user of users) {
        try {
          const response = await axios.get(`/getProfilePic?username=${user}`);
          if (
            response.data !== "" &&
            response.data !== localStorage.getItem(user + "_profilePic")
          ) {
            profilePicsUpdates[user] =
              "data:image/jpeg;base64," + response.data;
            localStorage.setItem(user + "_profilePic", response.data);
          }
        } catch (error) {
          console.error("Failed to fetch profile pic for", user, ":", error);
        }
      }

      setProfilePics(profilePicsUpdates);
    };

    if (users.length > 0) {
      fetchProfilePics();
    }
  }, [users]);

  useEffect(() => {
    const fetchImages = async () => {
      if (!hasMore) return;

      setLoading(true);
      try {
        const response = await axios.get("/jayConsImages", {
          params: { page: pageable.page, size: pageable.size },
        });
        const ids = response.data;
        if (ids.length < pageable.size) {
          setHasMore(false); // No more images available
        }
        await fetchImageBlobUrls(ids); // Load all images at once
      } finally {
        setLoading(false);
      }
    };

    if (pageable.page >= 0) {
      // Ensures the initial page is fetched and subsequent pages
      fetchImages();
    }
  }, [pageable, hasMore]); // Effect runs when pageable changes

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (loading || !hasMore) return; // Do not handle scroll if loading or no more images
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.scrollHeight
      ) {
        if (!loading) {
          setPageable((prev) => ({ ...prev, page: prev.page + 1 }));
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, hasMore]); // Only re-bind event listener when loading state changes

  const fetchImageBlobUrls = async (ids) => {
    const urlPromises = ids.map(async (id) => {
      try {
        const response = await axios.get(`/getImageBinary`, {
          params: { id },
          responseType: "blob", // This tells axios to expect a blob in the response
        });
        const url = URL.createObjectURL(response.data);
        return { id, url };
      } catch (error) {
        console.error("Error fetching image with ID:", id, error);
        return { id, url: null };
      }
    });

    const imageObjects = await Promise.all(urlPromises);
    setImages((prevImages) => {
      // Create a map to ensure no duplicates
      const imagesMap = new Map(prevImages.map((img) => [img.id, img]));
      imageObjects.forEach((img) => imagesMap.set(img.id, img));
      return Array.from(imagesMap.values());
    });
  };

  const widthDisplay = isMobile ? "100%" : "50%";

  function handleImageClick(id) {
    const index = images.findIndex((image) => image.id === id);
    setOpenImageIndex(index);
    // Fetch info when an image is clicked
    onOpen();
    fetchImageInfo(id);
  }

  const navigateImages = (direction) => {
    setOpenImageIndex((prevIndex) => {
      let newIndex = prevIndex + direction;
      if (newIndex < 0) newIndex = 0; // Prevent moving before the first image
      else if (newIndex >= images.length) newIndex = images.length - 1; // Prevent moving past the last image

      fetchImageInfo(images[newIndex].id);
      return newIndex;
    });
  };

  function isFirstLoad() {
    return images.length === 0 ? "50dvh" : "100%";
  }

  return (
    <div>
      <FontAwesomeIcon
        icon={faCircleArrowRight}
        onClick={(event) => navigateImages(1, event)}
        style={{
          display: isOpen ? "inline" : "none",
          position: "fixed",
          right: 10,
          height: "3rem",
          margin: "50vh 0",
          zIndex: 1000,
          cursor: "pointer",
          color: "#1e1e1e",
          backgroundColor: "rgb(216 216 216)",
          borderRadius: "10rem",
          padding: "0.2rem",
        }}
      />
      <FontAwesomeIcon
        icon={faCircleArrowLeft}
        onClick={(event) => navigateImages(-1, event)}
        style={{
          display: isOpen ? "inline" : "none",
          position: "fixed",
          left: 10,
          height: "3rem",
          margin: "50vh 0",
          zIndex: 1000,
          cursor: "pointer",
          color: "#1e1e1e",
          backgroundColor: "rgb(216 216 216)",
          borderRadius: "10rem",
          padding: "0.2rem",
        }}
      />
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "5rem 0 1rem 0",
          justifyContent: "flex-start",
          overflowX: "auto",
        }}
      >
        <Navbar active="Photo lib" />
        <h1 style={{ margin: "1rem 0 2rem 0", fontSize: "2rem" }}>
          <FontAwesomeIcon icon={faImage} /> Photo Library
        </h1>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            width: widthDisplay,
            padding: "0rem",
            borderRadius: "2rem",
          }}
        >
          {images.map((image) =>
            image.url ? (
              <div
                key={`${image.id}_page_${pageable.page}`}
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "black",
                  minHeight: "160px",
                  maxHeight: "250px",
                  border: "0.1rem solid black",
                }}
              >
                <img
                  src={image.url}
                  alt="Gallery"
                  style={{ width: "100%", height: "auto" }}
                  onClick={() => handleImageClick(image.id)}
                />
              </div>
            ) : (
              <div
                key={image.id} // Also use the unique ID for placeholders
                style={{
                  width: "100%",
                  height: "120px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#1e1e1e",
                }}
              >
                <CircularProgress aria-label="Loading..." />
              </div>
            )
          )}
        </div>{" "}
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: isFirstLoad(),
              alignItems: "center",
              margin: "2rem",
            }}
          >
            <CircularProgress aria-label="Loading..." />
          </div>
        )}
        <Modal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          size="lg"
          backdrop="blur"
          placement="center"
          hideCloseButton={true}
          style={{ width: "90%", backgroundColor: "#1e1e1e", color: "white" }}
          isDismissable={false}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader>
                  <p>Uploaded image</p>
                </ModalHeader>
                <ModalBody style={{ padding: 0 }}>
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    style={{
                      position: "absolute", // Changed from 'fixed' to 'absolute'
                      right: "0.5rem", // Right align with a 1rem space
                      top: "0.5rem", // Top align with a 1rem space
                      height: "2rem",
                      cursor: "pointer", // Ensure it's clear it's clickable
                      color: "black",
                      backgroundColor: "#DC143C",
                      borderRadius: "10rem",
                      padding: "0.2rem",
                    }}
                    onClick={onClose}
                  />
                  <img
                    src={images[openImageIndex].url}
                    alt="Gallery"
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  {jayCons ? (
                    <div className="dark" style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          alignContent: "flex-end",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        <div style={{ height: "100%", marginRight: "1rem" }}>
                          <p>Users:</p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            width: "100%",
                            gap: "0.5rem",
                          }}
                        >
                          {jayCons.users.map((user) => (
                            <Chip
                              key={user}
                              variant="solid"
                              color="primary"
                              className="dark"
                              avatar={
                                <Avatar
                                  name={user}
                                  src={
                                    profilePics[user]
                                      ? `${profilePics[user]}`
                                      : ""
                                  }
                                  fallback={<FontAwesomeIcon icon={faUser} />}
                                />
                              }
                            >
                              {user}
                            </Chip>
                          ))}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignContent: "flex-end",
                          justifyContent: "flex-start",
                          width: "100%",
                          marginTop: "0.5rem",
                        }}
                      >
                        <div style={{ height: "100%", marginRight: "1rem" }}>
                          <p>Info:</p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            width: "100%",
                            gap: "0.5rem",
                          }}
                        >
                          {" "}
                          {jayCons.location !== "Unknown" && (
                            <Chip style={{ backgroundColor: "rgb(0 150 134)" }}>
                              {jayCons.location}
                            </Chip>
                          )}
                          <Chip
                            style={{
                              backgroundColor: jayCons.hash
                                ? "#964B00"
                                : "darkgreen",
                            }}
                          >
                            {jayCons.hash ? "Hash" : "Bud"}
                          </Chip>
                          <Chip color="secondary">
                            {jayCons.type === "JOINT"
                              ? jayCons.size
                              : jayCons.type}
                          </Chip>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress aria-label="Loading..." />
                    </div>
                  )}
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};

export default PhotoLib;
