import "./App.css";
import LoginPage from "./components/LoginPage/LoginPage";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import RequireAuth from "./requireAuth";
import { ToastContainer } from "react-toastify";
import "./index.css";
import "video.js/dist/video-js.css";
import "react-toastify/dist/ReactToastify.css";
import Home from "./components/Home/Home";
import * as React from "react";
import CreateEntry from "./components/CreateEntry/CreateEntry";
import Leaderboard from "./components/Leaderboard/Leaderboard";
import LiveFeed from "./components/LiveFeed/LiveFeed";
import Settings from "./components/Settings/Settings";
import AdminPanel from "./components/Admin/AdminPanel";
import RequireAdmin from "./components/Admin/RequireAdmin";
import Help from "./components/Help/Help";
import EditEntry from "./components/EditEntry/EditEntry";
import PostFeed from "./components/PostFeed/PostFeed";
import CreatePost from "./components/CreatePost/CreatePost";
import NotificationPage from "./components/Notification/NotificationPage";
import PhotoLib from "./components/PhotoLib/PhotoLib";
import UserSelect from "./components/User/UserPage";
import UserCompare from "./components/User/UserCompare";
import CreateSnusTime from "./components/Snus/CreateSnusTime";
import SnusFeed from "./components/LiveFeed/SnusFeed";
import CreateNewFlavor from "./components/Snus/CreateNewFlavor";
import MapPage from "./components/Map/MapPage";

function App() {
  // State to manage theme mode

  // Create a theme instance based on the current mode

  const ProtectedRoutes = () => {
    return (
      <RequireAuth>
        <Outlet />
      </RequireAuth>
    );
  };
  const AdminRoutes = () => {
    return (
      <RequireAdmin>
        <Outlet />
      </RequireAdmin>
    );
  };

  return (
    <div className="app-container">
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          {/* Wrap the routes that require authentication under a single ProtectedRoutes */}
          <Route element={<ProtectedRoutes />}>
            <Route path="/stats" element={<Home />} />
            <Route path="/createEntry" element={<CreateEntry />} />
            <Route path="/createPost" element={<CreatePost />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/liveFeed" element={<LiveFeed />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/help" element={<Help />} />
            <Route path="/editEntry" element={<EditEntry />} />
            <Route path="/" element={<PostFeed />} />
            <Route path="/notifications" element={<NotificationPage />} />
            <Route path="/photoLibrary" element={<PhotoLib />} />
            <Route path="/users" element={<UserSelect />} />
            <Route path="/compare" element={<UserCompare />} />
            <Route path="/snusOclock" element={<CreateSnusTime />} />
            <Route path="snusFeed" element={<SnusFeed />} />
            <Route path="newFlavor" element={<CreateNewFlavor />} />
            <Route path="/map" element={<MapPage />} />
            <Route path="/admin" element={<AdminRoutes />}>
              <Route path="" element={<AdminPanel />} />
            </Route>
          </Route>
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}
export default App;
