import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import {
  Divider,
  Spacer,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Table,
  TableColumn,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleQuestion,
  faFlag,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import axios from "../../axiosInstance";
import { toast } from "react-toastify";

const Help = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [globalData, setGlobalData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const widthDisplay = window.innerWidth < 768 ? "90%" : "50%";

  const isDeleteButtonDisabled = () => {
    if (selectedRows.size !== undefined) {
      return selectedRows.size === 0 ? true : false;
    } else {
      return true;
    }
  };

  const handleDeleteEntries = () => {
    const ids = Array.from(selectedRows)
      .map((key) => {
        // Find the data object in globalData with the corresponding key
        const data = globalData.find((item) => item.id === key);
        return data ? data.id : null;
      })
      .filter((id) => id !== null); // We filter out any null values in case some keys didn't match
    deleteEntries(ids);
  };

  const deleteEntries = async (ids) => {
    const loadingToast = toast.loading("Flagging entry for deletion...", {
      position: "top-center",
      theme: "dark",
    });
    try {
      await axios.post(
        "stats/flagEntries",
        {},
        {
          params: {
            deleteIds: ids.join(","),
          },
        }
      );
      toast.dismiss(loadingToast);
      setSelectedRows([]);
      globalData.forEach((entry) => {
        if (ids.includes(entry.id)) {
          const index = globalData.indexOf(entry);
          globalData.splice(index, 1);
        }
      });
      onOpenChange();
      setTimeout(() => {
        toast.success("Successfully flagged entry", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }, 500);
    } catch (error) {
      toast.dismiss(loadingToast);
      console.error("Failed to submit form:", error);

      // Extracting error message from the response
      const errorMessage =
        error.response && error.response.data
          ? error.response.data
          : "Unknown error occurred";

      setTimeout(() => {
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }, 500);
    }
  };

  const deleteClick = () => {
    console.log("Delete button clicked");
    onOpen();
    setGlobalData([]);
    const fetchGlobalData = async () => {
      try {
        const response = await axios.get("/stats/getLiveFeed", {
          params: { global: false }, // Conditionally set global parameter
        });
        setGlobalData(response.data);
      } catch (error) {
        console.error("Error fetching live feed data:", error);
      }
    };
    fetchGlobalData();
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "5rem 0",
          justifyContent: "flex-start",
          overflowX: "auto",
        }}
      >
        <Navbar active="help" />
        <h1 style={{ margin: "1rem 0", fontSize: "2rem" }}>
          <FontAwesomeIcon icon={faCircleQuestion} /> Help
        </h1>
        <div
          style={{
            height: "fit-content",
            width: widthDisplay,
            padding: "2rem",
            backgroundColor: "#1e1e1e",
            borderRadius: "2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <h1 style={{ fontSize: "1.5rem" }}>Entries</h1>
            <Divider />
            <Spacer y={5} />
            <Button variant="bordered" color="danger" onPress={deleteClick}>
              <FontAwesomeIcon icon={faFlag} />
              Flag for deletion
            </Button>
            <Spacer y={5} />
            <h1 style={{ fontSize: "1.5rem" }}>Support</h1>
            <Divider />
            <Spacer y={5} />
            <Button
              variant="solid"
              color="primary"
              onPress={() =>
                window.open(
                  "mailto:help.smstats@gmail.com?subject=Request support"
                )
              }
              startContent={<FontAwesomeIcon icon={faEnvelope} />}
            >
              Contact us
            </Button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="lg"
        backdrop="blur"
        placement="center"
        hideCloseButton={true}
        style={{ width: "85%", backgroundColor: "#1e1e1e", maxHeight: "70%" }}
        scrollBehavior="inside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader style={{ color: "white" }}>
                Delete an Entry
              </ModalHeader>
              <ModalBody>
                <Table
                  isStriped
                  aria-label="Leaderboard table with dynamic content"
                  style={{ padding: "2rem" }}
                  className="dark text-foreground"
                  selectionMode="multiple"
                  selectedRows={selectedRows}
                  onSelectionChange={(rows) => setSelectedRows(new Set(rows))}
                >
                  <TableHeader>
                    <TableColumn>
                      <span>Users</span>
                    </TableColumn>
                    <TableColumn>
                      <span
                        style={{
                          display: "block",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        Time
                      </span>
                    </TableColumn>
                  </TableHeader>
                  <TableBody emptyContent={"Loading..."}>
                    {globalData.map((feed) => (
                      <TableRow key={feed.id}>
                        <TableCell>
                          {feed.users.map((user, index) => (
                            <span key={index} style={{ marginRight: "-1rem" }}>
                              {user}
                              {index < feed.users.length - 1 && <br />}
                            </span>
                          ))}
                        </TableCell>
                        <TableCell>
                          <span>
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "0.8rem",
                              }}
                            >
                              {moment(feed.timeOfConsumption).format(
                                "DD-MM-YYYY"
                              )}
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "0.8rem",
                              }}
                            >
                              {moment(feed.timeOfConsumption).format("HH:mm")}
                            </p>
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="bordered" onPress={onClose}>
                  Close
                </Button>
                <Spacer x={1} />
                <Button
                  color="danger"
                  variant="bordered"
                  onPress={handleDeleteEntries}
                  isDisabled={isDeleteButtonDisabled()}
                >
                  <FontAwesomeIcon icon={faFlag} /> Flag for deletion
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Help;
