import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Spacer,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  ModalFooter,
} from "@nextui-org/react";
import axios from "../../axiosInstance";

const TrophyView = ({ trophy, category, type }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [trophies, setTrophies] = useState([]);

  // Define style dynamically based on the trophy's colour attribute
  const trophyStyle = {
    color: trophy.colour, // Use the color from the trophy details
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return (
      <>
        {`${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getFullYear()}`}{" "}
        <br />
        {`${date.getHours().toString().padStart(2, "0")}:${date
          .getMinutes()
          .toString()
          .padStart(2, "0")}`}
      </>
    );
  };

  useEffect(() => {
    if (isOpen) {
      const requestString =
        type === "earned"
          ? `/getTrophiesByUser?group=${trophy.group}`
          : `/snus/getTrophiesByUser?brand=${trophy.brand}`;
      axios
        .get(requestString)
        .then((response) => setTrophies(response.data))
        .catch((error) => console.error("Error fetching trophies:", error));
    }
  }, [isOpen, trophy.group, trophy.brand, trophy.type]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "rgb(45 45 45)",
          borderRadius: "1rem",
          padding: "0.5rem",
        }}
      >
        <h2 style={{ fontSize: "1rem" }}>{capitalizeFirstLetter(category)}</h2>
        <Spacer y={3} />
        <FontAwesomeIcon icon={faTrophy} size="3x" style={trophyStyle} />
        <Spacer y={2} />
        <span
          style={{
            fontSize: "1rem",
            color: trophy.colour,
            textAlign: "center",
            lineHeight: "1",
          }}
        >
          {trophy.title}
        </span>
        <Spacer y={3} />
        <Button size="sm" onPress={onOpen}>
          More
        </Button>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="lg"
        backdrop="blur"
        placement="center"
        hideCloseButton={true}
        style={{ width: "80%", backgroundColor: "#1e1e1e" }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader style={{ color: "white" }}>
                All {capitalizeFirstLetter(category)} Trophies
              </ModalHeader>
              <ModalBody style={{ color: "white" }}>
                {Object.entries(trophies).map(([key, trophy]) => (
                  <div
                    key={trophy.id} // Use trophy.id to ensure unique keys
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgb(45 45 45)",
                      borderRadius: "1rem",
                      padding: "0.5rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTrophy}
                        size="3x"
                        style={{ color: trophy.colour }}
                      />
                      <Spacer y={2} />
                      <span
                        style={{
                          fontSize: "1rem",
                          color: trophy.colour,
                          textAlign: "center",
                          lineHeight: "1",
                        }}
                      >
                        {trophy.title}
                      </span>
                    </div>
                    <Spacer x={5} />
                    <div>
                      <p style={{ textAlign: "center" }}>
                        Earned at: <br />
                      </p>
                      <p style={{ textAlign: "center" }}>
                        {formatDateTime(key.split(" ")[1])}
                      </p>
                    </div>
                  </div>
                ))}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onPress={onClose}
                  variant="bordered"
                  style={{ margin: "0 1rem" }}
                >
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default TrophyView;
