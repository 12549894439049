import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import {
  Avatar,
  Button,
  Divider,
  Spacer,
  Switch,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalContent,
  ModalBody,
  useDisclosure,
  Input,
} from "@nextui-org/react";
import axios from "../../axiosInstance"; // Make sure this path matches your axios instance path
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRotateRight,
  faGear,
  faLock,
  faMinus,
  faPen,
  faPlus,
  faSave,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";

export default function Settings() {
  const [avatarSrc, setAvatarSrc] = useState("");
  const [settings, setSettings] = useState({});
  const [initialSettings, setInitialSettings] = useState({});
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [profilePic, setProfilePic] = useState(""); // New state for profile pic [1/2]
  const [image, setImage] = useState(null); // New state for image [1/2]
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isMobile, setIsMobile] = useState(false); // New state for mobile detection [1/2]

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Options for image compression
      const options = {
        maxSizeMB: 0.1, // Maximum file size in MB
        maxWidthOrHeight: 300, // Compresses image to a max width or height of 300px
        useWebWorker: true, // Use a web worker to perform compression in a background thread
      };

      try {
        // Perform the image compression
        const compressedFile = await imageCompression(file, options);

        // Set the compressed image for uploading
        setImage(compressedFile);

        // Create a FileReader to read the compressed file
        const reader = new FileReader();

        // Define what happens on reader load
        reader.onload = function (readerEvent) {
          // Convert compressed image file to base64 string and update the avatarSrc
          const base64String = readerEvent.target.result;
          setAvatarSrc(base64String);
        };

        // Read the compressed file as a Data URL (Base64 string)
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Compression Error:", error);
        // Handle errors (you can use toast notifications or any other method to show error)
      }
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }

    // Fetch initial data
    const fetchData = async () => {
      try {
        const [profilePicResponse, settingsResponse] = await Promise.all([
          axios.get("/getProfilePic"),
          axios.get("/getSettings"),
        ]);

        if (profilePicResponse.data !== "") {
          const base64Image = `data:image/jpeg;base64,${profilePicResponse.data}`;
          setProfilePic(base64Image); // Set the profile pic state [2/2]
          setAvatarSrc(base64Image);
        } else {
          setProfilePic(""); // Set the profile pic state [2/2]
          setAvatarSrc("");
        }
        setSettings(settingsResponse.data);
        setInitialSettings(settingsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const widthDisplay = isMobile ? "90%" : "50%";

  useEffect(() => {
    // Check if settings have changed
    const settingsHaveChanged =
      JSON.stringify(settings) !== JSON.stringify(initialSettings) ||
      avatarSrc !== profilePic;
    setShowSaveButton(settingsHaveChanged);
  }, [settings, initialSettings, avatarSrc, profilePic]);

  const handleResetSettings = () => {
    setSettings(initialSettings);
    setAvatarSrc(profilePic);
    setImage(null); // Clear the image state
    setShowSaveButton(false); // Hide Save button after resetting
  };

  const handleSaveSettings = async () => {
    try {
      // Save settings logic (send updated settings to server/storage)
      // Assuming POST method is used for updating settings
      if (JSON.stringify(settings) !== JSON.stringify(initialSettings)) {
        await axios.post("/updateSettings", settings);
      }
      if (avatarSrc !== profilePic) {
        const formData = new FormData();
        if (avatarSrc !== "") {
          formData.append("image", image);
        } else {
          formData.append("reset", true);
        }

        await axios.post("/updateProfilePic", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
          },
        });
      }

      toast.success("Successfully saved settings", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "dark",
      });
      setInitialSettings(settings);
      setProfilePic(avatarSrc);
      setShowSaveButton(false); // Hide Save button after saving
    } catch (error) {
      setAvatarSrc(profilePic); // Reset the avatar preview
      console.error("Error saving settings:", error);
      toast.error("Failed to save settings", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const handlePasswordChange = () => {
    const currentPassword = document.getElementById("currentPassword").value;
    const newPassword = document.getElementById("newPassword").value;

    if (currentPassword === "" || newPassword === "") {
      toast.error("Please fill in all fields", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "dark",
      });
      return;
    }
    const loadingToast = toast.loading("Changing password...", {
      position: "top-center",
      theme: "dark",
    });

    // Password change logic (send current and new password to server/storage)
    // Assuming POST method is used for changing password
    axios
      .post("/changePassword", null, {
        params: {
          currentPassword,
          newPassword,
        },
      })
      .then(() => {
        toast.dismiss(loadingToast);
        setTimeout(() => {
          toast.success("Password changed successfully", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "dark",
          });
        }, 500);
        onOpenChange(false); // Assuming this is to close a modal or similar
      })
      .catch((error) => {
        console.error("Error changing password:", error);
        let errorMessage = "Failed to change password"; // Default error message

        // Check if the error response has a specific message indicating "Incorrect current password."
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Incorrect current password."
        ) {
          errorMessage = "Incorrect current password.";
        }
        toast.dismiss(loadingToast);
        setTimeout(() => {
          toast.error(errorMessage, {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "dark",
          });
        }, 500);
      });
  };

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "5rem 0",
        justifyContent: "flex-start",
        overflowX: "auto",
      }}
    >
      <Navbar active="settings" />
      <h1 style={{ margin: "1rem 0", fontSize: "2rem" }}>
        <FontAwesomeIcon icon={faGear} className="slow-spin" /> Settings
      </h1>
      <div
        style={{
          height: "fit-content",
          width: widthDisplay,
          padding: "2rem",
          backgroundColor: "#1e1e1e",
          borderRadius: "2rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatar src={avatarSrc} size="lg" />
        <h1 style={{ margin: "0.5rem 0", fontSize: "1.4rem" }}>
          {settings.username || "Username"}
        </h1>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <h1 style={{ margin: "0.5rem 0", fontSize: "1.3rem" }}>
            Preferences
          </h1>
          <Divider />
          <Spacer y={5} />
          <Switch
            isDisabled={true}
            isSelected={settings.acceptCookies}
            onChange={(e) =>
              setSettings((prevSettings) => ({
                ...prevSettings,
                acceptCookies: e.target.checked,
              }))
            }
          >
            Accept cookies
          </Switch>
          <Spacer y={5} />
          <Switch
            isSelected={settings.sharePers}
            onChange={(e) =>
              setSettings((prevSettings) => ({
                ...prevSettings,
                sharePers: e.target.checked,
              }))
            }
          >
            Share personal entries globally
          </Switch>
          <Spacer y={5} />
          <Switch
            isSelected={settings.privatePhotos}
            onChange={(e) =>
              setSettings((prevSettings) => ({
                ...prevSettings,
                privatePhotos: e.target.checked,
              }))
            }
          >
            Keep photos private
          </Switch>{" "}
          <Spacer y={5} />
          <h1 style={{ margin: "0.5rem 0", fontSize: "1.3rem" }}>Actions</h1>
          <Divider />
          <Spacer y={5} />
          <Dropdown className="dark">
            <DropdownTrigger>
              <Button variant="bordered">
                <FontAwesomeIcon icon={faPen} /> Change profile pic
              </Button>
            </DropdownTrigger>
            <DropdownMenu className="text-white">
              <DropdownItem
                onClick={() => document.getElementById("imageInput").click()}
                startContent={<FontAwesomeIcon icon={faPlus} />}
              >
                Upload new image
              </DropdownItem>
              <DropdownItem
                className="text-danger"
                color="danger" // This sets the text color to a theme-defined "danger" color, which is usually some shade of red.
                startContent={<FontAwesomeIcon icon={faMinus} />}
                onClick={() => {
                  setAvatarSrc(""); // Clear the avatar preview
                  document.getElementById("imageInput").value = ""; // Clear the file input value
                }}
              >
                Remove profile pic
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <input
            type="file"
            id="imageInput"
            style={{ display: "none", width: "100%" }}
            onChange={handleImageChange}
            accept="image/*"
          />
          <Spacer y={5} />
          <Button variant="bordered" onPress={onOpen}>
            <FontAwesomeIcon icon={faLock} /> Change password
          </Button>
          <Spacer y={8} />
          <div style={{ width: "100%", display: "flex" }}>
            <Button
              variant="solid"
              color="default"
              isDisabled={!showSaveButton}
              onClick={handleResetSettings}
              style={{ width: "100%" }}
            >
              <FontAwesomeIcon icon={faArrowRotateRight} /> Reset
            </Button>
            <Spacer x={5} />
            <Button
              variant="solid"
              color="primary"
              isDisabled={!showSaveButton}
              onClick={handleSaveSettings}
              style={{ width: "100%" }}
            >
              <FontAwesomeIcon icon={faSave} /> Save
            </Button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="lg"
        backdrop="blur"
        placement="center"
        hideCloseButton={true}
        style={{ width: "90%", backgroundColor: "#1e1e1e" }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                    padding: "2rem 0",
                  }}
                >
                  <p style={{ color: "white" }}>
                    <FontAwesomeIcon
                      icon={faLock}
                      style={{ margin: "0 0.5rem 0 0" }}
                    />
                    Change password
                  </p>
                  <Input
                    className="dark"
                    type={showPassword ? "text" : "password"}
                    label="Current password"
                    id="currentPassword"
                    endContent={
                      <FontAwesomeIcon
                        id="currentPasswordIcon"
                        icon={showPassword ? faEyeSlash : faEye}
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={togglePasswordVisibility}
                      />
                    }
                  />
                  <Input
                    className="dark"
                    type={showNewPassword ? "text" : "password"}
                    label="New password"
                    id="newPassword"
                    endContent={
                      <FontAwesomeIcon
                        id="newPasswordIcon"
                        icon={showNewPassword ? faEyeSlash : faEye}
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={toggleNewPasswordVisibility}
                      />
                    }
                  />
                  <div>
                    <Button
                      color="danger"
                      onPress={onClose}
                      variant="bordered"
                      style={{ margin: "0 1rem" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="bordered"
                      style={{ margin: "0 1rem" }}
                      onPress={handlePasswordChange}
                    >
                      Accept
                    </Button>
                  </div>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}
