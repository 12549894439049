import React, { useState, useEffect } from "react";
import axios from "../../axiosInstance";
import { User } from "@nextui-org/react";

const UserDropdown = () => {
  const [users, setUsers] = useState(() => {
    const savedUsers = localStorage.getItem("users");
    return savedUsers ? JSON.parse(savedUsers) : [];
  });
  const [profilePics, setProfilePics] = useState({});

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/getAllUsers");
        setUsers(response.data);
        localStorage.setItem("users", JSON.stringify(response.data));
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    if (users.length === 0) {
      fetchUsers();
    }
  }, [users.length]);

  useEffect(() => {
    const fetchProfilePics = async () => {
      const profilePicsUpdates = {};
      for (const user of users) {
        const storedPic = localStorage.getItem(user + "_profilePic");
        profilePicsUpdates[user] = storedPic
          ? "data:image/jpeg;base64," + storedPic
          : null;
      }

      setProfilePics(profilePicsUpdates);

      for (const user of users) {
        try {
          const response = await axios.get(`/getProfilePic?username=${user}`);
          if (
            response.data !== "" &&
            response.data !== localStorage.getItem(user + "_profilePic")
          ) {
            profilePicsUpdates[user] =
              "data:image/jpeg;base64," + response.data;
            localStorage.setItem(user + "_profilePic", response.data);
          }
        } catch (error) {
          console.error("Failed to fetch profile pic for", user, ":", error);
        }
      }

      setProfilePics(profilePicsUpdates);
    };

    if (users.length > 0) {
      fetchProfilePics();
    }
  }, [users]);

  const redirectToUserPage = (user) => {
    window.location.href = `users?user=${user}`;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "0.5rem",
        padding: "0.5rem 0",
      }}
    >
      {users.map((user) => (
        <div
          style={{
            width: "100%",
            border: "1px solid rgb(53 53 53)",
            borderRadius: "0.5rem",
            padding: "0.4rem 2rem 0.4rem 0.5rem",
            display: "flex",
          }}
          key={user}
        >
          <User
            name={user}
            description={user.description || ""}
            style={{
              color: "white",
            }}
            avatarProps={{
              src: profilePics[user] || "", // Default image or loading state can be handled here
            }}
            isFocusable={true}
            onClick={() => redirectToUserPage(user)}
          />
        </div>
      ))}
    </div>
  );
};

export default UserDropdown;
