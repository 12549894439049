import React from "react";
import VideoJS from "./VideoJS"; // Adjust the import path as needed

const VideoPlayer = React.memo(
  ({ options, onReady }) => {
    return <VideoJS options={options} onReady={onReady} />;
  },
  (prevProps, nextProps) => {
    // Only re-render if the source or type of the video changes
    return (
      prevProps.options.sources[0].src === nextProps.options.sources[0].src &&
      prevProps.options.sources[0].type === nextProps.options.sources[0].type
    );
  }
);

export default VideoPlayer;
