import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import { saveAs } from "file-saver";
import {
  Table,
  TableColumn,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  User,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
  Tabs,
  Tab,
  Spinner,
  Chip,
  Avatar,
} from "@nextui-org/react";
import axios from "../../axiosInstance";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import {
  faCircleInfo,
  faLink,
  faStopwatch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

export default function SnusFeed() {
  const [globalData, setGlobalData] = useState([]); // New state for global data
  const [personalData, setPersonalData] = useState([]); // New state for personal data
  const [profilePics, setProfilePics] = useState({}); // State to hold profile pictures
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [isInfoSelected, setIsInfoSelected] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFeed, setSelectedFeed] = useState(null); // New state for selected feed
  const [activeTab, setActiveTab] = useState("global"); // Step 1: Track the active tab
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [users, setUsers] = useState(() => {
    const savedUsers = localStorage.getItem("users");
    return savedUsers ? JSON.parse(savedUsers) : [];
  });
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const [loadingPersonal, setLoadingPersonal] = useState(false);
  const [snus, setSnus] = useState({});
  const [selectedFlavorMap, setSelectedFlavorMap] = useState({});

  useEffect(() => {
    const fetchUsers = async () => {
      if (users.length === 0) {
        try {
          const response = await axios.get("/getAllUsers");
          setUsers(response.data);
        } catch (error) {
          console.error("Failed to fetch users:", error);
        }
      }
    };

    const fetchSnus = async () => {
      try {
        const response = await axios.get("/snus");
        const snusMap = {};
        response.data.forEach((snus) => {
          snusMap[snus.id] = snus; // Convert array to a map using snus ID as the key
        });
        setSnus(snusMap); // Now `snus` is a map of IDs to snus objects
      } catch (error) {
        console.error("Failed to fetch snus:", error);
      }
    };

    fetchUsers();
    fetchSnus();
  }, [users.length]);

  useEffect(() => {
    if (selectedFeed) {
      const flavorMap = {};
      Object.entries(selectedFeed.snusCons).forEach(([user, snusIds]) => {
        snusIds.forEach((id) => {
          const flavor = `${snus[id]?.brand} ${snus[id]?.flavor}`; // Assuming `snus` is a map of snus IDs to snus objects
          if (flavor) {
            if (!flavorMap[flavor]) {
              flavorMap[flavor] = new Set();
            }
            flavorMap[flavor].add(user);
          }
        });
      });
      setSelectedFlavorMap(flavorMap); // Assume `setSelectedFlavorMap` updates state for the modal
    }
  }, [selectedFeed, snus]);

  useEffect(() => {
    // Fetch live feed data
    const fetchGlobalData = async () => {
      setLoadingGlobal(true);
      try {
        const response = await axios.get("/snus/getLiveFeed", {
          params: { global: true },
        });
        setGlobalData(response.data);
      } catch (error) {
        console.error("Error fetching global feed data:", error);
      } finally {
        setLoadingGlobal(false);
      }
    };

    const fetchPersonalData = async () => {
      setLoadingPersonal(true);
      try {
        const response = await axios.get("/snus/getLiveFeed", {
          params: { global: false },
        });
        setPersonalData(response.data);
      } catch (error) {
        console.error("Error fetching personal feed data:", error);
      } finally {
        setLoadingPersonal(false);
      }
    };

    fetchGlobalData();
    fetchPersonalData();
  }, []);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }

    const fetchProfilePics = async () => {
      const updatedProfilePics = { ...profilePics }; // Start with a copy of the existing state
      let isUpdated = false; // Flag to track if updates are needed

      for (const user of users) {
        const profilePic = localStorage.getItem(`${user}_profilePic`);
        if (profilePic && updatedProfilePics[user] !== profilePic) {
          updatedProfilePics[user] = profilePic;
          isUpdated = true; // Mark that we have updates
        }
        if (!profilePic) {
          try {
            const response = await axios.get(`/getProfilePic?username=${user}`);
            if (response.data !== "") {
              localStorage.setItem(user + "_profilePic", response.data);
              updatedProfilePics[user] = response.data;
            } else {
              updatedProfilePics[user] = "";
            }
          } catch (error) {
            console.error("Failed to fetch profile pic for", user, ":", error);
          }
          isUpdated = true;
        }
      }

      if (isUpdated) {
        setProfilePics(updatedProfilePics); // Only update the state if there were changes
      }
    };

    if (users.length > 0) {
      // Only run this effect if we have users
      fetchProfilePics();
    }
  }, [users]); // Depend on `users` to avoid unnecessary updates

  const whatIsActiveMap = () => {
    if (activeTab === "global") {
      return globalData;
    } else {
      return personalData;
    }
  };

  const handleImageClick = (feed) => () => {
    setIsImageLoading(true); // Set isImageLoading to true while the request is loading
    setIsInfoSelected(false);
    onOpen();
    axios
      .get("/getImage", {
        params: {
          id: feed.image,
        },
      })
      .then((response) => {
        setSelectedImage(response.data);
        setSelectedFeed(feed);
      })
      .catch((error) => {
        console.error("There was an error fetching the image:", error);
      })
      .finally(() => {
        setIsImageLoading(false); // Set isImageLoading back to false after the request is completed
      });
  };
  const contentWidth = isMobile ? "90%" : "60%";

  const downloadImage = (base64Image) => {
    // Convert base64 to blob
    const byteCharacters = atob(base64Image.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "image/jpeg" });

    // Use FileSaver.js to handle the download
    saveAs(blob, `downloaded_image_${Date.now()}.jpg`);

    // Display success message
    toast.success("Successfully downloaded image", {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "dark",
    });
  };

  const handleInfoClick = (feed) => () => {
    setIsInfoSelected(true);
    setSelectedFeed(feed);
    onOpen(); // This uses the useDisclosure hook to open the modal
  };

  function redirectToUserPage(user) {
    if (users.includes(user)) {
      window.location.href = `users?user=${user}`;
    } else {
      toast.error("User does not have an SM Stats account", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "dark",
      });
    }
  }

  const isLoading = loadingGlobal || loadingPersonal;

  function redirectToSnus() {
    window.location.href = "/liveFeed";
  }

  return (
    <div>
      <Button
        color="default"
        style={{
          position: "fixed",
          right: "15px",
          bottom: "40px",
          zIndex: 10,
          boxShadow: "0px 0px 10px grey",
        }}
        onClick={() => redirectToSnus()}
        size="lg"
      >
        <FontAwesomeIcon icon={faLink} /> LiveFeed
      </Button>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "5rem 0",
          justifyContent: "flex-start",
          overflowX: "auto",
        }}
      >
        <Navbar active={"Live feed"} />
        <div>
          <h1 style={{ margin: "1rem 0", fontSize: "2rem" }}>
            <FontAwesomeIcon
              icon={faStopwatch}
              style={{ margin: "0 1rem 0 0" }}
            />
            Snus Feed
          </h1>
        </div>
        <Tabs
          variant={"solid"}
          aria-label="Tabs variants"
          value={activeTab}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "2rem 0",
          }}
          onSelectionChange={(value) => setActiveTab(value)}
        >
          <Tab
            key="global"
            title="Global"
            value="global"
            style={{ fontSize: "1rem" }}
          />
          <Tab
            key="personal"
            title="Personal"
            value="personal"
            style={{ fontSize: "1rem" }}
          />
        </Tabs>
        <div style={{ width: contentWidth }}>
          <Table
            isStriped
            aria-label="Leaderboard table with dynamic content"
            style={{ padding: "2rem" }}
          >
            <TableHeader>
              <TableColumn>
                <span>Users</span>
              </TableColumn>
              <TableColumn>
                <span
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Time
                </span>
              </TableColumn>
              <TableColumn>
                <span
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Image
                </span>
              </TableColumn>
              <TableColumn>
                <span
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Info
                </span>
              </TableColumn>
            </TableHeader>
            <TableBody
              emptyContent={
                isLoading
                  ? "Loading..."
                  : whatIsActiveMap().length === 0
                  ? "No entries matched your filters"
                  : undefined
              }
            >
              {whatIsActiveMap().map((feed) => (
                <TableRow key={feed.id}>
                  <TableCell>
                    {Object.entries(feed.snusCons).map(
                      ([user, ids], index, array) => (
                        <span key={user} style={{ marginRight: "-1rem" }}>
                          <User
                            name={user}
                            avatarProps={{
                              src: profilePics[user]
                                ? `data:image/jpeg;base64,${profilePics[user]}`
                                : null,
                              size: isMobile ? "sm" : "md",
                            }}
                            alt="Profile Pic"
                            onClick={() => redirectToUserPage(user)}
                            style={{
                              color: users.includes(user)
                                ? "white"
                                : "rgb(163 163 163)",
                            }}
                          />
                          {index < array.length - 1 && <br />}
                        </span>
                      )
                    )}
                  </TableCell>
                  <TableCell>
                    <span>
                      <p style={{ textAlign: "center", fontSize: "0.8rem" }}>
                        {moment(feed.time).format("DD-MM-YYYY")}
                      </p>
                      <p style={{ textAlign: "center", fontSize: "0.8rem" }}>
                        {moment(feed.time).format("HH:mm")}
                      </p>
                    </span>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {feed.image ? (
                        <FontAwesomeIcon
                          onClick={handleImageClick(feed)}
                          icon={faImage}
                          size="lg"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        "N/A"
                      )}{" "}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        size="lg"
                        color="rgb(100, 100, 100)"
                        style={{ cursor: "pointer" }}
                        onClick={handleInfoClick(feed)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            size="lg"
            backdrop="blur"
            placement="center"
            hideCloseButton={true}
            style={{
              width: "90%",
              backgroundColor: "#1e1e1e",
              maxHeight: "80vh",
            }}
          >
            <ModalContent>
              {(onClose) => (
                <>
                  {isImageLoading ? (
                    // Show just the spinner when the image is loading
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "400px",
                      }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    // Once the image has loaded, show the modal content
                    <>
                      {!isInfoSelected ? (
                        <ModalHeader
                          className="flex flex-col gap-1"
                          style={{ color: "white" }}
                        >
                          Uploaded image{" "}
                          {moment(selectedFeed?.timeOfConsumption).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                        </ModalHeader>
                      ) : (
                        <ModalHeader
                          className="flex flex-col gap-1"
                          style={{ color: "white" }}
                        >
                          Entry Info
                        </ModalHeader>
                      )}
                      <ModalBody>
                        {!isInfoSelected ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={`data:image/jpeg;base64,${selectedImage}`}
                              alt="Selected"
                              style={{
                                maxHeight: isMobile ? "500px" : "350px",
                                width: "auto",
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              color: "#D1D5DB", // Light gray text color for better readability
                              borderRadius: "8px",
                              maxWidth: "600px", // Limiting width for better structure // Centering in the view
                              fontFamily:
                                "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif", // Modern font family
                              width: "100%",
                              alignItems: "",
                            }}
                          >
                            {selectedFlavorMap &&
                              Object.entries(selectedFlavorMap).map(
                                ([flavor, userSet], index) => (
                                  <div
                                    key={`flavor-section-${flavor}-${index}`} // Ensuring uniqueness
                                    style={{
                                      margin: "10px 0",
                                      display: "flex",
                                    }}
                                  >
                                    <strong
                                      style={{
                                        fontWeight: "bold",
                                        color:
                                          "hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)))",
                                        fontSize: "14px",
                                        lineHeight: "1.5",
                                        width: "70%",
                                      }}
                                    >
                                      {flavor}:
                                    </strong>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        alignItems: "flex-end",
                                        width: "100%",
                                        gap: "0.5rem",
                                      }}
                                    >
                                      {Array.from(userSet).map((user) => (
                                        <Chip
                                          key={user + flavor}
                                          variant="solid"
                                          color="default"
                                          className="dark"
                                          avatar={
                                            <Avatar
                                              name={user}
                                              src={
                                                profilePics[user]
                                                  ? `data:image/jpeg;base64,${profilePics[user]}`
                                                  : ""
                                              }
                                              fallback={
                                                <FontAwesomeIcon
                                                  icon={faUser}
                                                />
                                              }
                                            />
                                          }
                                        >
                                          {user}
                                        </Chip>
                                      ))}
                                    </div>
                                  </div>
                                )
                              )}
                            <div
                              style={{
                                margin: "10px 0",
                                fontSize: "16px",
                                lineHeight: "1.5",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                              }}
                            >
                              <strong
                                style={{
                                  fontWeight: "bold",
                                  color:
                                    "hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)))",
                                  width: "70%",
                                  fontSize: "14px",
                                }}
                              >
                                Time:
                              </strong>
                              <div
                                style={{
                                  width: "100%",
                                  fontFamily:
                                    "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                                }}
                              >
                                <Chip className="dark">
                                  <p
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    {moment(selectedFeed.time).format("HH:mm")}
                                  </p>
                                </Chip>
                                <Chip
                                  className="dark"
                                  style={{ marginLeft: "0.2rem" }}
                                >
                                  <p
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    {moment(selectedFeed.time).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </p>
                                </Chip>
                              </div>
                            </div>
                          </div>
                        )}
                      </ModalBody>
                      <ModalFooter>
                        {!isInfoSelected && (
                          <Button
                            color="primary"
                            variant="solid"
                            onPress={() =>
                              downloadImage(
                                `data:image/jpeg;base64,${selectedImage}`
                              )
                            }
                          >
                            Download
                          </Button>
                        )}
                        <Button
                          variant="solid"
                          color="danger"
                          onPress={onClose}
                        >
                          Close
                        </Button>
                      </ModalFooter>
                    </>
                  )}
                </>
              )}
            </ModalContent>
          </Modal>
        </div>
      </div>
    </div>
  );
}
