import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import { Tabs, Tab, Button } from "@nextui-org/react";
import Lifetime from "./Lifetime/Lifetime";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Size from "./Specific/Size";
import Mixed from "./Specific/Mixed";
import MixedPercentage from "./Specific/MixedPercentage";
import FriendsStacked from "./Specific/FriendsStacked";

export default function Leaderboard() {
  const [activeTab, setActiveTab] = useState("leaderboard"); // Step 1: Track the active tab
  const [selectedKeys, setSelectedKeys] = React.useState(new Set(["size"]));

  const selectedValue = React.useMemo(
    () => Array.from(selectedKeys).join(", ").replaceAll("_", " "),
    [selectedKeys]
  );
  const [tabStyle] = useState({
    // Destructure the useState array
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "2rem",
  });
  /*const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);*/

  // Define a method to render tab content based on the current active tab
  const renderTabContent = () => {
    switch (activeTab) {
      case "leaderboard":
        return (
          <div style={tabStyle}>
            <Lifetime />
          </div>
        );
      case "specific":
        const specificContent = () => {
          switch (
            Array.from(selectedKeys)[0] // Assuming single selection mode
          ) {
            case "size":
              return <Size />; // Replace with actual component
            case "mixed":
              return (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "2rem",
                  }}
                >
                  <Mixed /> <MixedPercentage />
                </div>
              ); // Replace with actual component
            case "friends":
              return <FriendsStacked />; // Replace with actual component
            default:
              return null; // Default case when no key is selected
          }
        };

        return (
          <div style={tabStyle}>
            {specificContent()}
            <div
              style={{
                position: "fixed",
                bottom: 0,
                right: 0,
                margin: "0 2rem 5rem 0",
              }}
            >
              <Dropdown className="dark">
                <DropdownTrigger>
                  <Button
                    variant="bordered"
                    className="capitalize"
                    size="lg"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "fit-content",
                    }}
                  >
                    <FontAwesomeIcon icon={faBars} />{" "}
                    {selectedValue.charAt(0).toUpperCase() +
                      selectedValue.slice(1)}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Single selection example"
                  variant="flat"
                  disallowEmptySelection
                  selectionMode="single"
                  selectedKeys={selectedKeys}
                  onSelectionChange={setSelectedKeys}
                  className="text-white"
                >
                  <DropdownItem key="size">Size</DropdownItem>
                  <DropdownItem key="mixed">Mixed</DropdownItem>
                  <DropdownItem key="friends">Friends</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        );
      default:
        return null; // Render nothing for undefined tabs
    }
  };

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "flexStart",
        padding: "5rem 0",
        justifyContent: "flex-start",
      }}
    >
      <Navbar active={"Leaderboard"} />
      <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
        <Tabs
          variant={"solid"}
          aria-label="Tabs variants"
          value={activeTab}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "2rem 0",
          }}
          onSelectionChange={(value) => setActiveTab(value)}
        >
          <Tab
            key="leaderboard"
            title="Leaderboard"
            value="leaderboard"
            style={{ fontSize: "1rem" }}
          />
          <Tab
            key="specific"
            title="Specific"
            value="specific"
            style={{ fontSize: "1rem" }}
          />
        </Tabs>

        {renderTabContent()}
      </div>
    </div>
  );
}
