import React, { useState, useEffect } from "react";
import axios from "../../axiosInstance";
import Notification from "./Notification";

function NotificationDisplay({ type }) {
  const [notis, setNotis] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/notification/unread");
        setNotis(response.data); // Assuming response.data is an array of notifications
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    notis.forEach((notification) => {
      readNoti(notification.id);
    });
  }, [notis]); // Depend on unreadNotis to trigger this effect

  const readNoti = async (notiId) => {
    try {
      await axios.post("/notification/" + notiId);
      // Optionally, update local state to reflect the read status
    } catch (error) {
      console.error("Failed to mark notification as read:", error);
    }
  };

  return (
    <div>
      {notis.length === 0 && (
        <div style={{ padding: "2rem" }}>
          <p className="text-white text-center">
            You have no unread notifications.
          </p>
        </div>
      )}
      {notis.map((noti) => (
        <Notification key={noti.id} notification={noti} />
      ))}
    </div>
  );
}

export default NotificationDisplay;
