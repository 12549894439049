import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import axios from "../../../axiosInstance"; // Make sure this path matches your axios instance path

const BarChart = () => {
  const [dynamicStyle, setDynamicStyle] = useState();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Alone",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: "With Friends",
        data: [],
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    // Function to set styles based on window width
    const setInitialStyle = () => {
      const style =
        window.innerWidth < 768
          ? {
              height: "fit-content",
              width: "90%", // Default width for non-mobile devices
              padding: "2rem",
              backgroundColor: "#1e1e1e",
              borderRadius: "2rem", // Adjust the width for mobile devices
            }
          : {
              height: "fit-content",
              width: "40%", // Default width for non-mobile devices
              margin: "0rem 3rem",
              padding: "2rem",
              backgroundColor: "#1e1e1e",
              borderRadius: "2rem",
            };

      setDynamicStyle(style);
    };

    // Set the initial style based on the current window width
    setInitialStyle();
    // Note: We're not adding dynamicStyle to the dependency array to avoid re-running this effect due to style changes
  }, []); // Empty dependency array ensures this effect runs only once on mount

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/stats/leaderboard/getByFriends");
        const data = response.data;
        const usernames = Object.keys(data);
        const aloneData = usernames.map((user) => data[user][0]);
        const withFriendsData = usernames.map((user) => data[user][1]);

        setChartData((prevState) => ({
          ...prevState,
          labels: usernames,
          datasets: [
            { ...prevState.datasets[0], data: aloneData },
            { ...prevState.datasets[1], data: withFriendsData },
          ],
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const options = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true, // Adjust based on your preference
      },
    },
  };

  return (
    <div style={dynamicStyle}>
      <h1 style={{ textAlign: "center" }}>Activity Breakdown</h1>
      <Bar data={chartData} options={options} style={{ width: "100%" }} />
    </div>
  );
};

export default BarChart;
