import React, { useEffect, useState } from "react";
import axios from "../../axiosInstance";
import { Image as NextUIImage } from "@nextui-org/react"; // Renamed to avoid conflict

const ImageView = ({ id }) => {
  const [imageUrl, setImageUrl] = useState();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState(270);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(`/getImageBinary`, {
          params: { id },
          responseType: "blob", // This tells axios to expect a blob in the response
        });
        const imageUrl = URL.createObjectURL(response.data);
        setImageUrl(imageUrl);

        const img = new Image(); // This refers to the native HTMLImageElement
        img.onload = () => {
          const originalWidth = img.width;
          const originalHeight = img.height;
          const widthScale = 200 / originalWidth;
          const heightScale = 350 / originalHeight;
          const scale = Math.min(widthScale, heightScale); // Choose the smaller scale to fit within bounds

          setWidth(originalWidth * scale);
          setHeight(originalHeight * scale);
        };
        img.src = imageUrl;
      } catch (error) {
        console.error("Error fetching image with ID:", id, error);
      }
    };
    fetchImage();
  }, [id]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <NextUIImage src={imageUrl} width={width} height={height} />
    </div>
  );
};

export default ImageView;
