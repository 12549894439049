import { Tabs, Tab, ScrollShadow } from "@nextui-org/react";
import React, { useState, useEffect } from "react";
import axios from "../../axiosInstance";
import Notification from "./Notification";
import NotificationDisplay from "./NotificationDisplay";

const NotificationPage = () => {
  const [unreadNotis, setUnreadNotis] = useState([]); // Use an array to store unread notifications
  const [notis, setNotis] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/notification?max=4`);
        setNotis(response.data); // Assuming response.data is an array of notifications

        // Filter notifications to find unread ones
        const unreadNotifications = response.data.filter(
          (notification) => !notification.read
        );
        setUnreadNotis(unreadNotifications);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    unreadNotis.forEach((notification) => {
      readNoti(notification.id);
    });
  }, [unreadNotis]); // Depend on unreadNotis to trigger this effect

  const readNoti = async (notiId) => {
    try {
      await axios.post("/notification/" + notiId);
      // Optionally, update local state to reflect the read status
    } catch (error) {
      console.error("Failed to mark notification as read:", error);
    }
  };

  return (
    <div className="p-2" style={{ width: "60dvw", maxHeight: "70dv" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Tabs
          aria-label="Options"
          style={{ justifyContent: "center" }}
          radius="full"
          variant="underlined"
        >
          <Tab key="unread" title="Unread">
            <ScrollShadow hideScrollBar className="h-[400px]">
              <NotificationDisplay />
            </ScrollShadow>
          </Tab>
          <Tab key="all" title="All">
            {notis.map((noti) => (
              <Notification key={noti.id} notification={noti} type="all" />
            ))}
            {notis.length === 0 && (
              <div style={{ padding: "2rem" }}>
                <p className="text-white text-center">
                  You have no notifications.
                </p>
              </div>
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default NotificationPage;
