import React from "react";
import Comment from "./Comment";

function CommentSection({ comments, profilePics }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
      }}
    >
      {comments.length > 0 ? (
        comments.map((comment, index) => (
          <div key={index}>
            <Comment
              comment={comment}
              profilePic={{
                src: profilePics[comment.username]
                  ? `data:image/jpeg;base64,${profilePics[comment.username]}`
                  : null,
              }}
            />
          </div>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <p>No comments yet.</p>
        </div>
      )}
    </div>
  );
}
export default CommentSection;
