import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import { saveAs } from "file-saver";
import {
  Table,
  TableColumn,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  User,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
  Tabs,
  Tab,
  Spinner,
  Select,
  SelectItem,
  Spacer,
  DateRangePicker,
  Chip,
  Avatar,
} from "@nextui-org/react";
import axios from "../../axiosInstance";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDot, faImage } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import {
  faCircleInfo,
  faFilter,
  faLink,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";

export default function LiveFeed() {
  const [globalData, setGlobalData] = useState([]); // New state for global data
  const [personalData, setPersonalData] = useState([]); // New state for personal data
  const [profilePics, setProfilePics] = useState({}); // State to hold profile pictures
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [isInfoSelected, setIsInfoSelected] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFeed, setSelectedFeed] = useState(null); // New state for selected feed
  const [activeTab, setActiveTab] = useState("global"); // Step 1: Track the active tab
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [requestOptions, setRequestOptions] = useState([]);
  const [isFiltersSelected, setIsFilterSelected] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [users, setUsers] = useState(() => {
    const savedUsers = localStorage.getItem("users");
    return savedUsers ? JSON.parse(savedUsers) : [];
  });
  const [types, setTypes] = useState([]);
  const [mixedOptions, setMixedOptions] = useState([]);
  const [withRandomsOptions, setWithRandomsOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [selectedTypes, setSelectedTypes] = useState(new Set());
  const [selectedSize, setSelectedSize] = useState(new Set());
  const [withRandoms, setWithRandoms] = useState(undefined);
  const [isMixed, setIsMixed] = useState(undefined);
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const [loadingPersonal, setLoadingPersonal] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: undefined,
    end: undefined,
  });
  const [key, setKey] = useState(Date.now());
  /*
  const [onlyUsers, setOnlyUsers] = useState(false);
  const [maxEntries, setMaxEntries] = useState(true);
  */

  useEffect(() => {
    setSizes(["XSMALL", "SMALL", "MEDIUM", "LARGE", "XLARGE", "XXLARGE"]);
    setTypes(["Joint", "Blunt", "Bong", "Bowl", "Edible"]);
    setMixedOptions(["Mixed", "Not mixed"]);
    setWithRandomsOptions(["Yes", "No"]);

    const fetchUsers = async () => {
      try {
        const response = await axios.get("/getAllUsers");
        setUsers(response.data);
        localStorage.setItem("users", JSON.stringify(response.data));
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    // Fetch live feed data
    const fetchGlobalData = async () => {
      setLoadingGlobal(true);
      try {
        const response = await axios.get("/stats/getLiveFeed", {
          params: { ...requestOptions, global: true },
        });
        setGlobalData(response.data);
      } catch (error) {
        console.error("Error fetching global feed data:", error);
      } finally {
        setLoadingGlobal(false);
      }
    };

    const fetchPersonalData = async () => {
      setLoadingPersonal(true);
      try {
        const response = await axios.get("/stats/getLiveFeed", {
          params: { ...requestOptions, global: false },
        });
        setPersonalData(response.data);
      } catch (error) {
        console.error("Error fetching personal feed data:", error);
      } finally {
        setLoadingPersonal(false);
      }
    };

    fetchGlobalData();
    fetchPersonalData();
  }, []);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
    const fetchProfilePics = async () => {
      const profilePicsUpdates = {};
      for (const user of users) {
        const storedPic = localStorage.getItem(user + "_profilePic");
        profilePicsUpdates[user] = storedPic
          ? "data:image/jpeg;base64," + storedPic
          : null;
      }

      setProfilePics(profilePicsUpdates);

      for (const user of users) {
        try {
          const response = await axios.get(`/getProfilePic?username=${user}`);
          if (
            response.data !== "" &&
            response.data !== localStorage.getItem(user + "_profilePic")
          ) {
            profilePicsUpdates[user] =
              "data:image/jpeg;base64," + response.data;
            localStorage.setItem(user + "_profilePic", response.data);
          }
        } catch (error) {
          console.error("Failed to fetch profile pic for", user, ":", error);
        }
      }

      setProfilePics(profilePicsUpdates);
    };

    if (users.length > 0) {
      fetchProfilePics();
    }
  }, [users]); // Depend on `users` to avoid unnecessary updates

  const handleSelectionChange = (e) => {
    setIsMixed(e.target.value === "" ? undefined : e.target.value);
  };

  const handleSelectionChangeRandoms = (e) => {
    setWithRandoms(e.target.value === "" ? undefined : e.target.value);
  };

  const whatIsActiveMap = () => {
    if (activeTab === "global") {
      return globalData;
    } else {
      return personalData;
    }
  };

  const handleImageClick = (feed) => () => {
    setIsImageLoading(true); // Set isImageLoading to true while the request is loading
    setIsInfoSelected(false);
    setIsFilterSelected(false);
    onOpen();
    axios
      .get("/getImage", {
        params: {
          id: feed.image,
        },
      })
      .then((response) => {
        setSelectedImage(response.data);
        setSelectedFeed(feed);
      })
      .catch((error) => {
        console.error("There was an error fetching the image:", error);
      })
      .finally(() => {
        setIsImageLoading(false); // Set isImageLoading back to false after the request is completed
      });
  };
  const contentWidth = isMobile ? "90%" : "60%";

  const downloadImage = (base64Image) => {
    // Convert base64 to blob
    const byteCharacters = atob(base64Image.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "image/jpeg" });

    // Use FileSaver.js to handle the download
    saveAs(blob, `downloaded_image_${Date.now()}.jpg`);

    // Display success message
    toast.success("Successfully downloaded image", {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "dark",
    });
  };

  const handleInfoClick = (feed) => () => {
    setIsInfoSelected(true);
    setIsFilterSelected(false);
    setSelectedFeed(feed);
    onOpen(); // This uses the useDisclosure hook to open the modal
  };

  function redirectToEdit() {
    window.location.href = `editEntry?id=${selectedFeed.id}`;
  }

  function redirectToUserPage(user) {
    window.location.href = `users?user=${user}`;
  }
  function formatDate(year, month, day) {
    // Format month and day to ensure they are always two digits
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedDay = day.toString().padStart(2, "0");
    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  function handleFilterClick() {
    setIsFilterSelected(true);
    onOpen();
  }

  const updateFilters = async (e) => {
    e.preventDefault();

    // Create a new requestOptions object based on the selected filters
    const newRequestOptions = {
      users: Array.from(selectedUsers).join(","),
      sizes: Array.from(selectedSize)
        .map((size) => size.toUpperCase())
        .join(","),
      types: Array.from(selectedTypes)
        .map((type) => type.toUpperCase())
        .join(","),
      isMixed: isMixed === undefined ? [] : isMixed === "Mixed", // directly use the state value
      withRandoms: withRandoms === undefined ? [] : withRandoms === "Yes",
      startDate:
        dateRange.start === undefined
          ? null
          : formatDate(
              dateRange.start.year,
              dateRange.start.month,
              dateRange.start.day
            ),
      endDate:
        dateRange.end === undefined
          ? null
          : formatDate(
              dateRange.end.year,
              dateRange.end.month,
              dateRange.end.day
            ),
    };

    // Filter out empty strings to avoid sending empty parameters
    const filteredOptions = Object.fromEntries(
      Object.entries(newRequestOptions).filter(([_, v]) => v !== "")
    );

    // Optionally, update requestOptions state if you want to track it
    setRequestOptions(filteredOptions);
    onOpenChange(false); // Close the modal after updating the filters
    setGlobalData([]);
    setPersonalData([]);

    // Fetch updated data for both global and personal feeds
    try {
      setLoadingGlobal(true);
      setLoadingPersonal(true);
      const [globalResponse, personalResponse] = await Promise.all([
        axios.get("/stats/getLiveFeed", {
          params: { ...filteredOptions, global: true },
        }),
        axios.get("/stats/getLiveFeed", {
          params: { ...filteredOptions, global: false },
        }),
      ]);

      setGlobalData(globalResponse.data);
      setPersonalData(personalResponse.data);
      setLoadingGlobal(false);
      setLoadingPersonal(false);
    } catch (error) {
      console.error("Error fetching live feed data:", error);
    }
  };

  function resetFilter(type) {
    switch (type) {
      case 1:
        setSelectedUsers(new Set());
        break;
      case 2:
        setSelectedTypes(new Set());
        break;
      case 3:
        setSelectedSize(new Set());
        break;
      case 4:
        setIsMixed();
        break;
      case 5:
        setWithRandoms();
        break;
      case 6:
        setDateRange({ start: undefined, end: undefined });
        setKey(Date.now());
        break;
      default:
        setSelectedSize(new Set());
        setSelectedTypes(new Set());
        setSelectedUsers(new Set());
        setIsMixed();
        setWithRandoms();
        setDateRange({ start: undefined, end: undefined });
        setKey(Date.now());
        break;
    }
  }

  const isLoading = loadingGlobal || loadingPersonal;

  function redirectToSnus() {
    window.location.href = "/snusFeed";
  }

  useEffect(() => {
    if (activeTab === "snus") {
      window.location.href = "snusFeed";
    }
  }, [activeTab]);

  return (
    <div>
      {" "}
      <Button
        color="default"
        style={{
          position: "fixed",
          right: "15px",
          bottom: "40px",
          zIndex: 10,
          boxShadow: "0px 0px 10px grey",
        }}
        onClick={() => handleFilterClick()}
        size="lg"
      >
        <FontAwesomeIcon icon={faFilter} /> Filters
      </Button>
      <Button
        style={{
          margin: "0rem 0 1rem 0 ",
          position: "fixed",
          right: "15px",
          bottom: "85px",
          zIndex: 10,
          boxShadow: "0px 0px 10px grey",
        }}
        size="lg"
        onClick={() => redirectToSnus()}
      >
        <FontAwesomeIcon icon={faLink} /> Snus
      </Button>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "5rem 0",
          justifyContent: "flex-start",
          overflowX: "auto",
        }}
      >
        <Navbar active={"Live feed"} />
        <div>
          <h1 style={{ margin: "1rem 0", fontSize: "2rem" }}>
            <FontAwesomeIcon
              icon={faCircleDot}
              className="icon-beatfade"
              style={{ margin: "0 1rem 0 0" }}
            />
            Live Feed
          </h1>
        </div>
        <Tabs
          variant={"solid"}
          aria-label="Tabs variants"
          value={activeTab}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "2rem 0 1rem 0",
          }}
          onSelectionChange={(value) => setActiveTab(value)}
        >
          <Tab
            key="global"
            title="Global"
            value="global"
            style={{ fontSize: "1rem" }}
          />
          <Tab
            key="personal"
            title="Personal"
            value="personal"
            style={{ fontSize: "1rem" }}
          />
        </Tabs>
        <div style={{ width: contentWidth }}>
          <Table
            isStriped
            aria-label="Leaderboard table with dynamic content"
            style={{ padding: "2rem" }}
          >
            <TableHeader>
              <TableColumn>
                <span>Users</span>
              </TableColumn>
              <TableColumn>
                <span
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Time
                </span>
              </TableColumn>
              <TableColumn>
                <span
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Image
                </span>
              </TableColumn>
              <TableColumn>
                <span
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Info
                </span>
              </TableColumn>
            </TableHeader>
            <TableBody
              emptyContent={
                isLoading
                  ? "Loading..."
                  : whatIsActiveMap().length === 0
                  ? "No entries matched your filters"
                  : undefined
              }
            >
              {whatIsActiveMap().map((feed) => (
                <TableRow key={feed.id}>
                  <TableCell>
                    {feed.users.map((user, index) => (
                      <span key={index} style={{ marginRight: "-1rem" }}>
                        <User
                          name={user}
                          avatarProps={{
                            src: profilePics[user]
                              ? `${profilePics[user]}`
                              : null,
                            size: isMobile ? "sm" : "md",
                          }}
                          alt="Profile Pic"
                          onClick={() => redirectToUserPage(user)}
                        />
                        {index < feed.users.length - 1 && <br />}
                      </span>
                    ))}
                  </TableCell>
                  <TableCell>
                    <span>
                      <p style={{ textAlign: "center", fontSize: "0.8rem" }}>
                        {moment(feed.timeOfConsumption).format("DD-MM-YYYY")}
                      </p>
                      <p style={{ textAlign: "center", fontSize: "0.8rem" }}>
                        {moment(feed.timeOfConsumption).format("HH:mm")}
                      </p>
                    </span>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {feed.image ? (
                        <FontAwesomeIcon
                          onClick={handleImageClick(feed)}
                          icon={faImage}
                          size="lg"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        "N/A"
                      )}{" "}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        size="lg"
                        color="rgb(100, 100, 100)"
                        style={{ cursor: "pointer" }}
                        onClick={handleInfoClick(feed)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            size="lg"
            backdrop="blur"
            placement="center"
            hideCloseButton={true}
            style={{
              width: "90%",
              backgroundColor: "#1e1e1e",
              maxHeight: "80vh",
            }}
          >
            {!isFiltersSelected ? (
              <ModalContent>
                {(onClose) => (
                  <>
                    {isImageLoading ? (
                      // Show just the spinner when the image is loading
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "400px",
                        }}
                      >
                        <Spinner />
                      </div>
                    ) : (
                      // Once the image has loaded, show the modal content
                      <>
                        {!isInfoSelected ? (
                          <ModalHeader
                            className="flex flex-col gap-1"
                            style={{ color: "white" }}
                          >
                            Uploaded image{" "}
                            {moment(selectedFeed?.timeOfConsumption).format(
                              "DD-MM-YYYY HH:mm"
                            )}
                          </ModalHeader>
                        ) : (
                          <ModalHeader
                            className="flex flex-col gap-1"
                            style={{ color: "white" }}
                          >
                            Entry Info
                          </ModalHeader>
                        )}
                        <ModalBody>
                          {!isInfoSelected ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={`data:image/jpeg;base64,${selectedImage}`}
                                alt="Selected"
                                style={{
                                  maxHeight: isMobile ? "500px" : "350px",
                                  width: "auto",
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                color: "#D1D5DB", // Light gray text color for better readability
                                borderRadius: "8px",
                                maxWidth: "600px", // Limiting width for better structure // Centering in the view
                                fontFamily:
                                  "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif", // Modern font family
                                width: "100%",
                                alignItems: "",
                              }}
                            >
                              <div
                                style={{ margin: "10px 0", display: "flex" }}
                              >
                                <strong
                                  style={{
                                    fontWeight: "bold",
                                    color:
                                      "hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)))",
                                    fontSize: "16px",
                                    lineHeight: "1.5",
                                  }}
                                >
                                  Users:
                                </strong>
                                <Spacer y={1} />
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "flex-start",
                                    width: "100%",
                                    gap: "0.5rem",
                                  }}
                                >
                                  {selectedFeed?.users.map((user) => (
                                    <Chip
                                      key={user}
                                      variant="solid"
                                      color="default"
                                      className="dark"
                                      avatar={
                                        <Avatar
                                          name={user}
                                          src={
                                            profilePics[user]
                                              ? `${profilePics[user]}`
                                              : ""
                                          }
                                        />
                                      }
                                    >
                                      {user}
                                    </Chip>
                                  ))}
                                </div>
                              </div>
                              <p
                                style={{
                                  margin: "10px 0",
                                  fontSize: "16px",
                                  lineHeight: "1.5",
                                }}
                              >
                                <strong
                                  style={{
                                    fontWeight: "bold",
                                    color:
                                      "hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)))",
                                  }}
                                >
                                  Time:
                                </strong>{" "}
                                {moment(selectedFeed?.timeOfConsumption).format(
                                  "DD-MM-YYYY HH:mm"
                                )}
                              </p>
                              {selectedFeed?.type && (
                                <p
                                  style={{
                                    margin: "10px 0",
                                    fontSize: "16px",
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <strong
                                    style={{
                                      fontWeight: "bold",
                                      color:
                                        "hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)))",
                                    }}
                                  >
                                    Type:
                                  </strong>{" "}
                                  {selectedFeed?.type.charAt(0).toUpperCase() +
                                    selectedFeed?.type.slice(1).toLowerCase()}
                                </p>
                              )}
                              {(selectedFeed?.type === "JOINT" ||
                                selectedFeed?.type === "BLUNT") && (
                                <p
                                  style={{
                                    margin: "10px 0",
                                    fontSize: "16px",
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <strong
                                    style={{
                                      fontWeight: "bold",
                                      color:
                                        "hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)))",
                                    }}
                                  >
                                    Size:
                                  </strong>{" "}
                                  {selectedFeed?.size.charAt(0).toUpperCase() +
                                    selectedFeed?.size.slice(1).toLowerCase()}
                                </p>
                              )}
                              <p
                                style={{
                                  margin: "10px 0",
                                  fontSize: "16px",
                                  lineHeight: "1.5",
                                }}
                              >
                                <strong
                                  style={{
                                    fontWeight: "bold",
                                    color:
                                      "hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)))",
                                  }}
                                >
                                  Hash:
                                </strong>{" "}
                                {selectedFeed?.hash ? "Yes" : "No"}
                              </p>
                              <p
                                style={{
                                  margin: "10px 0",
                                  fontSize: "16px",
                                  lineHeight: "1.5",
                                }}
                              >
                                <strong
                                  style={{
                                    fontWeight: "bold",
                                    color:
                                      "hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)))",
                                  }}
                                >
                                  Mixed:
                                </strong>{" "}
                                {selectedFeed?.mixed ? "Yes" : "No"}
                              </p>
                              <p
                                style={{
                                  margin: "10px 0",
                                  fontSize: "16px",
                                  lineHeight: "1.5",
                                }}
                              >
                                <strong
                                  style={{
                                    fontWeight: "bold",
                                    color:
                                      "hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-bg-opacity)))",
                                  }}
                                >
                                  With randoms:
                                </strong>{" "}
                                {selectedFeed?.withRandoms ? "Yes" : "No"}
                              </p>
                            </div>
                          )}
                        </ModalBody>
                        <ModalFooter>
                          {isInfoSelected && (
                            <Button
                              variant="solid"
                              color="primary"
                              onPress={redirectToEdit}
                            >
                              Edit
                            </Button>
                          )}
                          {!isInfoSelected && (
                            <Button
                              color="primary"
                              variant="solid"
                              onPress={() =>
                                downloadImage(
                                  `data:image/jpeg;base64,${selectedImage}`
                                )
                              }
                            >
                              Download
                            </Button>
                          )}
                          <Button
                            variant="solid"
                            color="danger"
                            onPress={onClose}
                          >
                            Close
                          </Button>
                        </ModalFooter>
                      </>
                    )}
                  </>
                )}
              </ModalContent>
            ) : (
              <ModalContent>
                {(onClose) => (
                  <>
                    <ModalHeader className="flex flex-col gap-1 text-white">
                      Filters
                    </ModalHeader>
                    <ModalBody style={{ color: "white" }}>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <Select
                            label="Users"
                            selectionMode="multiple"
                            placeholder="Select users"
                            selectedKeys={selectedUsers}
                            onSelectionChange={setSelectedUsers}
                            className="dark"
                            listboxProps={{ className: "dark text-white" }}
                            popoverProps={{ className: "dark" }}
                          >
                            {users.map((user) => (
                              <SelectItem key={user} value={user}>
                                {user}
                              </SelectItem>
                            ))}
                          </Select>
                          <FontAwesomeIcon
                            onClick={() => resetFilter(1)}
                            icon={faRotateRight}
                            style={{ margin: "0 0 0 0.8rem" }}
                          />
                        </div>{" "}
                        <Spacer y={5} />{" "}
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <DateRangePicker
                            key={key} // Use the key to force re-render
                            label="Time of consumption"
                            className="max-w-xs dark"
                            value={dateRange}
                            onChange={setDateRange}
                            calendarProps={{ className: "dark" }}
                          />
                          <FontAwesomeIcon
                            onClick={() => resetFilter(6)}
                            icon={faRotateRight}
                            style={{ margin: "0 0 0 0.8rem" }}
                          />
                        </div>
                        <Spacer y={5} />
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <Select
                            label="Types"
                            selectionMode="multiple"
                            placeholder="Select types"
                            selectedKeys={selectedTypes}
                            onSelectionChange={setSelectedTypes}
                            className="dark"
                            listboxProps={{ className: "dark text-white" }}
                            popoverProps={{ className: "dark" }}
                          >
                            {types.map((type) => (
                              <SelectItem key={type} value={type}>
                                {type}
                              </SelectItem>
                            ))}
                          </Select>
                          <FontAwesomeIcon
                            onClick={() => resetFilter(2)}
                            icon={faRotateRight}
                            style={{ margin: "0 0 0 0.8rem" }}
                          />
                        </div>
                        <Spacer y={5} />
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <Select
                            label="Sizes"
                            selectionMode="multiple"
                            placeholder="Select sizes"
                            selectedKeys={selectedSize}
                            onSelectionChange={setSelectedSize}
                            className="dark"
                            listboxProps={{ className: "dark text-white" }}
                            popoverProps={{ className: "dark" }}
                          >
                            {sizes.map((option) => (
                              <SelectItem key={option} value={option}>
                                {option}
                              </SelectItem>
                            ))}
                          </Select>{" "}
                          <FontAwesomeIcon
                            onClick={() => resetFilter(3)}
                            icon={faRotateRight}
                            style={{ margin: "0 0 0 0.8rem" }}
                          />
                        </div>{" "}
                        <Spacer y={5} />{" "}
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <Select
                            label="Mixed"
                            placeholder="Select mixed"
                            selectedKeys={
                              isMixed === undefined ? [] : [isMixed]
                            }
                            className="max-w-xs dark"
                            onChange={handleSelectionChange}
                            listboxProps={{ className: "dark text-white" }}
                            popoverProps={{ className: "dark" }}
                          >
                            {mixedOptions.map((option) => (
                              <SelectItem key={option}>{option}</SelectItem>
                            ))}
                          </Select>{" "}
                          <FontAwesomeIcon
                            onClick={() => resetFilter(4)}
                            icon={faRotateRight}
                            style={{ margin: "0 0 0 0.8rem" }}
                          />
                        </div>
                        <Spacer y={5} />{" "}
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <Select
                            label="With randoms"
                            placeholder="Select with randoms"
                            selectedKeys={
                              withRandoms === undefined ? [] : [withRandoms]
                            }
                            className="max-w-xs dark"
                            onChange={handleSelectionChangeRandoms}
                            listboxProps={{ className: "dark text-white" }}
                            popoverProps={{ className: "dark" }}
                          >
                            {withRandomsOptions.map((option) => (
                              <SelectItem key={option}>{option}</SelectItem>
                            ))}
                          </Select>{" "}
                          <FontAwesomeIcon
                            onClick={() => resetFilter(5)}
                            icon={faRotateRight}
                            style={{ margin: "0 0 0 0.8rem" }}
                          />
                        </div>
                        <Spacer y={10} />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Button onClick={() => resetFilter(0)}>
                            <FontAwesomeIcon icon={faRotateRight} /> All
                          </Button>
                          <div
                            style={{
                              display: "flex",
                              gap: "0.5rem",
                            }}
                          >
                            <Button
                              color="danger"
                              variant="solid"
                              onPress={onClose}
                            >
                              Close
                            </Button>
                            <Button
                              color="primary"
                              variant="solid"
                              onClick={updateFilters}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                        <Spacer y={3} />
                      </div>
                    </ModalBody>
                  </>
                )}
              </ModalContent>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
}
