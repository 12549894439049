import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "../../axiosInstance";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
  Spacer,
  User,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Button,
  Skeleton,
} from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faCircleExclamation,
  faHeart as faHeartSolid,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import RequireAuth from "../../requireAuth";
import moment from "moment";
import CommentSection from "./CommentSection";
import { toast } from "react-toastify";
import VideoPlayer from "../VideoJS/VideoPlayer";

function Post({ post, profilePics }) {
  const [imageSrc, setImageSrc] = useState("");
  const [isLiked, setIsLiked] = useState(false);
  const imageRef = useRef(null);
  const lastTapRef = useRef(null); // To store the timestamp of the last tap
  const [likeCount, setLikeCount] = useState(0);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const iconRef = useRef(null);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [inputValid, setInputValid] = useState(true);
  const mediaType = post.mediaType;
  const [media, setMedia] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const videoOptions = useMemo(
    () => ({
      autoplay: true,
      controls: true, // Enable controls to manipulate them
      controlBar: {
        volumePanel: { inline: false }, // Show volume control
        playToggle: true, // Show play button
        currentTimeDisplay: false, // Hide current time display
        timeDivider: false, // Hide time divider
        durationDisplay: false, // Hide duration display
        remainingTimeDisplay: false, // Hide remaining time display
        progressControl: false, // Hide progress bar
        fullscreenToggle: false, // Hide fullscreen toggle
        playbackRateMenuButton: false, // Hide playback rate button
        pictureInPictureToggle: false, // Hide PiP button
        liveDisplay: false, // Hide live display
      },
      responsive: true,
      fluid: true,
      loop: false,
      sources: [
        {
          src: media,
          type: "video/mp4",
        },
      ],
    }),
    [media]
  );

  useEffect(() => {
    setLikeCount(post.likes ? Object.keys(post.likes).length : 0);
    const fetchMedia = async () => {
      setIsLoading(true);
      if (mediaType === "IMAGE") {
        try {
          const response = await axios.get(`/getImage?id=${post.media}`);
          setImageSrc(`data:image/jpeg;base64,${response.data}`);
        } catch (error) {
          console.error("Error fetching image:", error);
          setImageSrc("/images/placeholder.png");
        }
      } else {
        try {
          const response = await axios.get(`/getVideoBinary?id=${post.media}`, {
            responseType: "blob", // This tells axios to fetch the data as a blob
          });
          const videoURL = URL.createObjectURL(response.data);
          setMedia(videoURL);
        } catch (error) {
          console.error("Error fetching image:", error);
        }
      }
      setIsLoading(false);
    };

    const username = RequireAuth.getUsername();
    setIsLiked(post.likes && post.likes.hasOwnProperty(username));
    fetchMedia();
  }, [post, mediaType]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (comment.length > 0 && comment.length <= 100) {
      try {
        const response = await axios.post(`/post/comment/${post.id}`, comment, {
          headers: { "Content-Type": "text/plain" },
        });
        setComments((prevComments) => [response.data, ...prevComments]);
        setComment("");
      } catch (error) {
        console.error("Failed to post comment:", error);
        toast.error("Failed to upload comment", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }
    } else {
      toast.error("Comments must be between 1 and 100 characters.", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const handleInputChange = (e) => {
    const newComment = e.target.value;
    if (newComment.length === 101) {
      setInputValid(false);
      setComment(newComment);
    } else if (newComment.length <= 100) {
      setInputValid(true);
      setComment(newComment);
    }
  };

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(`/post/comment/${post.id}`);
        setComments(response.data); // Assuming the API returns an array of Comment objects
      } catch (error) {
        console.error("Error fetching comments:", error);
        setComments([]);
      }
    };

    if (isOpen) {
      fetchComments();
    }
  }, [isOpen, post.id]);

  function calculateTimeAgo(time) {
    return time ? moment(time).fromNow() : "some time ago";
  }

  const handleDoubleTap = async (type) => {
    if (type === 1 || !isLiked) {
      try {
        await axios.post(`/post/toggleLike/${post.id}`);
        setLikeCount(isLiked ? likeCount - 1 : likeCount + 1);
        setIsLiked(!isLiked);
        if (iconRef.current) {
          iconRef.current.classList.toggle("active", !isLiked);
        }
      } catch (error) {
        console.error("Error toggling like:", error);
      }
    }
  };

  const handleTouch = (e) => {
    const currentTime = new Date().getTime();
    const tapDelay = 300; // milliseconds

    if (lastTapRef.current && currentTime - lastTapRef.current < tapDelay) {
      handleDoubleTap(2);
    }

    lastTapRef.current = currentTime;
  };

  return (
    <div style={{ margin: "1rem 0" }}>
      <Card
        style={{
          minWidth: "100vw",
          maxWidth: "100dvw",
          backgroundColor: "black",
        }}
      >
        <CardHeader>
          <User
            avatarProps={{
              src: profilePics[post.username]
                ? `${profilePics[post.username]}`
                : null,
            }}
            name={post.username}
            description={calculateTimeAgo(post.time)}
          />
        </CardHeader>
        <CardBody>
          <div style={{ display: "flex", placeContent: "center" }}>
            {isLoading && (
              <Skeleton className="rounded-lg">
                <div style={{ height: "30dvh", width: "100dvw" }}></div>
              </Skeleton>
            )}
            {mediaType === "IMAGE" && (
              <Image
                id={post.image}
                radius="sm"
                src={imageSrc}
                style={{
                  maxWidth: "70vw",
                  maxHeight: "50vh",
                  cursor: "pointer",
                }}
                ref={imageRef}
                onDoubleClick={() => handleDoubleTap(2)}
                onTouchEnd={handleTouch} // Handle touch to detect double taps
              />
            )}
            {mediaType === "VIDEO" && media && (
              <VideoPlayer
                options={videoOptions}
                onReady={(player) => {}}
                style={{
                  maxWidth: "70vw",
                  maxHeight: "50vh",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </CardBody>
        <CardFooter style={{ paddingTop: "0" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div style={{ display: "flex" }}>
              <FontAwesomeIcon
                ref={iconRef}
                onClick={() => handleDoubleTap(1)}
                icon={isLiked ? faHeartSolid : faHeart}
                style={{ cursor: "pointer", width: "1.8rem", height: "1.8rem" }}
                size="lg"
                color={isLiked ? "red" : undefined}
              />
              <Spacer x={6} />
              <FontAwesomeIcon
                icon={faComment}
                size="lg"
                className="flip-vertical"
                style={{ cursor: "pointer", width: "1.8rem", height: "1.8rem" }}
                onClick={onOpen}
              />
            </div>
            <p style={{ fontSize: "1.3rem" }}>{likeCount} Likes</p>
            <span style={{ width: "100%" }}>
              <strong>{post.username}</strong> {post.caption}
            </span>
          </div>
        </CardFooter>
      </Card>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="bottom"
        backdrop="blur"
        scrollBehavior="inside"
        style={{
          width: "100%",
          backgroundColor: "#1e1e1e",
          maxHeight: "80vh",
          margin: 0,
          minHeight: "70dvh",
        }}
        radius="sm"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col text-white items-center">
                Comments
              </ModalHeader>
              <ModalBody className="text-white" style={{ padding: "1rem" }}>
                <CommentSection comments={comments} profilePics={profilePics} />
              </ModalBody>
              <ModalFooter style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <form onSubmit={handleSubmit}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        gap: "1rem",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        {!inputValid && (
                          <div
                            style={{
                              textAlign: "center",
                              color: "red",
                              marginBottom: "1rem",
                              fontSize: "0.9rem",
                              display: "flex",
                              alignItems: "center",
                              gap: "0.5rem",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              color="red"
                            />
                            <p>Comment can be max 100 characters</p>
                          </div>
                        )}
                        <Input
                          className="dark"
                          type="text"
                          label="Add a comment"
                          size="sm"
                          style={{ width: "100%" }}
                          value={comment}
                          onChange={handleInputChange}
                          isInvalid={!inputValid}
                        />
                      </div>
                      <Button
                        type="submit"
                        color="primary"
                        isIconOnly
                        size="lg"
                        isDisabled={!inputValid}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </Button>
                    </div>
                  </form>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}

export default Post;
