import React, { useEffect, useState } from "react";
import axios from "../../axiosInstance";
import {
  Select,
  SelectItem,
  Button,
  Spacer,
  Input,
  SelectSection,
} from "@nextui-org/react";
import Navbar from "../Navbar/Navbar";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faTrophy } from "@fortawesome/free-solid-svg-icons";
import Confetti from "../Confetti/Confetti";
import imageCompression from "browser-image-compression";
import { faClock, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { json } from "react-router-dom";

function SnusForm() {
  const [users, setUsers] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [image, setImage] = useState(null); // New state for image
  const [timeOfConsumption, setTimeOfConsumption] = useState(() => {
    const current = new Date();
    const localISOTime = new Date(
      current.getTime() - current.getTimezoneOffset() * 60000
    ).toISOString();
    return localISOTime.substring(0, 16);
  });
  const [snusEntries, setSnusEntries] = useState([{ users: [], snusId: "" }]);
  const [snusOptions, setSnusOptions] = useState({});

  useEffect(() => {
    setUsers(["Vincent", "Bumbo", "Milan"]);

    const fetchSnus = async () => {
      try {
        const response = await axios.get("/snus");
        // Organize locations by area
        const areaGroups = response.data.reduce((acc, snus) => {
          acc[snus.brand] = acc[snus.brand] || [];
          acc[snus.brand].push(snus);
          return acc;
        }, {});
        setSnusOptions(areaGroups);
      } catch (error) {
        console.error("Failed to fetch locations:", error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get("/getAllUsers");
        // Append new users to the existing users array
        setUsers((currentUsers) => [...response.data, ...currentUsers]);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchUsers();
    fetchSnus();
  }, []);

  const handleAddEntry = () =>
    setSnusEntries([...snusEntries, { users: [], snusId: "" }]);

  const handleRemoveEntry = (index) =>
    setSnusEntries(snusEntries.filter((_, i) => i !== index));

  const handleUserSelectionChange = (index, selectedUsers) => {
    const updatedEntries = snusEntries.map((entry, i) => {
      if (i === index) {
        return { ...entry, users: selectedUsers.target.value };
      }
      return entry;
    });
    setSnusEntries(updatedEntries);
  };

  const handleSnusChange = (index, snusId) => {
    const updatedEntries = snusEntries.map((entry, i) => {
      if (i === index) {
        return { ...entry, snusId: snusId.target.value };
      }
      return entry;
    });
    setSnusEntries(updatedEntries);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Only specify maxSizeMB to limit the file size
      const options = {
        maxSizeMB: 1, // (Max file size in MB)
        useWebWorker: true, // (Use a web worker for better performance in compressing the image)
      };
      const loadingToast = toast.loading("Compressing photo...", {
        position: "top-center",
        theme: "dark",
      });
      try {
        const compressedFile = await imageCompression(file, options);
        setImage(compressedFile); // Set the compressed image
        toast.dismiss(loadingToast);
        setTimeout(() => {
          toast.success("Image processed successfully.", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "dark",
          });
        }, 500);
      } catch (error) {
        toast.dismiss(loadingToast);
        setTimeout(() => {
          console.error("Compression Error:", error);
        }, 500);
        toast.error("Failed to process image.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const snusCons = {};
    snusEntries.forEach((entry) => {
      Array.from(entry.users.split(",")).forEach((user) => {
        console.log(user);
        if (!snusCons[user]) {
          snusCons[user] = [];
        }
        if (entry.snusId && !snusCons[user].includes(entry.snusId)) {
          snusCons[user].push(entry.snusId);
        }
      });
    });

    const snusTimeData = {
      snusCons: snusCons,
      time: `${timeOfConsumption}:00`,
    };

    const formData = new FormData();
    formData.append("image", image);
    formData.append("snusTime", JSON.stringify(snusTimeData));

    const loadingToast = toast.loading("Submitting entry...", {
      position: "top-center",
      theme: "dark",
    });

    try {
      const response = await axios.post("/snus/createSnusTime", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.dismiss(loadingToast);
      console.log("Submission successful", response.data);
      setTimeout(() => {
        checkForNewTrophies();
        toast.success("ITS SNUUUUS O'CLOCK", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 8000);
      }, 500);
    } catch (error) {
      toast.dismiss(loadingToast);
      console.error("Failed to submit form:", error);

      // Extracting error message from the response
      const errorMessage =
        error.response && error.response.data
          ? error.response.data
          : "Unknown error occurred";

      setTimeout(() => {
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }, 500);
    }
  };

  // Initialize state to store maxWidth
  const [maxWidth, setMaxWidth] = useState(
    window.innerWidth > 768 ? "600px" : "300px"
  );

  // Effect to add window resize listener
  useEffect(() => {
    const handleResize = () => {
      // Update maxWidth based on window width
      setMaxWidth(window.innerWidth > 768 ? "600px" : "300px");
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array means this effect runs once on mount

  const resetImage = () => {
    setImage(null); // Reset the image state to null
    setTimeout(() => {
      document.getElementById("imageInput").value = ""; // Reset the file input field
    }, 100);
  };

  const checkForNewTrophies = async () => {
    var usersList = [];
    snusEntries.forEach((entry) => {
      if (!entry.users) {
        return;
      }
      Array.from(entry.users.split(",")).forEach((user) => {
        if (!usersList.includes(user)) {
          usersList.push(user);
        }
      });
    });
    try {
      const response = await axios.get(
        "/snus/checkForNewTrophies?selectedUsers=" +
          encodeURIComponent(usersList)
      );
      const newTrophies = response.data;

      if (newTrophies.length > 0) {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 8000);
        newTrophies.forEach((trophy) => {
          const iconColor = {
            color: trophy.colour,
          };

          toast.success(
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "white",
              }}
            >
              <span style={{ margin: "0 1rem" }}>
                {trophy.title} - {trophy.brand}
              </span>
              <FontAwesomeIcon icon={faTrophy} style={iconColor} />
            </div>,
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              theme: "dark",
              style: { background: "#8031ff" },
            }
          );
        });
      }
    } catch (error) {
      console.error("Error fetching new trophies:", error);
      toast.error("Failed to fetch new trophies");
    }
  };

  function redirectToNewFlavor() {
    window.location.href = "/newFlavor";
  }

  return (
    <div>
      <Navbar active={"Snus time"} />
      {showConfetti && <Confetti />}
      <div
        style={{
          maxWidth: maxWidth,
          margin: "0 auto",
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div style={{ margin: "5rem 0" }}>
          <h1
            style={{
              fontSize: "2rem",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            <FontAwesomeIcon icon={faClock} /> Snus O'Clock
          </h1>
          <form onSubmit={handleSubmit}>
            <Spacer y={5} />
            {snusEntries.map((entry, index) => (
              <div key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div style={{ width: "145px" }}>
                    <Select
                      clearable
                      selectionMode="multiple"
                      label="Select Users"
                      value={Array.from(entry.users)} // Convert Set to Array for the value prop
                      onChange={(value) =>
                        handleUserSelectionChange(index, value)
                      }
                      listboxProps={{ className: "dark text-white" }}
                      popoverProps={{ className: "dark" }}
                    >
                      {users.map((user) => (
                        <SelectItem key={user} value={user}>
                          {user}
                        </SelectItem>
                      ))}
                    </Select>
                  </div>
                  <div style={{ width: "145px" }}>
                    <Select
                      clearable
                      label="Select Snus"
                      value={entry.snusId}
                      onChange={(value) => handleSnusChange(index, value)}
                      listboxProps={{ className: "dark text-white" }}
                      popoverProps={{ className: "dark" }}
                    >
                      {Object.entries(snusOptions).map(([brand, snusItems]) => (
                        <SelectSection key={brand} title={brand}>
                          {snusItems.map((snus) => (
                            <SelectItem key={snus.id} value={snus.id}>
                              {snus.flavor}
                            </SelectItem>
                          ))}
                        </SelectSection>
                      ))}
                    </Select>
                  </div>
                </div>
                {index === snusEntries.length - 1 && snusEntries.length > 1 && (
                  <Button
                    auto
                    flat
                    onClick={() => handleRemoveEntry(index)}
                    style={{
                      width: "100%",
                      marginTop: "0.5rem",
                      backgroundColor:
                        "hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)))",
                      padding: "1.5rem",
                    }}
                  >
                    <FontAwesomeIcon icon={faMinus} /> Remove
                  </Button>
                )}
                <Spacer y={2} />
              </div>
            ))}
            <Button
              style={{
                width: "100%",
                backgroundColor:
                  "hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)))",
                padding: "1.5rem",
              }}
              onClick={handleAddEntry}
            >
              <FontAwesomeIcon icon={faPlus} /> Add
            </Button>
            <Spacer y={5} />
            <Button
              style={{
                width: "100%",
                backgroundColor:
                  "hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)))",
                padding: "1.5rem",
              }}
              onClick={() => redirectToNewFlavor()}
            >
              <FontAwesomeIcon icon={faPlus} /> New flavor
            </Button>
            <Spacer y={5} />
            <Input
              label="Time of Consumption"
              type="datetime-local"
              underlined
              fullWidth
              value={timeOfConsumption}
              onChange={(e) => setTimeOfConsumption(e.target.value)}
              css={{
                maxWidth: "100%",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            />
            <Spacer y={5} />
            {image !== null && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Input
                  label="Selected Image"
                  value={image.name}
                  isReadOnly={true}
                  endContent={
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      style={{ cursor: "pointer" }}
                      onClick={resetImage}
                    />
                  }
                />
              </div>
            )}
            {!image && ( // Show this div only if image is null
              <div>
                <Button
                  auto
                  flat
                  style={{
                    width: "100%",
                    backgroundColor:
                      "hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)))",
                    padding: "1.5rem",
                  }}
                  onClick={() => document.getElementById("imageInput").click()}
                >
                  Add image
                </Button>
                <input
                  type="file"
                  id="imageInput"
                  style={{ display: "none", width: "100%" }}
                  onChange={handleImageChange}
                  accept="image/*"
                />
              </div>
            )}
            <Spacer y={5} />
            <Button
              type="submit"
              shadow
              color="primary"
              style={{
                width: "100%",
              }}
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SnusForm;
