import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import axios from "../../../axiosInstance";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from "@nextui-org/react";

const BarChart = () => {
  const [dynamicStyle, setDynamicStyle] = useState();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Amount consumed",
        data: [],
        backgroundColor: [
          "rgba(156,41,234, 0.5)",
          "rgba(136,64,234, 0.5)",
          "rgba(116,87,234, 0.5)",
          "rgba(96,110,234, 0.5)",
          "rgba(76,133,234, 0.5)",
          "rgba(66,156,234, 0.5)",
          "rgba(46,179,234, 0.5)",
        ],
        borderColor: [
          "rgba(156,41,234, 1)",
          "rgba(136,64,234, 1)",
          "rgba(116,87,234, 1)",
          "rgba(96,110,234, 1)",
          "rgba(76,133,234, 1)",
          "rgba(66,156,234, 1)",
          "rgba(46,179,234, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });
  const [selectedKeys, setSelectedKeys] = React.useState(new Set(["MEDIUM"])); // Default selection
  const selectedValue = React.useMemo(
    () => Array.from(selectedKeys).join(", ").replaceAll("_", " "),
    [selectedKeys]
  );

  useEffect(() => {
    const setInitialStyle = () => {
      const style =
        window.innerWidth < 768
          ? {
              height: "fit-content",
              width: "90%", // Default width for non-mobile devices
              padding: "2rem",
              backgroundColor: "#1e1e1e",
              borderRadius: "2rem", // Adjust the width for mobile devices
            }
          : {
              height: "fit-content",
              width: "40%", // Default width for non-mobile devices
              margin: "0rem 3rem",
              padding: "2rem",
              backgroundColor: "#1e1e1e",
              borderRadius: "2rem",
            };

      setDynamicStyle(style);
    };

    setInitialStyle();
    // Adjust the window resize listener if necessary for dynamic responsiveness
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const size = Array.from(selectedKeys)[0]; // Use selected dropdown value
      try {
        const response = await axios.get(
          `/stats/leaderboard/getBySize?size=${size}`
        );
        const data = response.data;
        const updatedLabels = Object.keys(data);
        const updatedData = Object.values(data);

        setChartData((prevChartData) => ({
          ...prevChartData,
          labels: updatedLabels,
          datasets: prevChartData.datasets.map((dataset) => ({
            ...dataset,
            data: updatedData,
          })),
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedKeys]); // Re-fetch data when selectedKeys changes

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false, // Adjust based on your preference
      },
    },
  };

  return (
    <div style={dynamicStyle}>
      <h1 style={{ textAlign: "center" }}>Amount consumed by size</h1>
      <Bar data={chartData} options={options} />
      <Dropdown className="dark">
        <DropdownTrigger>
          <Button
            variant="bordered"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "150px",
              float: "right",
              marginTop: "1rem",
            }}
          >
            <span>Size: </span>
            {selectedValue}
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          aria-label="Single selection example"
          variant="flat"
          disallowEmptySelection
          selectionMode="single"
          selectedKeys={selectedKeys}
          onSelectionChange={setSelectedKeys}
          className="text-white"
        >
          <DropdownItem key="XSMALL">XSMALL</DropdownItem>
          <DropdownItem key="SMALL">SMALL</DropdownItem>
          <DropdownItem key="MEDIUM">MEDIUM</DropdownItem>
          <DropdownItem key="LARGE">LARGE</DropdownItem>
          <DropdownItem key="XLARGE">XLARGE</DropdownItem>
          <DropdownItem key="XXLARGE">XXLARGE</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default BarChart;
