import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spacer } from "@nextui-org/react";
import React from "react";
import moment from "moment";

function Notification({ notification, type }) {
  function calculateTimeAgo(time) {
    return time ? moment(time).fromNow() : "some time ago";
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#1e1e1e",
        borderRadius: "1rem",
        padding: "0.7rem",
        margin: "0.7rem 0 0 0",
        border: "1px solid rgb(53, 53, 53)",
        gap: "0.1rem",
      }}
      className={notification.read || type === "all" ? "" : "unread-noti"}
    >
      <FontAwesomeIcon icon={faCircleInfo} size="lg" color="white" />
      <Spacer x={3} />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <p className="text-white">{notification.message}</p>
        <p style={{ fontSize: "0.8rem", color: "grey", textAlign: "left" }}>
          {calculateTimeAgo(notification.time)}
        </p>
      </div>
    </div>
  );
}
export default Notification;
