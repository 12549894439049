import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import { faFlag, faTrash, faUserGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Divider,
  Spacer,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Table,
  TableColumn,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Select,
  Textarea,
  SelectItem,
  Switch,
} from "@nextui-org/react";
import moment from "moment";
import axios from "../../axiosInstance";
import { toast } from "react-toastify";

const AdminPanel = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [globalData, setGlobalData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [notificationMessage, setNotificationMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [celebrate, setCelebrate] = useState(false);
  const [announce, setAnnounce] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }

    const fetchUsers = async () => {
      try {
        const response = await axios.get("/getAllUsers");
        setUsers(response.data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };
    fetchUsers();
  }, [isMobile]);

  const isDeleteButtonDisabled = () => {
    if (selectedRows.size !== undefined) {
      return selectedRows.size === 0 ? true : false;
    } else {
      return true;
    }
  };

  const widthDisplay = isMobile ? "90%" : "50%";

  const handleDeleteEntries = () => {
    const ids = Array.from(selectedRows)
      .map((key) => {
        // Find the data object in globalData with the corresponding key
        const data = globalData.find((item) => item.id === key);
        return data ? data.id : null;
      })
      .filter((id) => id !== null); // We filter out any null values in case some keys didn't match
    deleteEntries(ids);
  };

  const deleteEntries = async (ids) => {
    const loadingToast = toast.loading("Deleting entry...", {
      position: "top-center",
      theme: "dark",
    });
    try {
      await axios.delete("stats/deleteEntries", {
        params: { deleteIds: ids.join(",") }, // Join IDs into a comma-separated string
      });
      toast.dismiss(loadingToast);
      setSelectedRows([]);
      globalData.forEach((entry) => {
        if (ids.includes(entry.id)) {
          const index = globalData.indexOf(entry);
          globalData.splice(index, 1);
        }
      });
      onOpenChange();
      setTimeout(() => {
        toast.success("Successfully deleted entry", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }, 500);
    } catch (error) {
      toast.dismiss(loadingToast);
      console.error("Failed to submit form:", error);

      // Extracting error message from the response
      const errorMessage =
        error.response && error.response.data
          ? error.response.data
          : "Unknown error occurred";

      setTimeout(() => {
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }, 500);
    }
  };

  const deleteClick = (type) => {
    onOpen();
    setGlobalData([]);
    setLoading(true);
    if (type === "global") {
      const fetchGlobalData = async () => {
        try {
          const response = await axios.get("/stats/getLiveFeed", {
            params: { global: true }, // Conditionally set global parameter
          });
          setGlobalData(response.data);
        } catch (error) {
          console.error("Error fetching live feed data:", error);
        }
      };
      fetchGlobalData();
    } else {
      const fetchFlaggedData = async () => {
        try {
          const response = await axios.get("/stats/getFlaggedEntries");
          setGlobalData(response.data);
        } catch (error) {
          console.error("Error fetching flagged entries:", error);
        }
      };
      fetchFlaggedData();
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("users", Array.from(selectedUsers).join(","));
    formData.append("message", notificationMessage);
    formData.append("celebrate", celebrate);
    formData.append("announce", announce);

    const loadingToast = toast.loading("Creating notification...", {
      position: "top-center",
      theme: "dark",
    });
    try {
      const response = await axios.post("/createNotification", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.dismiss(loadingToast);
      console.log("Submission successful", response.data);
      setTimeout(() => {
        toast.success("Successfully create notification", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }, 500);
    } catch (error) {
      toast.dismiss(loadingToast);
      console.error("Failed to submit form:", error);

      // Extracting error message from the response
      const errorMessage =
        error.response && error.response.data
          ? error.response.data
          : "Unknown error occurred";

      setTimeout(() => {
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }, 500);
    }
  };

  const loadingOrEmpty = loading ? "Loading..." : "No entries found";

  return (
    <div>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "5rem 0",
          justifyContent: "flex-start",
          overflowX: "auto",
        }}
      >
        <Navbar active="admin" />
        <h1 style={{ margin: "1rem 0", fontSize: "2rem" }}>
          <FontAwesomeIcon icon={faUserGear} /> Admin Panel
        </h1>
        <div
          style={{
            height: "fit-content",
            width: widthDisplay,
            padding: "2rem",
            backgroundColor: "#1e1e1e",
            borderRadius: "2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <h1 style={{ fontSize: "1.5rem" }}>Entries</h1>
            <Divider />
            <Spacer y={5} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                variant="flat"
                color="danger"
                onPress={() => deleteClick("global")}
              >
                <FontAwesomeIcon icon={faTrash} />
                Delete entry
              </Button>
              <Spacer y={5} />
              <Button
                variant="flat"
                color="danger"
                onPress={() => deleteClick("flagged")}
              >
                <FontAwesomeIcon icon={faFlag} />
                Delete flagged
              </Button>
            </div>
            <Spacer y={5} />
            <h1 style={{ fontSize: "1.5rem" }}>Notifications</h1>
            <Divider />
            <Spacer y={5} />
            <div style={{ width: "100%" }}>
              <form onSubmit={handleSubmit}>
                {" "}
                <Select
                  label="Select users"
                  selectionMode="multiple"
                  placeholder="Select users"
                  selectedKeys={selectedUsers}
                  onSelectionChange={setSelectedUsers}
                  isRequired
                  listboxProps={{ className: "dark text-white" }}
                  popoverProps={{ className: "dark" }}
                >
                  {users.map((user) => (
                    <SelectItem key={user} value={user}>
                      {user}
                    </SelectItem>
                  ))}
                </Select>
                <Spacer y={5} />
                <Textarea
                  label="Message"
                  placeholder="Enter your message"
                  value={notificationMessage}
                  onValueChange={setNotificationMessage}
                  isRequired
                />
                <Spacer y={5} />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Switch onValueChange={setCelebrate} value={celebrate}>
                    <p>Celebrate 🥳</p>
                  </Switch>
                  <Switch onValueChange={setAnnounce} value={announce}>
                    <p>Announce</p>
                  </Switch>
                </div>
                <Spacer y={5} />
                <Button color="primary" type="submit" style={{ width: "100%" }}>
                  Send
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="lg"
        backdrop="blur"
        placement="center"
        hideCloseButton={true}
        style={{ width: "85%", backgroundColor: "#1e1e1e", maxHeight: "70%" }}
        scrollBehavior="inside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader style={{ color: "white" }}>
                Delete an Entry
              </ModalHeader>
              <ModalBody>
                <Table
                  isStriped
                  aria-label="Leaderboard table with dynamic content"
                  style={{ padding: "2rem" }}
                  className="dark text-foreground"
                  selectionMode="multiple"
                  selectedRows={selectedRows}
                  onSelectionChange={(rows) => setSelectedRows(new Set(rows))}
                >
                  <TableHeader>
                    <TableColumn>
                      <span>Users</span>
                    </TableColumn>
                    <TableColumn>
                      <span
                        style={{
                          display: "block",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        Time
                      </span>
                    </TableColumn>
                  </TableHeader>
                  <TableBody emptyContent={loadingOrEmpty}>
                    {globalData.map((feed) => (
                      <TableRow key={feed.id}>
                        <TableCell>
                          {feed.users.map((user, index) => (
                            <span key={index} style={{ marginRight: "-1rem" }}>
                              {user}
                              {index < feed.users.length - 1 && <br />}
                            </span>
                          ))}
                        </TableCell>
                        <TableCell>
                          <span>
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "0.8rem",
                              }}
                            >
                              {moment(feed.timeOfConsumption).format(
                                "DD-MM-YYYY"
                              )}
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "0.8rem",
                              }}
                            >
                              {moment(feed.timeOfConsumption).format("HH:mm")}
                            </p>
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="bordered" onPress={onClose}>
                  Close
                </Button>
                <Spacer x={1} />
                <Button
                  color="danger"
                  variant="bordered"
                  onPress={handleDeleteEntries}
                  isDisabled={isDeleteButtonDisabled()}
                >
                  <FontAwesomeIcon icon={faTrash} /> Delete entries
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AdminPanel;
