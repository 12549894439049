import React, { useEffect, useState } from "react";
import axios from "../../../axiosInstance";
import {
  Table,
  TableColumn,
  TableRow,
  TableCell,
  TableHeader,
  TableBody,
  User,
  Button,
} from "@nextui-org/react";
import { faCrown, faL } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

const BarChart = () => {
  const [dynamicStyle, setDynamicStyle] = useState();
  const [usersData, setUsersData] = useState([]);
  const [type, setType] = useState("Jane");
  const [allData, setAllData] = useState({ jane: [], snus: [] });
  const [users, setUsers] = useState(() => {
    const savedUsers = localStorage.getItem("users");
    return savedUsers ? JSON.parse(savedUsers) : [];
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/getAllUsers");
        setUsers(response.data);
        localStorage.setItem("users", JSON.stringify(response.data));
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    setDynamicStyle(
      window.innerWidth < 768
        ? {
            height: "fit-content",
            width: "90%",
            padding: "2rem",
            backgroundColor: "#1e1e1e",
            borderRadius: "2rem",
          }
        : {
            height: "fit-content",
            width: "40%",
            margin: "0rem 3rem",
            padding: "2rem",
            backgroundColor: "#1e1e1e",
            borderRadius: "2rem",
          }
    );
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [janeResponse, snusResponse] = await Promise.all([
          axios.get("/stats/leaderboard/getLifetime"),
          axios.get("/snus/leaderboard/getLifetime"),
        ]);

        const formatDataInit = (data) =>
          Object.entries(data).map(([user, amount]) => ({
            name: user,
            description: "",
            avatar: localStorage.getItem(user + "_profilePic"),
            amount,
          }));

        setAllData({
          jane: formatDataInit(janeResponse.data),
          snus: formatDataInit(snusResponse.data),
        });
        setUsersData(formatDataInit(janeResponse.data));
        const userDetails = await axios.get("/getAllFriends");
        const friendsData = userDetails.data;

        const formatData = (data) =>
          Object.entries(data).map(([user, amount]) => ({
            name: user,
            description: "",
            avatar: localStorage.getItem(user + "_profilePic"),
            amount,
            ...(friendsData.find((u) => u.name === user) || {}),
          }));

        setAllData({
          jane: formatData(janeResponse.data),
          snus: formatData(snusResponse.data),
        });
        setUsersData(formatData(janeResponse.data)); // Initialize with Jane data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAllData();
  }, []);

  const avatarPresent = (avatar) =>
    avatar ? `data:image/jpeg;base64,${avatar}` : undefined;

  const topThree = (index) => {
    if (index < 3) {
      const colors = ["gold", "silver", "#cd7f32"]; // Gold, Silver, Bronze
      return colors[index];
    }
    return "black";
  };

  const toggleType = () => {
    setType((prev) => {
      const newType = prev === "Jane" ? "Snus" : "Jane";
      setUsersData(allData[newType.toLowerCase()]); // Switch data without fetching
      return newType;
    });
  };

  function redirectToUserPage(user) {
    if (users.includes(user)) {
      window.location.href = `users?user=${user}`;
    } else {
      toast.error("User does not have an SM Stats account", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "dark",
      });
    }
  }

  return (
    <div style={dynamicStyle}>
      <h1 style={{ textAlign: "center", margin: "1rem 0" }}>
        {type} leaderboard
      </h1>
      <Table aria-label="Leaderboard table with dynamic content">
        <TableHeader>
          <TableColumn align="center">User</TableColumn>
          <TableColumn align="center">Amount</TableColumn>
        </TableHeader>
        <TableBody emptyContent={"Loading..."}>
          {usersData.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <User
                    // The key prop here is not necessary as mentioned, but if you're mapping over an array at a higher level, ensure you keep a key prop there.
                    name={`${index + 1}. ${item.name}`} // Display the index followed by the name
                    description={item.description}
                    avatarProps={{ src: avatarPresent(item.avatar) }} // Ensure avatarPresent is a function that returns a valid src or handles undefined/null values properly.
                    onClick={() => redirectToUserPage(item.name)}
                    style={{
                      color: users.includes(item.name)
                        ? "white"
                        : "rgb(163, 163, 163)",
                    }}
                  />
                  {index < 3 && ( // Display for the first three items or the last item
                    <FontAwesomeIcon icon={faCrown} color={topThree(index)} />
                  )}
                  {index === usersData.length - 1 && (
                    <FontAwesomeIcon icon={faL} color="red" />
                  )}
                </div>
              </TableCell>
              <TableCell align="">{item.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
        style={{ width: "100%", margin: "2rem 0 0 0 " }}
        onClick={() => toggleType()}
      >
        Toggle leaderboard
      </Button>
    </div>
  );
};

export default BarChart;
