import {
  faPieChart,
  faChartSimple,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const iconMapping = {
  pieChart: faPieChart,
  chartSimple: faChartSimple,
  trophy: faTrophy,
};

const BarChart = ({ data, type, icon }) => {
  const userKeys = Object.keys(data);
  const user1Key = userKeys[0];
  const user2Key = userKeys[1];

  // Access the type values for each user
  const user1Value = data[user1Key][type];
  const user2Value = data[user2Key][type];

  // Calculate the maximum value for relative bar widths
  const maxValue = Math.max(user1Value, user2Value);

  // Calculate width percentages for the bar widths
  const user1Width = maxValue === 0 ? 0 : (user1Value / maxValue) * 100;
  const user2Width = maxValue === 0 ? 0 : (user2Value / maxValue) * 100;

  const dynamicIcon = iconMapping[icon];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "90%",
        margin: "0.3rem 0",
        height: "3rem",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "2.5rem",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "0.2rem",
        }}
      >
        {user1Width <= 30 && (
          <p
            style={{
              fontSize: "1.2rem",
              margin: "0.5rem",
              fontWeight: "bold",
            }}
          >
            {user1Value}
          </p>
        )}
        <div
          style={{
            height: "100%",
            width: `${user1Width}%`,
            backgroundColor: "blue",
            borderRadius: "1rem 0 0 1rem",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {user1Width > 30 && (
            <p
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                margin: "1rem",
              }}
            >
              {user1Value}
            </p>
          )}
        </div>
      </div>
      <div
        style={{
          backgroundColor: "black",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0.5rem",
        }}
      >
        <FontAwesomeIcon
          icon={dynamicIcon}
          style={{ height: "2.5rem", width: "2.5rem" }}
          color="#6000e5"
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "2.5rem",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "0.2rem",
        }}
      >
        <div
          style={{
            height: "100%",
            width: `${user2Width}%`,
            backgroundColor: "blue",
            borderRadius: "0 1rem 1rem 0",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {user2Width > 30 && (
            <p
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                margin: "1rem",
              }}
            >
              {user2Value}
            </p>
          )}
        </div>{" "}
        {user2Width <= 30 && (
          <p
            style={{
              fontSize: "1.2rem",
              margin: "0.5rem",
              fontWeight: "bold",
            }}
          >
            {user2Value}
          </p>
        )}
      </div>
    </div>
  );
};

export default BarChart;
