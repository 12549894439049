import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import axios from "../../axiosInstance";

const PieChart = ({ username = "" }) => {
  const [dynamicStyle, setDynamicStyle] = useState();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Amount",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  });
  const [snusMap, setSnusMap] = useState({});
  const [isSnusMapLoaded, setIsSnusMapLoaded] = useState(false); // New state to track if snusMap is ready

  useEffect(() => {
    const setInitialStyle = () => {
      const style =
        window.innerWidth < 768
          ? {
              height: "fit-content",
              width: "90%",
              padding: "2rem",
              backgroundColor: "#1e1e1e",
              borderRadius: "2rem",
            }
          : {
              height: "fit-content",
              width: "50%",
              margin: "0rem 3rem",
              padding: "2rem",
              backgroundColor: "#1e1e1e",
              borderRadius: "2rem",
            };
      setDynamicStyle(style);
    };

    setInitialStyle();
  }, []);

  useEffect(() => {
    const fetchSnus = async () => {
      try {
        const response = await axios.get("/snus");
        const snusList = response.data;
        const map = {};
        snusList.forEach((snus) => {
          map[snus.id] = `${snus.brand} - ${snus.flavor}`;
        });
        setSnusMap(map);
        setIsSnusMapLoaded(true); // Update the flag once snusMap is populated
      } catch (error) {
        console.error("Error fetching snus data:", error);
      }
    };

    fetchSnus();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!isSnusMapLoaded) return; // Check if snusMap is ready before fetching flavor breakdown

      try {
        const endpoint = username
          ? `/snus/flavorBreakdown?username=${username}`
          : "/snus/flavorBreakdown";
        const response = await axios.get(endpoint);
        const data = response.data;

        const flavorCounts = {};
        Object.keys(data).forEach((id) => {
          const flavor = snusMap[id] || "Unknown";
          flavorCounts[flavor] = (flavorCounts[flavor] || 0) + data[id];
        });

        const flavors = Object.keys(flavorCounts);
        const counts = flavors.map((flavor) => flavorCounts[flavor]);
        const backgroundColors = flavors.map(
          (_, index) => `hsla(${(index * 360) / flavors.length}, 70%, 70%, 0.5)`
        );
        const borderColors = flavors.map(
          (_, index) => `hsla(${(index * 360) / flavors.length}, 70%, 70%, 1)`
        );

        setChartData({
          labels: flavors,
          datasets: [
            {
              label: "Amount",
              data: counts,
              backgroundColor: backgroundColors,
              borderColor: borderColors,
              borderWidth: 1,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [isSnusMapLoaded]);

  const options = {
    responsive: true,
    maintainAspectRatio: true, // This helps maintain the aspect ratio within the given div dimensions
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div style={dynamicStyle}>
      <h1 style={{ textAlign: "center" }}>Flavor Breakdown</h1>
      <Pie
        data={chartData}
        options={options}
        style={{ width: "100%", padding: "1rem" }}
      />
    </div>
  );
};

export default PieChart;
