import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import axios from "../../axiosInstance";
import {
  Navbar,
  NavbarBrand,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  NavbarContent,
  NavbarItem,
  Link,
  Avatar,
  Modal,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Badge,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faBarChart,
  faTrophy,
  faRightFromBracket,
  faGear,
  faUserGear,
  faCircleQuestion,
  faHome,
  faHouse,
  faCamera,
  faUserGroup,
  faMapLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircleDot,
  faBell,
  faImage,
  faClock,
} from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import RequireAdmin from "../Admin/RequireAdmin";
import NotificationPage from "../Notification/NotificationPage";
import UserDropdown from "../User/UserDropdown"; // Custom hook for detecting screen width
function useMobileView() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
}

export default function SMNavbar(active) {
  const [profilePic, setProfilePic] = useState("");
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [notificationsCount, setNotificationsCount] = useState(0); // State to handle notifications count
  const location = useLocation(); // Get location object

  useEffect(() => {
    const fetchProfilePic = async () => {
      try {
        const response = await axios.get("/getProfilePic");
        if (response.data) {
          setProfilePic("data:image/jpeg;base64," + response.data); // Assuming the API returns the base64 string directly
        } else {
          setProfilePic("images/EmptyProfilePic.png");
        }
      } catch (error) {
        console.error("Failed to fetch profile picture:", error);
        setProfilePic("images/EmptyProfilePic.png");
      }
    };

    const fetchNotifications = async () => {
      try {
        const response = await axios.get("/notification/unread"); // Fetch unread notifications
        const notifications = response.data; // Assuming the response data is the array of notifications
        const count = notifications.length; // Count the number of notifications
        setNotificationsCount(count); // Set the notification count state
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
        setNotificationsCount(0); // Set count to 0 in case of error
      }
    };
    fetchNotifications();
    fetchProfilePic();
  }, []);

  function resetNotis() {
    setNotificationsCount(0);
  }

  useEffect(() => {
    const handleResize = () => {
      setInnerHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = useMobileView();

  function isItemActiveColour(item) {
    if (active && active.active === item) {
      return ""; // Item is active
    } else {
      return "foreground"; // Item is not active
    }
  }

  function logOut() {
    document.cookie = `accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    toast.success("Successfully logged out!", {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "dark",
    });
    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  }

  function isItemActiveColourDropdown(item) {
    if (active && active.active === item) {
      return "foreground"; // Item is active
    } else {
      return "#ffffff"; // Item is not active
    }
  }

  function isItemActive(item) {
    if (active && active.active === item) {
      return true; // Item is active
    } else {
      return false; // Item is not active
    }
  }

  return (
    <Navbar
      style={{
        width: "100%",
        position: "fixed",
        height: innerHeight > 1200 ? "7rem" : "auto",
        backgroundColor: "#000000",
      }}
    >
      <NavbarBrand>
        <a
          href="/"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="images/Logo (4).png"
            alt="logo"
            style={{
              height: innerHeight > 1200 ? "5.5rem" : "3rem",
              marginRight: "1rem",
            }}
          />
        </a>
      </NavbarBrand>
      {!isMobile ? (
        <React.Fragment>
          <NavbarContent
            className="sm:flex"
            justify="end"
            style={{ width: "100%" }}
          >
            <NavbarItem className="navbar-item" isActive={isItemActive("home")}>
              <Link
                color={isItemActiveColour("home")}
                style={{ fontSize: innerHeight > 1200 ? "1.5rem" : "auto" }}
                href="/"
              >
                <FontAwesomeIcon
                  icon={faHome}
                  style={{ margin: "0rem 0.5rem" }}
                />
                Home
              </Link>
            </NavbarItem>
            {/* Explicitly defining each NavbarItem */}
            <NavbarItem
              className="navbar-item"
              isActive={isItemActive("Stats")}
            >
              <Link
                color={isItemActiveColour("Stats")}
                style={{ fontSize: innerHeight > 1200 ? "1.5rem" : "auto" }}
                href="/stats"
              >
                <FontAwesomeIcon
                  icon={faBarChart}
                  style={{ margin: "0rem 0.5rem" }}
                />
                Stats
              </Link>
            </NavbarItem>
            <NavbarItem
              className="navbar-item"
              isActive={isItemActive("Create entry")}
            >
              <Link
                color={isItemActiveColour("Create entry")}
                href="/createEntry"
                style={{ fontSize: innerHeight > 1200 ? "1.5rem" : "auto" }}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{ margin: "0rem 0.5rem" }}
                />{" "}
                Create entry
              </Link>
            </NavbarItem>
            <NavbarItem
              className="navbar-item"
              isActive={isItemActive("Photo lib")}
            >
              <Link
                color={isItemActiveColour("Photo lib")}
                href="/photoLibrary"
                style={{ fontSize: innerHeight > 1200 ? "1.5rem" : "auto" }}
              >
                <FontAwesomeIcon
                  icon={faImage}
                  style={{ margin: "0rem 0.5rem" }}
                />{" "}
                Photo Library
              </Link>
            </NavbarItem>
            <NavbarItem
              className="navbar-item"
              isActive={isItemActive("Create post")}
            >
              <Link
                color={isItemActiveColour("Create post")}
                style={{ fontSize: innerHeight > 1200 ? "1.5rem" : "auto" }}
                href="/createPost"
              >
                <FontAwesomeIcon
                  icon={faCamera}
                  style={{ margin: "0rem 0.5rem" }}
                />
                New Post
              </Link>
            </NavbarItem>
            <NavbarItem
              className="navbar-item"
              isActive={isItemActive("Leaderboard")}
            >
              <Link
                color={isItemActiveColour("Leaderboard")}
                href="/leaderboard"
                style={{ fontSize: innerHeight > 1200 ? "1.5rem" : "auto" }}
              >
                <FontAwesomeIcon
                  icon={faTrophy}
                  style={{ margin: "0rem 0.5rem" }}
                />
                Leaderboard
              </Link>
            </NavbarItem>
            <NavbarItem
              className="navbar-item"
              isActive={isItemActive("Live feed")}
            >
              <Link
                color={isItemActiveColour("Live feed")}
                style={{ fontSize: innerHeight > 1200 ? "1.5rem" : "auto" }}
                href="/liveFeed"
              >
                <FontAwesomeIcon
                  icon={faCircleDot}
                  style={{ margin: "0rem 0.5rem" }}
                />
                Live feed
              </Link>
            </NavbarItem>
            <NavbarItem
              className="navbar-item"
              isActive={isItemActive("settings")}
            >
              <Link
                color={isItemActiveColour("settings")}
                style={{ fontSize: innerHeight > 1200 ? "1.5rem" : "auto" }}
                href="/settings"
              >
                <FontAwesomeIcon
                  icon={faGear}
                  style={{ margin: "0rem 0.5rem" }}
                />
                Settings
              </Link>
            </NavbarItem>
            <RequireAdmin>
              <NavbarItem
                className="navbar-item"
                isActive={isItemActive("admin")}
              >
                <Link
                  color={isItemActiveColour("admin")}
                  style={{ fontSize: innerHeight > 1200 ? "1.5rem" : "auto" }}
                  href="/admin"
                >
                  <FontAwesomeIcon
                    icon={faUserGear}
                    style={{ margin: "0rem 0.5rem" }}
                  />
                  Admin
                </Link>
              </NavbarItem>
            </RequireAdmin>
            <NavbarItem className="navbar-item" isActive={isItemActive("help")}>
              <Link
                color={isItemActiveColour("help")}
                style={{ fontSize: innerHeight > 1200 ? "1.5rem" : "auto" }}
                href="/help"
              >
                <FontAwesomeIcon
                  icon={faCircleQuestion}
                  style={{ margin: "0rem 0.5rem" }}
                />
                Help
              </Link>
            </NavbarItem>
            <NavbarItem className="navbar-item">
              <Dropdown className="dark">
                <DropdownTrigger>
                  <Avatar
                    src={profilePic}
                    size={innerHeight > 1200 ? "lg" : "md"}
                    style={{ cursor: "pointer" }}
                  />
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownItem onClick={onOpen}>
                    {" "}
                    <Link color="danger" size="md" onClick={onOpen}>
                      <FontAwesomeIcon
                        icon={faRightFromBracket}
                        style={{ margin: "0rem 0.5rem" }}
                      />
                      Logout
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavbarItem>
          </NavbarContent>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Popover
            placement="bottom"
            style={{ margin: " 0" }}
            className="dark"
            backdrop="opaque"
            onOpenChange={resetNotis}
          >
            <Badge
              content={notificationsCount}
              isInvisible={notificationsCount === 0}
              shape="circle"
              color="danger"
              as="button"
            >
              <PopoverTrigger>
                <Button
                  radius="full"
                  isIconOnly
                  aria-label="more than 99 notifications"
                  variant="light"
                  as="button"
                >
                  <FontAwesomeIcon
                    icon={faBell}
                    style={{ margin: "0 0.5rem", height: "60%" }}
                  />
                </Button>
              </PopoverTrigger>
            </Badge>
            <PopoverContent>
              <NotificationPage />
            </PopoverContent>
          </Popover>
          <Popover
            placement="bottom"
            style={{ margin: " 0" }}
            className="dark"
            backdrop="opaque"
            onOpenChange={resetNotis}
          >
            <PopoverTrigger>
              <Button
                radius="full"
                isIconOnly
                aria-label="users"
                variant="light"
                as="button"
              >
                <FontAwesomeIcon
                  icon={faUserGroup}
                  style={{ margin: "0 0.5rem", height: "60%" }}
                />
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <UserDropdown />
            </PopoverContent>
          </Popover>
          <NavbarMenuToggle />
          <NavbarMenu
            style={{
              display: "flex",
              alignItems: "flex-end",
              gap: "1.5rem",
              paddingTop: "2rem",
              // maxHeight: "40vh",
              backgroundColor: "rgb(30 30 30 / 60%)",
            }}
          >
            {/* Explicitly defining each NavbarMenuItem for mobile view */}
            <NavbarMenuItem>
              <Link
                className="w-full"
                href="/"
                size="lg"
                style={{ color: isItemActiveColourDropdown("home") }}
              >
                <FontAwesomeIcon
                  icon={faHouse}
                  style={{ margin: "0rem 0.5rem" }}
                />
                Home
              </Link>
            </NavbarMenuItem>
            <NavbarMenuItem>
              <Link
                className="w-full"
                href="/stats"
                size="lg"
                style={{ color: isItemActiveColourDropdown("Stats") }}
              >
                <FontAwesomeIcon
                  icon={faBarChart}
                  style={{ margin: "0rem 0.5rem" }}
                />
                Stats
              </Link>
            </NavbarMenuItem>
            <NavbarMenuItem>
              <Link
                className="w-full"
                href="/createEntry"
                size="lg"
                style={{ color: isItemActiveColourDropdown("Create entry") }}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{ margin: "0rem 0.5rem" }}
                />{" "}
                Create entry
              </Link>
            </NavbarMenuItem>
            <NavbarMenuItem>
              <Link
                className="w-full"
                href="/snusOclock"
                size="lg"
                style={{ color: isItemActiveColourDropdown("Snus time") }}
              >
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ margin: "0rem 0.5rem" }}
                />{" "}
                Snus O'Clock
              </Link>
            </NavbarMenuItem>
            <NavbarMenuItem>
              <Link
                className="w-full"
                href="/map"
                size="lg"
                style={{ color: isItemActiveColourDropdown("Map") }}
              >
                <FontAwesomeIcon
                  icon={faMapLocationDot}
                  style={{ margin: "0rem 0.5rem" }}
                />{" "}
                Map
              </Link>
            </NavbarMenuItem>
            <NavbarMenuItem>
              <Link
                className="w-full"
                href="/photoLibrary"
                size="lg"
                style={{ color: isItemActiveColourDropdown("Photo lib") }}
              >
                <FontAwesomeIcon
                  icon={faImage}
                  style={{ margin: "0rem 0.5rem" }}
                />{" "}
                Photo Library
              </Link>
            </NavbarMenuItem>
            <NavbarMenuItem>
              <Link
                className="w-full"
                href="/createPost"
                size="lg"
                style={{ color: isItemActiveColourDropdown("Create post") }}
              >
                <FontAwesomeIcon
                  icon={faCamera}
                  style={{ margin: "0rem 0.5rem" }}
                />{" "}
                New post
              </Link>
            </NavbarMenuItem>
            <NavbarMenuItem>
              <Link
                className="w-full"
                href="/leaderboard"
                size="lg"
                style={{ color: isItemActiveColourDropdown("Leaderboard") }}
              >
                <FontAwesomeIcon
                  icon={faTrophy}
                  style={{ margin: "0rem 0.5rem" }}
                />
                Leaderboard
              </Link>
            </NavbarMenuItem>
            <NavbarMenuItem>
              <Link
                style={{ color: isItemActiveColourDropdown("Live feed") }}
                href="/liveFeed"
                size="lg"
              >
                <FontAwesomeIcon
                  icon={faCircleDot}
                  style={{ margin: "0rem 0.5rem" }}
                />
                Live feed
              </Link>
            </NavbarMenuItem>
            <NavbarMenuItem>
              <Link
                style={{ color: isItemActiveColourDropdown("settings") }}
                href="/settings"
                size="lg"
              >
                <FontAwesomeIcon
                  icon={faGear}
                  style={{ margin: "0rem 0.5rem" }}
                />
                Settings
              </Link>
            </NavbarMenuItem>
            <RequireAdmin>
              <NavbarMenuItem>
                <Link
                  style={{ color: isItemActiveColourDropdown("admin") }}
                  href="/admin"
                  size="lg"
                >
                  <FontAwesomeIcon
                    icon={faUserGear}
                    style={{ margin: "0rem 0.5rem" }}
                  />
                  Admin
                </Link>
              </NavbarMenuItem>
            </RequireAdmin>
            <NavbarMenuItem>
              <Link
                style={{ color: isItemActiveColourDropdown("help") }}
                href="/help"
                size="lg"
              >
                <FontAwesomeIcon
                  icon={faCircleQuestion}
                  style={{ margin: "0rem 0.5rem" }}
                />
                Help
              </Link>
            </NavbarMenuItem>
            <NavbarMenuItem>
              <Link color="danger" size="lg" onClick={onOpen}>
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  style={{ margin: "0rem 0.5rem" }}
                />
                Logout
              </Link>
            </NavbarMenuItem>
          </NavbarMenu>
        </React.Fragment>
      )}
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="lg"
        backdrop="blur"
        placement="center"
        hideCloseButton={true}
        style={{ width: "90%", backgroundColor: "#1e1e1e" }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                    padding: "2rem 0",
                  }}
                >
                  <p style={{ color: "white" }}>
                    Are you sure you want to log out?
                  </p>
                  <div>
                    <Button
                      color="primary"
                      onPress={onClose}
                      variant="bordered"
                      style={{ margin: "0 1rem" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="danger"
                      onPress={() => logOut()}
                      variant="bordered"
                      style={{ margin: "0 1rem" }}
                    >
                      Logout
                    </Button>
                  </div>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </Navbar>
  );
}
