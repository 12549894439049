import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2"; // Import Pie instead of Bar
import "chart.js/auto";
import axios from "../../axiosInstance";

const PieChart = ({ username = "" }) => {
  const [dynamicStyle, setDynamicStyle] = useState();
  const [chartData, setChartData] = useState({
    labels: ["Mixed", "Not Mixed"],
    datasets: [
      {
        label: "Type",
        data: [], // Will be populated from API
        backgroundColor: ["rgba(255, 142, 0, 0.5)", "rgba(96,110,234, 0.5)"],
        borderColor: ["rgba(255, 142, 0, 1)", "rgba(96,110,234, 1)"],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const setInitialStyle = () => {
      const style =
        window.innerWidth < 768
          ? {
              height: "fit-content",
              width: "90%",
              padding: "2rem",
              backgroundColor: "#1e1e1e",
              borderRadius: "2rem",
            }
          : {
              height: "fit-content",
              width: "50%",
              margin: "0rem 3rem",
              padding: "2rem",
              backgroundColor: "#1e1e1e",
              borderRadius: "2rem",
            };
      setDynamicStyle(style);
    };

    setInitialStyle();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        var response = "";
        if (username !== "") {
          response = await axios.get(
            "/stats/getLifetimeMixedAll?username=" + username
          );
        } else {
          response = await axios.get("/stats/getLifetimeMixedAll");
        }
        const data = response.data; // { "mixed": 15, "notMixed": 10 }

        // Update chart data using the API response
        setChartData((prevChartData) => ({
          ...prevChartData,
          datasets: prevChartData.datasets.map((dataset) => ({
            ...dataset,
            data: [data.mixed, data.notMixed], // Update data array with mixed and notMixed values
          })),
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Options can remain mostly the same, though you might not need scales for a pie chart
  const options = {
    plugins: {
      legend: {
        display: true, // Ensures the legend is visible
        position: "bottom", // Optionally, you can define the position of the legend
      },
    },
  };

  return (
    <div style={dynamicStyle}>
      <h1 style={{ textAlign: "center" }}>Lifetime Mixed vs Not Mixed</h1>
      <Pie
        data={chartData}
        options={options}
        style={{ width: "100%", padding: "1rem" }}
      />
    </div>
  );
};

export default PieChart;
