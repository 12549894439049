import React, { useState, useMemo } from "react";
import { Button, Spacer, Input } from "@nextui-org/react";
import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import VideoPlayer from "../VideoJS/VideoPlayer";
import axios from "../../axiosInstance";

function CreatePost() {
  const maxWidth = useState(window.innerWidth > 768 ? "70dvw" : "80dvw");
  const [media, setMedia] = useState(null); // State for storing selected media
  const [caption, setCaption] = useState("");
  const [inputValid, setInputValid] = useState(true);

  const videoOptions = useMemo(
    () => ({
      autoplay: true,
      controls: false, // as per your options to disable controls
      responsive: true,
      fluid: true,
      loop: true,
      sources: [
        {
          src: media?.url, // Use optional chaining to safely access url
          type: media?.type, // Use optional chaining to safely access type
        },
      ],
    }),
    [media?.url, media?.type]
  );

  const handleInputChange = (e) => {
    const newCaption = e.target.value;
    if (newCaption.length === 101) {
      setInputValid(false);
      setCaption(newCaption);
    } else if (newCaption.length <= 100) {
      setInputValid(true);
      setCaption(newCaption);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("media", media?.binary);
    formData.append(
      "post",
      JSON.stringify({
        caption: caption,
        mediaType: media?.type.split("/")[0].toUpperCase(),
      })
    );

    const loadingToast = toast.loading("Uploading post...", {
      position: "top-center",
      theme: "dark",
    });

    try {
      const response = await axios.post("/post", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.dismiss(loadingToast);
      console.log("Submission successful", response.data);
      setTimeout(() => {
        toast.success("Successfully uploaded post", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }, 500);
    } catch (error) {
      toast.dismiss(loadingToast);
      console.error("Failed to upload post:", error);

      // Extracting error message from the response
      const errorMessage =
        error.response && error.response.data
          ? error.response.data
          : "Unknown error occurred";

      setTimeout(() => {
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }, 500);
    }
  };

  const handleMediaChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMedia({
        name: file.name,
        type: file.type,
        url: URL.createObjectURL(file),
        binary: file,
      });
    }
  };

  const resetMedia = () => {
    setMedia(null);
    URL.revokeObjectURL(media.url); // Cleanup URL object
    setTimeout(() => {
      document.getElementById("mediaInput").value = "";
    }, 100);
  };

  const handleDeleteMedia = (e) => {
    e.preventDefault(); // Prevent form submission
    e.stopPropagation(); // Stop event propagation
    resetMedia();
  };

  return (
    <div>
      <Navbar active={"Create post"} />
      <div
        style={{
          maxWidth: maxWidth,
          margin: "0 auto",
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            placeContent: "center",
            marginBottom: "4rem",
          }}
        >
          <h1 style={{ fontSize: "2rem", textAlign: "center" }}>New Post</h1>
        </div>
        <form onSubmit={handleSubmit} style={{ width: "90dvw" }}>
          {media && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {media.type.startsWith("video") && (
                <VideoPlayer
                  options={videoOptions}
                  style={{ margin: 0 }}
                  onReady={(player) => {
                    console.log("Player is ready");
                  }}
                />
              )}
              {media.type.startsWith("image") && (
                <img
                  style={{ maxHeight: "30dvh", borderRadius: "1rem" }}
                  src={media.url}
                  alt="Preview"
                />
              )}
              <Spacer y={5} />
              <Button
                type="button"
                color="danger"
                style={{ width: "100%" }}
                size="lg"
                onClick={handleDeleteMedia}
              >
                <FontAwesomeIcon icon={faTrash} style={{ cursor: "pointer" }} />
                <p style={{ lineHeight: 0 }}>Delete media</p>
              </Button>
            </div>
          )}
          {!media && (
            <div>
              <Button
                auto
                flat
                style={{
                  width: "100%",
                  backgroundColor:
                    "hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)))",
                  padding: "1.5rem",
                }}
                onClick={() => document.getElementById("mediaInput").click()}
              >
                Add media
              </Button>
              <input
                type="file"
                id="mediaInput"
                style={{ display: "none", width: "100%" }}
                onChange={handleMediaChange}
                accept="image/*, video/mp4" // Accept images and videos
              />
            </div>
          )}
          <Spacer y={5} />
          <div style={{ width: "100%" }}>
            {!inputValid && (
              <div
                style={{
                  textAlign: "center",
                  color: "red",
                  marginBottom: "1rem",
                  fontSize: "0.9rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon icon={faCircleExclamation} color="red" />
                <p>Comment can be max 100 characters</p>
              </div>
            )}
            <Input
              className="dark"
              type="text"
              label="Add a caption"
              size="sm"
              radius="md"
              style={{ width: "100%" }}
              value={caption}
              onChange={handleInputChange}
              isInvalid={!inputValid}
            />
          </div>
          <Spacer y={5} />
          <Button
            type="submit"
            shadow
            size="lg"
            color="primary"
            style={{
              width: window.innerWidth < 768 ? "100%" : "auto",
            }}
          >
            Post
          </Button>
        </form>
      </div>
    </div>
  );
}

export default CreatePost;
