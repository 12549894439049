import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2"; // Changed from Bar to Line
import "chart.js/auto";
import axios from "../../axiosInstance";

const LineChart = ({ username = "" }) => {
  // Renamed for clarity
  const [dynamicStyle, setDynamicStyle] = useState();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Amount consumed",
        data: [],
        // For a line chart, you may only need borderColor
        backgroundColor: "rgba(156,41,234, 0.5)", // Consider using a single color or gradients
        borderColor: "rgba(156,41,234, 1)",
        borderWidth: 2, // Adjusted for better visibility
        fill: false, // Line charts typically don't use fill, but you can adjust as needed
      },
    ],
  });

  useEffect(() => {
    const setInitialStyle = () => {
      const style =
        window.innerWidth < 768
          ? {
              height: "fit-content",
              width: "90%",
              padding: "2rem",
              backgroundColor: "#1e1e1e",
              borderRadius: "2rem",
            }
          : {
              height: "fit-content",
              width: "50%",
              margin: "0rem 3rem",
              padding: "2rem",
              backgroundColor: "#1e1e1e",
              borderRadius: "2rem",
            };

      setDynamicStyle(style);
    };

    setInitialStyle();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "/stats/getMonthly" + (username !== "" ? `?username=${username}` : "")
        );
        const monthsData = response.data;
        const updatedLabels = Object.keys(monthsData).map((month) =>
          month.slice(0, 3)
        );
        const updatedData = Object.values(monthsData);

        setChartData({
          labels: updatedLabels,
          datasets: [
            {
              label: "Amount consumed",
              data: updatedData,
              backgroundColor: "rgba(156,41,234, 0.2)",
              borderColor: "rgba(156,41,234, 1)",
              borderWidth: 2,
              fill: true,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching monthly stats:", error);
      }
    };

    fetchData();
  }, []);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false, // Adjusted to display legend for line chart
      },
    },
    layout: {
      backgroundColor: "grey",
    },
  };

  return (
    <div style={dynamicStyle}>
      <h1 style={{ textAlign: "center" }}>Consumed in past months</h1>
      <Line data={chartData} options={options} style={{ width: "100%" }} />
    </div>
  );
};

export default LineChart;
