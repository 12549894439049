import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Chip,
  Avatar,
} from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import ImageView from "./ImageView";
import moment from "moment";

// Correcting the path for marker icons
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const MapView = ({ coordinates, profilePics }) => {
  return (
    <div style={{ display: "flex", alignItems: "flex-end", height: "100dvh" }}>
      <MapContainer
        center={[47.559599, 7.5888]}
        zoom={13}
        zoomControl={false}
        style={{
          height: "92dvh",
          width: "100%",
          zIndex: 1,
          backgroundColor: "black",
        }}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        />
        {coordinates.map((coord) => (
          <Marker key={coord.id} position={[coord.latitude, coord.longitude]}>
            <Popup style={{ backgroundColor: "black", padding: "0" }}>
              <Card style={{ width: "70dvw" }}>
                <CardHeader
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <p style={{ margin: "0.2rem", fontSize: "1.2rem" }}>
                    Entry info
                  </p>
                  <Divider orientation="horizontal" />
                </CardHeader>
                {coord.jayCons.image !== "" && (
                  <CardBody>
                    <ImageView id={coord.jayCons.image} />
                  </CardBody>
                )}
                <CardFooter>
                  <div className="dark" style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "flex-end",
                        justifyContent: "flex-start",
                        width: "100%",
                      }}
                    >
                      <div style={{ height: "100%", marginRight: "1rem" }}>
                        <p style={{ margin: "0.5rem 0" }}>Users:</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "flex-start",
                          width: "100%",
                          gap: "0.5rem",
                        }}
                      >
                        {coord.jayCons.users.map((user) => (
                          <Chip
                            size="sm"
                            key={user}
                            variant="solid"
                            color="primary"
                            className="dark"
                            avatar={
                              <Avatar
                                name={user}
                                src={
                                  profilePics[user]
                                    ? `${profilePics[user]}`
                                    : ""
                                }
                                fallback={<FontAwesomeIcon icon={faUser} />}
                              />
                            }
                          >
                            {user}
                          </Chip>
                        ))}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "flex-end",
                        justifyContent: "flex-start",
                        width: "100%",
                        marginTop: "0.5rem",
                      }}
                    >
                      <div style={{ height: "100%", marginRight: "1rem" }}>
                        <p style={{ margin: "0.5rem 0" }}>Info:</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "flex-start",
                          width: "100%",
                          gap: "0.5rem",
                        }}
                      >
                        <Chip
                          style={{
                            backgroundColor: coord.jayCons.hash
                              ? "#964B00"
                              : "darkgreen",
                          }}
                          size="sm"
                        >
                          {coord.jayCons.hash ? "Hash" : "Bud"}
                        </Chip>
                        <Chip color="secondary" size="sm">
                          {coord.jayCons.type === "JOINT"
                            ? coord.jayCons.size
                            : coord.jayCons.type}
                        </Chip>
                        <div
                          style={{
                            width: "100%",
                            fontFamily:
                              "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: "0.5rem",
                          }}
                        >
                          <Chip className="dark" size="sm">
                            <p
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              {moment(coord.jayCons.timeOfConsumption).format(
                                "HH:mm"
                              )}
                            </p>
                          </Chip>
                          <Chip className="dark" size="sm">
                            <p
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              {moment(coord.jayCons.timeOfConsumption).format(
                                "DD-MM-YYYY"
                              )}
                            </p>
                          </Chip>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default MapView;
