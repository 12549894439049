import React from "react";
import { useState, useEffect } from "react";
import { Divider, Spacer } from "@nextui-org/react";
import axios from "../../axiosInstance";
import TrophyView from "../TrophyView/TrophyView";
import TrophyPeriodView from "../TrophyView/TrophyPeriodView";

export default function Highlights() {
  const [dynamicStyle, setDynamicStyle] = useState();
  const [topTrophies, setTopTrophies] = useState({});
  const [periodTrophies, setPeriodTrophies] = useState({});
  const [snusTrophies, setSnusTrophies] = useState({});

  useEffect(() => {
    const setInitialStyle = () => {
      const style =
        window.innerWidth < 768
          ? {
              height: "fit-content",
              width: "90%",
              padding: "2rem",
              backgroundColor: "#1e1e1e",
              borderRadius: "2rem",
            }
          : {
              height: "fit-content",
              width: "40%",
              margin: "0rem 3rem",
              padding: "2rem",
              backgroundColor: "#1e1e1e",
              borderRadius: "2rem",
            };

      setDynamicStyle(style);
    };

    const fetchTopTrophies = async () => {
      try {
        const response = await axios.get("/getTopTrophies");
        setTopTrophies(response.data);
      } catch (error) {
        console.error("Error fetching trophies:", error);
      }
    };

    const fetchPeriodTrohpies = async () => {
      try {
        const response = await axios.get("/getPeriodTrophies");
        setPeriodTrophies(response.data);
      } catch (error) {
        console.error("Error fetching trophies:", error);
      }
    };

    const fetchSnusTrophies = async () => {
      try {
        const response = await axios.get("/snus/getTopTrophies");
        setSnusTrophies(response.data);
      } catch (error) {
        console.error("Error fetching trophies:", error);
      }
    };

    fetchPeriodTrohpies();
    setInitialStyle();
    fetchTopTrophies();
    fetchSnusTrophies();

    /*
    const fetchData = async () => {
      try {
        const response = await axios.get("/getTopFriend");
        setTopFriend(response.data);
      } catch (error) {
        console.error("Error fetching top friends:", error);
      }
    };

    fetchData(); */
  }, []);

  const avatarPresent = (avatar) => {
    if (avatar !== "" && avatar !== null && avatar !== undefined) {
      return "data:image/jpeg;base64," + avatar;
    } else {
      return;
    }
  };

  return (
    <div style={dynamicStyle}>
      <div>
        <h1 style={{ fontSize: "1.5rem" }}>Earned</h1>
        <Spacer y={1} />
        <Divider />
        <Spacer y={5} />
        <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
          {Object.entries(topTrophies).map(([category, trophyDetails]) => (
            <TrophyView
              key={trophyDetails.id}
              category={category}
              trophy={trophyDetails}
              type={"earned"}
            />
          ))}
        </div>
        <Spacer y={5} />
        <h1 style={{ fontSize: "1.5rem" }}>Range</h1>
        <Spacer y={1} />
        <Divider />
        <Spacer y={5} />
        <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
          {Object.entries(periodTrophies).map(([period, trophyDetails]) => (
            <TrophyPeriodView
              key={trophyDetails.id}
              period={period}
              trophy={trophyDetails}
            />
          ))}
        </div>
        <Spacer y={5} />
        <h1 style={{ fontSize: "1.5rem" }}>Snus</h1>
        <Spacer y={1} />
        <Divider />
        <Spacer y={5} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "0.993rem",
            flexWrap: "wrap",
          }}
        >
          {Object.entries(snusTrophies).map(([category, trophyDetails]) => (
            <TrophyView
              key={trophyDetails.id + category}
              category={category}
              trophy={trophyDetails}
              type={"snus"}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
