import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Spacer,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  ModalFooter,
} from "@nextui-org/react";
import axios from "../../axiosInstance";

const TrophyView = ({ trophy, period }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [trophies, setTrophies] = useState([]);

  const trophyStyle = {
    color: trophy.colour,
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const formatPeriod = (period, title) => {
    const [startDateStr] = period.split(" - ");
    const startDate = new Date(startDateStr);

    if (title === "Monthly") {
      return startDate.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
    } else if (title === "Weekly") {
      const startWeek = getWeekNumber(startDate);
      const year = startDate.getFullYear();
      // If the week spans two different weeks (uncommon but possible around new year)
      return `KW${startWeek} - ${year}`;
    }
    return period; // Default return in unexpected cases
  };

  const getWeekNumber = (date) => {
    const d = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNo;
  };

  useEffect(() => {
    if (isOpen) {
      axios
        .get(`/getPeriodTrophiesByUser?group=${trophy.group}`)
        .then((response) => {
          // Convert object into an array, sort it, then convert it back into an object if necessary
          const sortedTrophies = Object.entries(response.data)
            .sort((a, b) => {
              const dateA = new Date(a[0].split(" - ")[0]); // Gets the start date of period A
              const dateB = new Date(b[0].split(" - ")[0]); // Gets the start date of period B
              return dateB - dateA; // Sorts in descending order by date
            })
            .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}); // Rebuild object from sorted array
          setTrophies(sortedTrophies);
        })
        .catch((error) => console.error("Error fetching trophies:", error));
    }
  }, [isOpen, trophy.group]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "rgb(45 45 45)",
          borderRadius: "1rem",
          padding: "0.5rem",
        }}
      >
        <h2 style={{ fontSize: "1rem" }}>
          {capitalizeFirstLetter(trophy.title)}
        </h2>
        <Spacer y={3} />
        <FontAwesomeIcon icon={faTrophy} size="3x" style={trophyStyle} />
        <Spacer y={2} />
        <span
          style={{
            fontSize: "1rem",
            color: "white",
            textAlign: "center",
            lineHeight: "1",
          }}
        >
          {formatPeriod(period, trophy.title)}
        </span>
        <Spacer y={3} />
        <Button size="sm" onPress={onOpen}>
          More
        </Button>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="lg"
        backdrop="blur"
        placement="center"
        hideCloseButton={true}
        style={{ width: "80%", backgroundColor: "#1e1e1e" }}
        scrollBehavior="inside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader style={{ color: "white" }}>
                All {capitalizeFirstLetter(trophy.title)} Trophies
              </ModalHeader>
              <ModalBody style={{ color: "white", maxHeight: "60dvh" }}>
                {Object.entries(trophies).map(([key, trophy]) => (
                  <div
                    key={key} // Use trophy.id to ensure unique keys
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "rgb(45 45 45)",
                      borderRadius: "1rem",
                      padding: "0.5rem 2rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTrophy}
                        size="3x"
                        style={{ color: trophy.colour }}
                      />
                      <Spacer y={2} />
                      <span
                        style={{
                          fontSize: "1rem",
                          textAlign: "center",
                          lineHeight: "1",
                        }}
                      >
                        {formatPeriod(key, trophy.title)}
                      </span>
                    </div>
                    <Spacer x={5} />
                    <div>
                      <p style={{ textAlign: "center" }}>
                        Entries: <br />
                      </p>
                      <p style={{ textAlign: "center" }}>{key.split("|")[1]}</p>
                    </div>
                  </div>
                ))}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onPress={onClose}
                  variant="bordered"
                  style={{ margin: "0 1rem" }}
                >
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default TrophyView;
