import React, { useEffect, useRef, useState } from "react";

const Confetti = () => {
  const canvasRef = useRef(null);
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    let W = window.innerWidth;
    let H = window.innerHeight;
    const canvas = canvasRef.current;
    canvas.width = W;
    canvas.height = H;
    const context = canvas.getContext("2d");

    const particles = [];
    const maxConfettis = 150;
    const possibleColors = [
      "DodgerBlue",
      "OliveDrab",
      "Gold",
      "Pink",
      "SlateBlue",
      "LightBlue",
      "Gold",
      "Violet",
      "PaleGreen",
      "SteelBlue",
      "SandyBrown",
      "Chocolate",
      "Crimson",
    ];

    function randomFromTo(from, to) {
      return Math.floor(Math.random() * (to - from + 1) + from);
    }

    function confettiParticle() {
      this.x = Math.random() * W;
      this.y = -Math.random() * H;
      this.r = randomFromTo(11, 33);
      this.d = Math.random() * maxConfettis + 11;
      this.color =
        possibleColors[Math.floor(Math.random() * possibleColors.length)];
      this.tilt = Math.floor(Math.random() * 33) - 11;
      this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
      this.tiltAngle = 0;

      this.draw = function () {
        context.beginPath();
        context.lineWidth = this.r / 2;
        context.strokeStyle = this.color;
        context.moveTo(this.x + this.tilt + this.r / 3, this.y);
        context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
        context.stroke();
      };
    }

    for (let i = 0; i < maxConfettis; i++) {
      particles.push(new confettiParticle());
    }

    const draw = () => {
      requestAnimationFrame(draw);
      context.clearRect(0, 0, W, H);

      for (let i = 0; i < maxConfettis; i++) {
        particles[i].tiltAngle += particles[i].tiltAngleIncremental;
        particles[i].y +=
          (Math.cos(particles[i].d) + 3 + particles[i].r / 2) / 2;
        particles[i].tilt = Math.sin(particles[i].tiltAngle - i / 3) * 15;

        if (particles[i].y <= H) {
          particles[i].draw();
        }

        if (
          particles[i].x > W + 30 ||
          particles[i].x < -30 ||
          particles[i].y > H
        ) {
          particles[i].x = Math.random() * W;
          particles[i].y = -30;
          particles[i].tilt = Math.floor(Math.random() * 10) - 20;
        }
      }
    };

    draw();

    window.addEventListener("resize", function () {
      W = window.innerWidth;
      H = window.innerHeight;
      canvas.width = W;
      canvas.height = H;
    });

    setTimeout(() => setOpacity(0), 5000); // Start fading out after 5 seconds

    return () => {
      window.removeEventListener("resize", function () {
        W = window.innerWidth;
        H = window.innerHeight;
        canvas.width = W;
        canvas.height = H;
      });
      context.clearRect(0, 0, W, H);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        pointerEvents: "none",
        opacity: opacity,
        transition: "opacity 3s linear", // Fades out over 3 seconds
        zIndex: 1000,
      }}
    ></canvas>
  );
};

export default Confetti;
