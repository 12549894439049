import React, { useState, useEffect } from "react";
import MapView from "./MapView";
import axios from "../../axiosInstance";
import Navbar from "../Navbar/Navbar";

const MapPage = () => {
  const [coordinates, setCoordinates] = useState([]);
  const [users, setUsers] = useState(() => {
    const savedUsers = localStorage.getItem("users");
    return savedUsers ? JSON.parse(savedUsers) : [];
  });
  const [profilePics, setProfilePics] = useState({}); // State to hold profile pictures

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/getAllUsers");
        setUsers(response.data);
        localStorage.setItem("users", JSON.stringify(response.data));
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchCoords = async () => {
      try {
        const response = await axios.get("/getAllCoordinates");
        setCoordinates(response.data);
      } catch (error) {
        console.error("Failed to fetch coords:", error);
      }
    };
    fetchCoords();
  }, []);

  useEffect(() => {
    const fetchProfilePics = async () => {
      const profilePicsUpdates = {};
      for (const user of users) {
        const storedPic = localStorage.getItem(user + "_profilePic");
        profilePicsUpdates[user] = storedPic
          ? "data:image/jpeg;base64," + storedPic
          : null;
      }

      setProfilePics(profilePicsUpdates);

      for (const user of users) {
        try {
          const response = await axios.get(`/getProfilePic?username=${user}`);
          if (
            response.data !== "" &&
            response.data !== localStorage.getItem(user + "_profilePic")
          ) {
            profilePicsUpdates[user] =
              "data:image/jpeg;base64," + response.data;
            localStorage.setItem(user + "_profilePic", response.data);
          }
        } catch (error) {
          console.error("Failed to fetch profile pic for", user, ":", error);
        }
      }

      setProfilePics(profilePicsUpdates);
    };

    if (users.length > 0) {
      fetchProfilePics();
    }
  }, [users]);

  return (
    <div>
      <Navbar active={"Map"} />
      <MapView coordinates={coordinates} profilePics={profilePics} />
    </div>
  );
};

export default MapPage;
