import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import axios from "../../axiosInstance";
import Post from "../Post/Post";
import { toast } from "react-toastify";
import Confetti from "../Confetti/Confetti";

export default function PostFeed() {
  const [posts, setPosts] = useState([]);
  const [profilePics, setProfilePics] = useState({}); // State to hold profile pictures
  const [showConfetti, setShowConfetti] = useState(false);
  const [users, setUsers] = useState(() => {
    const savedUsers = localStorage.getItem("users");
    return savedUsers ? JSON.parse(savedUsers) : [];
  });

  if (window.innerWidth > 768) {
    window.location.href = "/stats";
  }

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/getAllUsers");
        setUsers(response.data);
        localStorage.setItem("users", JSON.stringify(response.data));
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchUsers();
  }, [users.length]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        const notifResponse = await axios.get(`/checkForPendingNotifications`);
        setTimeout(() => {
          if (notifResponse.data.length !== 0) {
            const hasCelebration = notifResponse.data.some(
              (notif) => notif.celebrate
            );
            if (hasCelebration) {
              setShowConfetti(true);
              setTimeout(() => setShowConfetti(false), 8000);
            }
          }
          notifResponse.data.forEach((message) => {
            toast.info(message.message, {
              position: "top-center",
              autoClose: 7000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
            });
          });
        }, 500);
      };

      fetchData();
    } catch (notifError) {
      console.error("Error fetching notifications:", notifError);
    }
  }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get("/post");
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    fetchPosts();
  }, []);

  useEffect(() => {
    const fetchProfilePics = async () => {
      const profilePicsUpdates = {};
      for (const user of users) {
        const storedPic = localStorage.getItem(user + "_profilePic");
        profilePicsUpdates[user] = storedPic
          ? "data:image/jpeg;base64," + storedPic
          : null;
      }

      setProfilePics(profilePicsUpdates);

      for (const user of users) {
        try {
          const response = await axios.get(`/getProfilePic?username=${user}`);
          if (
            response.data !== "" &&
            response.data !== localStorage.getItem(user + "_profilePic")
          ) {
            profilePicsUpdates[user] =
              "data:image/jpeg;base64," + response.data;
            localStorage.setItem(user + "_profilePic", response.data);
          }
        } catch (error) {
          console.error("Failed to fetch profile pic for", user, ":", error);
        }
      }

      setProfilePics(profilePicsUpdates);
    };

    if (users.length > 0) {
      fetchProfilePics();
    }
  }, [users]);

  return (
    <div>
      {" "}
      {showConfetti && <Confetti />}
      <div
        style={{
          width: "100%",
          minHeight: "100dvh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "3rem 0 0 0",
          justifyContent: "flex-start",
          overflowX: "auto",
        }}
      >
        <Navbar active={"home"} />
        {posts.map((post) => (
          <Post key={post.id} post={post} profilePics={profilePics} />
        ))}
      </div>
    </div>
  );
}
