import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "../../axiosInstance";
import {
  Select,
  SelectItem,
  Switch,
  Button,
  Spacer,
  Input,
} from "@nextui-org/react";
import Navbar from "../Navbar/Navbar";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faPen,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import Confetti from "../Confetti/Confetti";

function JointForm() {
  const [isLoading, setIsLoading] = useState(true); // State to indicate loading
  const [sizes, setSizes] = useState([]);
  const [users, setUsers] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [selectedSize, setSelectedSize] = useState("");
  const [isMixed, setIsMixed] = useState(false);
  const [withRandoms, setWithRandoms] = useState(false);
  const [selectedType, setSelectedType] = useState(""); // New state for type
  const [types, setTypes] = useState([]); // New state for types
  const [image, setImage] = useState(null); // New state for image
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [timeOfConsumption, setTimeOfConsumption] = useState(() => {
    const current = new Date();
    const localISOTime = new Date(
      current.getTime() - current.getTimezoneOffset() * 60000
    ).toISOString();
    return localISOTime.substring(0, 16); // Format to YYYY-MM-DDTHH:mm
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [initalImage, setInitialImage] = useState(false);

  useEffect(() => {
    setSizes(["XSMALL", "SMALL", "MEDIUM", "LARGE", "XLARGE", "XXLARGE"]);

    setTypes(["joint", "blunt", "bong", "bowl", "edible"]);

    async function fetchData() {
      try {
        const usersResponse = axios.get("/getAllUsers");
        const currentResponse = axios.get("/stats/jayCons?id=" + id);

        const [usersData, currentData] = await Promise.all([
          usersResponse,
          currentResponse,
        ]);

        setUsers(usersData.data);
        const jayCons = currentData.data;
        setSelectedSize(jayCons.size);
        setWithRandoms(jayCons.withRandoms);
        setSelectedType(jayCons.type.toLowerCase());
        setSelectedUsers(new Set(jayCons.users));
        setTimeOfConsumption(jayCons.timeOfConsumption.slice(0, 16));
        setIsLoading(false); // Data loaded, set loading to false

        if (jayCons.image !== "") {
          const response = await axios.get(
            "/getImageBinary?id=" + jayCons.image
          );
          setImage(response.data);
          setInitialImage(true);
        } else {
          setImage(null);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setIsLoading(false); // Ensure loading is set to false even on error
      }
    }

    fetchData();
  }, [id]);

  const handleImageChange = (e) => {
    setInitialImage(false);
    setImage(e.target.files[0]); // Set the selected file
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (!initalImage) formData.append("image", image);
    else formData.append("initialImage", initalImage);
    const jayCons = {
      id: id,
      mixed: isMixed,
      users: Array.from(selectedUsers),
      timeOfConsumption: `${timeOfConsumption}:00`,
      type: selectedType.toUpperCase(),
      withRandoms: withRandoms,
    };

    // Add the 'size' property conditionally
    if (selectedType === "joint" || selectedType === "bong") {
      jayCons.size = selectedSize;
    }

    // Now, append the JSON stringified object to formData
    formData.append("jayCons", JSON.stringify(jayCons));

    const loadingToast = toast.loading("Submitting entry...", {
      position: "top-center",
      theme: "dark",
    });

    try {
      const response = await axios.put("/stats/updateEntry", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.dismiss(loadingToast);
      console.log("Update Successful", response.data);
      setTimeout(() => {
        checkForNewTrophies();
        toast.success("Successfully updated entry", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }, 500);
    } catch (error) {
      toast.dismiss(loadingToast);
      console.error("Failed to submit form:", error);

      // Extracting error message from the response
      const errorMessage =
        error.response && error.response.data
          ? error.response.data
          : "Unknown error occurred";

      setTimeout(() => {
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }, 500);
    }
  };

  const checkForNewTrophies = async () => {
    try {
      const response = await axios.get("/checkForNewTrophies", {
        params: {
          selectedUsers: Array.from(selectedUsers).join(","),
        },
      });
      const newTrophies = response.data;

      if (newTrophies.length > 0) {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 8000);
        newTrophies.forEach((trophy) => {
          const iconColor = {
            color: trophy.colour, // Make sure your API returns a valid color name or hex
          };

          toast.success(
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "white",
              }}
            >
              <span style={{ margin: "0 1rem" }}>
                {trophy.title} - {trophy.group}
              </span>
              <FontAwesomeIcon icon={faTrophy} style={iconColor} />
            </div>,
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              theme: "dark",
              style: { background: "#8031ff" },
            }
          );
        });
        setTimeout(() => {
          window.location.href = "/liveFeed";
        }, 8000);
      }
      setTimeout(() => {
        window.location.href = "/liveFeed";
      }, 1000);
    } catch (error) {
      console.error("Error fetching new trophies:", error);
      toast.error("Failed to fetch new trophies");
    }
  };

  // Initialize state to store maxWidth
  const [maxWidth, setMaxWidth] = useState(
    window.innerWidth > 768 ? "600px" : "300px"
  );

  // Effect to add window resize listener
  useEffect(() => {
    const handleResize = () => {
      // Update maxWidth based on window width
      setMaxWidth(window.innerWidth > 768 ? "600px" : "300px");
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array means this effect runs once on mount

  const resetImage = () => {
    setInitialImage(false);
    setImage(null); // Reset the image state to null
    setTimeout(() => {
      document.getElementById("imageInput").value = ""; // Reset the file input field
    }, 100);
  };

  return (
    <div>
      <Navbar active={"Create entry"} />
      {showConfetti && <Confetti />}
      <div
        style={{
          maxWidth: maxWidth,
          margin: "0 auto",
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {!isLoading && (
          <div>
            <h1
              style={{
                fontSize: "2rem",
                marginBottom: "2rem",
                textAlign: "center",
              }}
            >
              <FontAwesomeIcon icon={faPen} style={{ marginRight: "0.5rem" }} />
              Edit an entry
            </h1>

            <form onSubmit={handleSubmit}>
              <Spacer y={5} />
              <Select
                label="Select type"
                onChange={(e) => setSelectedType(e.target.value)} // Update the type state
                isRequired={true}
                defaultSelectedKeys={Array.of(selectedType)}
              >
                {types.map((type) => (
                  <SelectItem key={type} value={type}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </SelectItem>
                ))}
              </Select>
              <Spacer y={5} />
              {(selectedType === "blunt" || selectedType === "joint") && (
                <div>
                  <Select
                    label="Select size"
                    onChange={(e) => setSelectedSize(e.target.value)}
                    isRequired={true}
                    defaultSelectedKeys={
                      selectedSize !== null ? Array.of(selectedSize) : null
                    }
                  >
                    {sizes.map((size) => (
                      <SelectItem key={size} value={size}>
                        {size}
                      </SelectItem>
                    ))}
                  </Select>
                  <Spacer y={5} />
                </div>
              )}
              <div style={{ display: "flex", gap: "1rem" }}>
                <Switch
                  checked={isMixed}
                  onChange={(e) => setIsMixed(e.target.checked)}
                  label="Mixed"
                  defaultSelected={isMixed}
                  defaultChecked={isMixed}
                >
                  Mixed
                </Switch>
                <Switch
                  checked={withRandoms}
                  onChange={(e) => setWithRandoms(e.target.checked)}
                  label="WithRandoms"
                  defaultSelected={withRandoms}
                >
                  With Randoms
                </Switch>
              </div>{" "}
              <Spacer y={5} />
              <Select
                label="Select users"
                selectionMode="multiple"
                placeholder="Select users"
                selectedKeys={selectedUsers}
                onSelectionChange={setSelectedUsers}
                isRequired={true}
              >
                {users.map((user) => (
                  <SelectItem key={user} value={user}>
                    {user}
                  </SelectItem>
                ))}
              </Select>
              <Spacer y={5} />
              <Input
                label="Time of Consumption"
                type="datetime-local"
                underlined
                fullWidth
                value={timeOfConsumption}
                onChange={(e) => setTimeOfConsumption(e.target.value)}
                css={{
                  maxWidth: "100%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              />
              <Spacer y={5} />
              {image !== null && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Input
                    label="Selected Image"
                    value="uploadedImage.jpg"
                    isReadOnly={true}
                    endContent={
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        style={{ cursor: "pointer" }}
                        onClick={resetImage}
                      />
                    }
                  />
                </div>
              )}
              {image === null && ( // Show this div only if image is null
                <div>
                  <Button
                    auto
                    flat
                    style={{
                      width: "100%",
                      backgroundColor:
                        "hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)))",
                      padding: "1.5rem",
                    }}
                    onClick={() =>
                      document.getElementById("imageInput").click()
                    }
                  >
                    Add image
                  </Button>
                  <input
                    type="file"
                    id="imageInput"
                    style={{ display: "none", width: "100%" }}
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                </div>
              )}
              <Spacer y={5} />
              <Button
                type="submit"
                shadow
                color="primary"
                style={{
                  width: window.innerWidth < 768 ? "auto" : "auto",
                }}
              >
                Submit
              </Button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default JointForm;
