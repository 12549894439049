import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const VideoJS = React.memo((props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      videoElement.classList.add("video-js", "vjs-big-play-centered");
      videoElement.style.borderRadius = "1rem"; // Apply border radius directly to the video element
      videoElement.style.overflow = "hidden"; // Ensure the border effect is contained
      videoElement.style.minHeight = "30dvh";
      videoElement.style.maxHeight = "30dvh";

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");
        onReady && onReady(player);
      }));
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, onReady]);

  React.useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
      }
    };
  }, []);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js" />
    </div>
  );
});

export default VideoJS;
