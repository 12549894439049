import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import { useLocation } from "react-router-dom";
import {
  Avatar,
  Button,
  Spacer,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  User,
} from "@nextui-org/react";
import axios from "../../axiosInstance"; // Make sure this path matches your axios instance path
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faChartColumn,
  faRankingStar,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import LifetimeBySize from "../LifetimeBySize/LifetimeBySize";
import LifetimeByMixed from "../LifetimeMixed/LifetimeMixed";
import LifetimeByFriends from "../LifetimeByFriends/LifetimeByFriends";
import LifetimeStats from "../LifetimeStats/LifetimeStats";
import WeeklyStats from "../WeeklyStats/WeeklyStats";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

const ComingSoon = () => {
  const [isMobile, setIsMobile] = useState(true);
  const [profilePic, setProfilePic] = useState("");
  const [leaderboardPos, setLeaderboardPos] = useState("-");
  const [totalEntries, setTotalEntries] = useState("-");
  const [averageEntries, setAverageEntries] = useState("-");
  const [trophyBreakdown, setTrophyBreadown] = useState({
    earned: "-",
    weekly: "-",
    monthly: "-",
  });
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user = queryParams.get("user");
  const [users, setUsers] = useState(() => {
    const savedUsers = localStorage.getItem("users");
    return savedUsers ? JSON.parse(savedUsers) : [];
  });
  const [profilePics, setProfilePics] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/getAllUsers");
        setUsers(response.data);
        localStorage.setItem("users", JSON.stringify(response.data));
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    if (users.length === 0) {
      fetchUsers();
    }
  }, [users.length]);

  useEffect(() => {
    const fetchProfilePics = async () => {
      const profilePicsUpdates = {};
      for (const user of users) {
        const profilePic = localStorage.getItem(user + "_profilePic");
        if (profilePic) {
          profilePicsUpdates[user] = "data:image/jpeg;base64," + profilePic;
        } else {
          try {
            const response = await axios.get(`/getProfilePic?username=${user}`);
            if (response.data !== "") {
              profilePicsUpdates[user] =
                "data:image/jpeg;base64," + response.data;
              localStorage.setItem(user + "_profilePic", response.data);
            } else {
              profilePicsUpdates[user] = null;
            }
          } catch (error) {
            console.error("Failed to fetch profile pic for", user, ":", error);
          }
        }
      }
      setProfilePics(profilePicsUpdates);
    };

    if (users.length > 0) {
      fetchProfilePics();
    }
  }, [users]);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }

    const fetchData = async () => {
      const response = await axios.get("/getProfilePic?username=" + user);
      if (response.data !== "") {
        setProfilePic(`data:image/jpeg;base64,${response.data}`);
      }
    };

    const fetchLB = async () => {
      const response = await axios.get(
        "/stats/getLeaderboardPos?username=" + user
      );
      setLeaderboardPos(response.data);
    };

    const fetchTotal = async () => {
      const response = await axios.get(
        "/stats/getTotalEntries?username=" + user
      );
      setTotalEntries(response.data);
    };

    const fetchAverage = async () => {
      const response = await axios.get(
        "/stats/getAverageEntries?username=" + user
      );
      setAverageEntries(response.data);
    };

    const fetchTrophyBreakdown = async () => {
      const response = await axios.get(
        "/stats/getTrophyBreakdown?username=" + user
      );
      setTrophyBreadown(response.data);
    };

    fetchLB();
    fetchAverage();
    fetchTotal();
    fetchTrophyBreakdown();

    const profilePic = localStorage.getItem(user + "_profilePic");
    if (profilePic) {
      setProfilePic(`data:image/jpeg;base64,${profilePic}`);
    } else {
      fetchData();
    }
  }, [isMobile, user]);

  function leaderboardColour() {
    if (leaderboardPos === 1) {
      return "gold";
    } else if (leaderboardPos === 2) {
      return "silver";
    } else if (leaderboardPos === 3) {
      return "#CD7F32";
    } else {
      return "white";
    }
  }

  function addUserToSelected(newUser) {
    setSelectedUsers((prevSelectedUsers) => {
      // Check if the user is already selected
      if (prevSelectedUsers.includes(newUser)) {
        // Remove the user if they are already selected
        return prevSelectedUsers.filter((user) => user !== newUser);
      } else if (prevSelectedUsers.length < 2) {
        // Add the new user if there are less than 2 users selected
        return [...prevSelectedUsers, newUser];
      }
      // Return previous state if there are already 2 users and trying to add another one
      return prevSelectedUsers;
    });
  }

  function redirectToCompare() {
    if (selectedUsers.length === 2) {
      window.location.href = "/compare?users=" + selectedUsers;
    }
  }

  return (
    <div>
      <Button
        size="lg"
        color="primary"
        variant="solid"
        style={{
          position: "fixed",
          right: "15px",
          bottom: "20px",
          zIndex: 10,
          boxShadow:
            "0px 0px 10px hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-text-opacity)))",
        }}
        onClick={() => onOpen()}
      >
        Compare users
      </Button>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "5rem 0",
          justifyContent: "flex-start",
          overflowX: "auto",
          position: "relative",
        }}
      >
        <Navbar active="" style={{ zIndex: 10000 }} />
        <Avatar src={profilePic} style={{ width: "6rem", height: "6rem" }} />
        <h1 style={{ margin: "0.5rem 0", fontSize: "1.7rem" }}>{user}</h1>
        <Spacer y={5} />
        <div
          style={{
            backgroundColor: "#1e1e1e",
            borderRadius: "2rem",
            width: "90%",
            padding: "2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <p style={{ fontSize: "1.4rem" }}>{leaderboardPos}</p>
              <FontAwesomeIcon
                icon={faRankingStar}
                style={{
                  height: "4rem",
                  width: "4rem",
                  color: leaderboardColour(),
                }}
              />
              <p>LB</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <p style={{ fontSize: "1.4rem" }}>{totalEntries}</p>
              <FontAwesomeIcon
                icon={faChartColumn}
                style={{
                  height: "4rem",
                  width: "4rem",
                  color:
                    "hsl(var(--nextui-primary) / var(--nextui-primary-opacity, var(--tw-text-opacity)))",
                }}
              />
              <p>Total entries</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <p style={{ fontSize: "1.4rem" }}>{averageEntries}</p>
              <FontAwesomeIcon
                icon={faCalendarDays}
                style={{ height: "4rem", width: "4rem", color: "#adadad" }}
              />
              <p>Per day</p>
            </div>
          </div>
          <Spacer y={5} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <p style={{ fontSize: "1.4rem" }}>{trophyBreakdown.earned}</p>
              <FontAwesomeIcon
                icon={faTrophy}
                style={{
                  height: "4rem",
                  width: "4rem",
                  color: "#CD7F32",
                }}
              />
              <p>Earned</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <p style={{ fontSize: "1.4rem" }}>{trophyBreakdown.weekly}</p>
              <FontAwesomeIcon
                icon={faTrophy}
                style={{ height: "4rem", width: "4rem", color: "purple" }}
              />
              <p>Weekly</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <p style={{ fontSize: "1.4rem" }}>{trophyBreakdown.monthly}</p>
              <FontAwesomeIcon
                icon={faTrophy}
                style={{ height: "4rem", width: "4rem", color: "teal" }}
              />
              <p>Monthly</p>
            </div>
          </div>
        </div>
        <Spacer y={5} />
        <LifetimeStats username={user}/>
        <Spacer y={5} />
        <WeeklyStats username={user}/>
        <Spacer y={5} />
        <LifetimeBySize username={user} />
        <Spacer y={5} />
        <LifetimeByFriends username={user} />
        <Spacer y={5} />
        <LifetimeByMixed username={user} />
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="lg"
        backdrop="blur"
        placement="center"
        hideCloseButton={true}
        style={{
          width: "70%",
          backgroundColor: "#1e1e1e",
          maxHeight: "70%",
          color: "white",
        }}
        scrollBehavior="inside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Select users
              </ModalHeader>
              <ModalBody>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "0.5rem",
                    padding: "0.5rem 0",
                  }}
                >
                  {users.map((user) => (
                    <div
                      style={{
                        width: "100%",
                        border: selectedUsers.includes(user)
                          ? "3px solid hsl(var(--nextui-primary)"
                          : "3px solid rgb(53 53 53)",
                        borderRadius: "0.5rem",
                        padding: "0.4rem 2rem 0.4rem 0.5rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      key={user}
                      onClick={() => addUserToSelected(user)}
                    >
                      <User
                        name={user}
                        description={user.description || ""}
                        style={{
                          color: "white",
                        }}
                        avatarProps={{
                          src: profilePics[user] || "", // Default image or loading state can be handled here
                        }}
                        isFocusable
                      />
                      {selectedUsers.includes(user) && (
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          size="lg"
                          color="hsl(var(--nextui-primary)"
                        />
                      )}
                    </div>
                  ))}
                </div>
              </ModalBody>
              <ModalFooter className="justify-between">
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="primary"
                  variant="solid"
                  isDisabled={selectedUsers.length !== 2}
                  onPress={() => redirectToCompare()}
                >
                  Compare
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ComingSoon;
