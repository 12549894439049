// Function to retrieve the token from cookies
const getToken = () => {
  const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
  const tokenCookie = cookies.find((cookie) =>
    cookie.startsWith("accessToken=")
  );
  return tokenCookie ? tokenCookie.split("=")[1] : null;
};

// Function to decode a JWT token
const decodeJwt = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Error decoding JWT:", error);
    return null;
  }
};

// Function to determine if the user has an admin role
export const isUserAdmin = () => {
  const token = getToken();
  if (!token) return false;

  const decodedToken = decodeJwt(token);
  if (!decodedToken) return false;

  return decodedToken.roles && decodedToken.roles.includes("ADMIN");
};

// Component that requires the user to be an admin to render children
const RequireAdmin = ({ children }) => {
  if (!isUserAdmin()) {
    // Optionally redirect or render a message instead
    return null;
  }
  return <>{children}</>;
};

export default RequireAdmin;
