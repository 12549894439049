import React, { useEffect, useRef, useState } from "react";
import { User } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import RequireAuth from "../../requireAuth";
import axios from "../../axiosInstance";

function Comment({ comment, profilePic }) {
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const iconRef = useRef(null);
  function calculateTimeAgo(time) {
    return time ? moment(time).fromNow() : "some time ago";
  }

  useEffect(() => {
    setLikeCount(comment.likes ? Object.keys(comment.likes).length : 0);
    const username = RequireAuth.getUsername();
    setIsLiked(comment.likes && comment.likes.hasOwnProperty(username));
  }, [comment]);

  const handleDoubleTap = async (type) => {
    if (type === 1 || !isLiked) {
      setLikeCount(isLiked ? likeCount - 1 : likeCount + 1);
      try {
        await axios.post(`/post/comment/toggleLike/${comment.id}`);
        setIsLiked(!isLiked);
        if (iconRef.current) {
          iconRef.current.classList.toggle("active", !isLiked);
        }
      } catch (error) {
        console.error("Error toggling like:", error);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "0rem",
          alignItems: "flex-start",
        }}
      >
        <User
          avatarProps={{
            src: profilePic.src,
          }}
          name={comment.username}
          description={calculateTimeAgo(comment.time)}
          style={{
            maxWidth: "35dvw",
            minWidth: "35dvw",
            justifyContent: "flex-start",
          }}
        />
        <p style={{ fontSize: "1.1rem", textWrap: "wrap" }}>
          {comment.comment}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
          margin: "0 0.2rem",
          paddingTop: "0.4rem",
        }}
      >
        <FontAwesomeIcon
          ref={iconRef}
          onClick={() => handleDoubleTap(1)}
          icon={isLiked ? faHeartSolid : faHeart}
          style={{ cursor: "pointer" }}
          size="sm"
          color={isLiked ? "red" : undefined}
        />
        <p style={{ fontSize: "0.7rem", color: "grey" }}>{likeCount}</p>
      </div>
    </div>
  );
}

export default Comment;
