import React, { useState, useEffect } from "react";
import axios from "../../axiosInstance";
import { Button, Input } from "@nextui-org/react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { isTokenValid } from "../../requireAuth";

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/", search: "" } }; // Default to home page, no query params

  useEffect(() => {
    const token = getCookie("accessToken");
    if (token && isTokenValid(token)) {
      navigate("/");
    }
  }, [navigate]);

  const getCookie = (name) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return "";
  };

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    console.log("Logging in...");
    try {
      // Sending a POST request to the /auth endpoint with the username and password
      const response = await axios.post("/auth/authenticate", {
        username,
        password,
      });

      // Assuming the token is in response.data.token
      const { token } = response.data;
      if (token) {
        // Setting the token as a cookie named 'accessToken'
        document.cookie = `accessToken=${token}; path=/`;
        console.log("Token set in cookies");
        toast.success("Successfully logged in", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
        setTimeout(function () {
          navigate(from.pathname + from.search);
        }, 1000); // 1000 milliseconds = 1 second
      } else {
        console.error("Token not found in response");
        toast.error("Failed to log in", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Login failed:", error);
      toast.error("Failed to log in", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "dark",
      });
    }
  };

  return (
    <div
      style={{
        maxWidth: "400px",
        margin: "0 auto",
        padding: "20px",
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "3rem",
          gap: "0.5rem",
        }}
      >
        <img style={{ height: "6rem" }} src="images/Logo (4).png" alt="logo" />
        <p
          style={{
            fontWeight: "500",
            fontSize: "2.5rem",
            lineHeight: 0,
          }}
        >
          STATS
        </p>
      </div>
      <form style={{ marginBottom: "30%" }} onSubmit={handleLogin}>
        <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
          <Input
            type="email"
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoComplete="username"
          />
          <Input
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent the default Enter key action
                handleLogin(e); // Call the login handler
              }
            }}
            autoComplete="password"
          />
        </div>
        <Button
          auto
          onClick={handleLogin}
          color="primary"
          size="lg"
          style={{ marginTop: "20px", width: "100%" }}
        >
          Log In
        </Button>
      </form>
    </div>
  );
}

export default LoginPage;
