import React, { useEffect, useState } from "react";
import axios from "../../axiosInstance";
import {
  Select,
  SelectItem,
  Switch,
  Button,
  Spacer,
  Input,
  SelectSection,
} from "@nextui-org/react";
import Navbar from "../Navbar/Navbar";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faTrophy } from "@fortawesome/free-solid-svg-icons";
import Confetti from "../Confetti/Confetti";
import imageCompression from "browser-image-compression";

function JointForm() {
  const [sizes, setSizes] = useState([]);
  const [users, setUsers] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [selectedSize, setSelectedSize] = useState("");
  const [isMixed, setIsMixed] = useState(false);
  const [withRandoms, setWithRandoms] = useState(false);
  const [selectedType, setSelectedType] = useState(""); // New state for type
  const [types, setTypes] = useState([]); // New state for types
  const [image, setImage] = useState(null); // New state for image
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [timeOfConsumption, setTimeOfConsumption] = useState(() => {
    const current = new Date();
    const localISOTime = new Date(
      current.getTime() - current.getTimezoneOffset() * 60000
    ).toISOString();
    return localISOTime.substring(0, 16); // Format to YYYY-MM-DDTHH:mm
  });
  const [selectedLocation, setSelectedLocation] = useState("");

  const [locations, setLocations] = useState({});
  const [isWithHash, setIsWithHash] = useState(false);
  const [coords, setCoords] = useState(null);
  const [useCoords, setUseCoords] = useState(false);
  const [geoAccepted, setGeoAccepted] = useState();
  const [switchKey, setSwitchKey] = useState(Date.now());
  const [permissionRequested, setPermissionRequested] = useState(false);

  const requestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    } else {
      toast.error("Geolocation is not supported by this browser.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "dark",
      });
    }
  };

  const handleSuccess = (position) => {
    setGeoAccepted(true);
    const { latitude, longitude } = position.coords;
    const coordsValue = `${latitude.toFixed(6)}, ${longitude.toFixed(6)}`;
    setCoords(coordsValue);
    setUseCoords(true);
    setSwitchKey(Date.now());
    setPermissionRequested(true);
  };

  const handleError = (error) => {
    setGeoAccepted(false);
    setPermissionRequested(true);
    console.error("Error occurred in fetching location: " + error.message);
  };

  useEffect(() => {
    if (coords && locations.coordinates) {
      setSelectedLocation(coords); // Only update if coordinates are actually set
    }
  }, [locations, coords]); // Depend on locations and coords

  useEffect(() => {
    setSizes([
      "XSMALL   |   (0.2 - 0.4)",
      "SMALL    |   (0.5 - 0.7)",
      "MEDIUM   |   (0.8 - 1)",
      "LARGE    |   (1 - 1.5)",
      "XLARGE   |   (1.6 - 2)",
      "XXLARGE  |   (2+)",
    ]);

    setTypes(["joint", "blunt", "bong", "bowl", "edible"]);

    const fetchUsers = async () => {
      try {
        const response = await axios.get("/getAllUsers");
        setUsers(response.data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    const fetchLocations = async () => {
      try {
        const response = await axios.get("/getLocations");
        // Organize locations by area
        const areaGroups = response.data.reduce((acc, location) => {
          acc[location.area] = acc[location.area] || [];
          acc[location.area].push(location);
          return acc;
        }, {});
        setLocations(areaGroups);
      } catch (error) {
        console.error("Failed to fetch locations:", error);
      }
    };

    fetchLocations();
    fetchUsers();
  }, []);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Only specify maxSizeMB to limit the file size
      const options = {
        maxSizeMB: 1, // (Max file size in MB)
        useWebWorker: true, // (Use a web worker for better performance in compressing the image)
      };
      const loadingToast = toast.loading("Compressing photo...", {
        position: "top-center",
        theme: "dark",
      });
      try {
        const compressedFile = await imageCompression(file, options);
        setImage(compressedFile); // Set the compressed image
        toast.dismiss(loadingToast);
        setTimeout(() => {
          toast.success("Image processed successfully.", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "dark",
          });
        }, 500);
      } catch (error) {
        toast.dismiss(loadingToast);
        setTimeout(() => {
          console.error("Compression Error:", error);
        }, 500);
        toast.error("Failed to process image.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }
    }
  };

  const getLocationById = (id) => {
    for (const area of Object.keys(locations)) {
      const location = locations[area].find((loc) => loc.id === id);
      if (location) return location;
    }
    return null; // Return null if not found
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    const jayCons = {
      size: selectedSize !== "" ? selectedSize.split(" ")[0] : null,
      mixed: isMixed,
      users: Array.from(selectedUsers),
      timeOfConsumption: `${timeOfConsumption}:00`,
      type: selectedType.toUpperCase(),
      withRandoms: withRandoms,
      hash: isWithHash,
    };

    if (!useCoords) {
      jayCons.location =
        selectedLocation === "" || selectedLocation.target.value === ""
          ? "Unknown"
          : getLocationById(selectedLocation.target.value).name;
    } else {
      jayCons.location = "coordinates";
      formData.append("coords", coords);
    }

    formData.append("image", image);
    formData.append("jayCons", JSON.stringify(jayCons));

    const loadingToast = toast.loading("Submitting entry...", {
      position: "top-center",
      theme: "dark",
    });

    try {
      const response = await axios.post("/stats/createEntry", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.dismiss(loadingToast);
      console.log("Submission successful " + response);
      setTimeout(() => {
        checkForNewTrophies();
        toast.success("Successfully submitted entry", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }, 500);
    } catch (error) {
      toast.dismiss(loadingToast);
      console.error("Failed to submit form:", error);

      // Extracting error message from the response
      const errorMessage =
        error.response && error.response.data
          ? error.response.data
          : "Unknown error occurred";

      setTimeout(() => {
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        });
      }, 500);
    }
  };

  const checkForNewTrophies = async () => {
    try {
      const response = await axios.get("/checkForNewTrophies", {
        params: {
          selectedUsers: Array.from(selectedUsers).join(","),
        },
      });
      const newTrophies = response.data;

      if (newTrophies.length > 0) {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 8000);
        newTrophies.forEach((trophy) => {
          const iconColor = {
            color: trophy.colour, // Make sure your API returns a valid color name or hex
          };

          toast.success(
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "white",
              }}
            >
              <span style={{ margin: "0 1rem" }}>
                {trophy.title} - {trophy.group}
              </span>
              <FontAwesomeIcon icon={faTrophy} style={iconColor} />
            </div>,
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              theme: "dark",
              style: { background: "#8031ff" },
            }
          );
        });
      }
    } catch (error) {
      console.error("Error fetching new trophies:", error);
      toast.error("Failed to fetch new trophies");
    }
  };

  // Initialize state to store maxWidth
  const [maxWidth, setMaxWidth] = useState(
    window.innerWidth > 768 ? "600px" : "300px"
  );

  // Effect to add window resize listener
  useEffect(() => {
    const handleResize = () => {
      // Update maxWidth based on window width
      setMaxWidth(window.innerWidth > 768 ? "600px" : "300px");
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array means this effect runs once on mount

  const resetImage = () => {
    setImage(null); // Reset the image state to null
    setTimeout(() => {
      document.getElementById("imageInput").value = ""; // Reset the file input field
    }, 100);
  };

  return (
    <div>
      <Navbar active={"Create entry"} />
      {showConfetti && <Confetti />}
      <div
        style={{
          maxWidth: maxWidth,
          margin: "0 auto",
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h1
          style={{
            fontSize: "2rem",
            marginBottom: "2rem",
            textAlign: "center",
          }}
        >
          Create a new entry
        </h1>
        <form onSubmit={handleSubmit}>
          <Spacer y={5} />
          <Select
            label="Select type"
            onChange={(e) => setSelectedType(e.target.value)} // Update the type state
            isRequired={true}
            listboxProps={{ className: "dark text-white" }}
            popoverProps={{ className: "dark" }}
          >
            {types.map((type) => (
              <SelectItem key={type} value={type}>
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </SelectItem>
            ))}
          </Select>
          <Spacer y={5} />
          {(selectedType === "blunt" || selectedType === "joint") && (
            <div>
              <Select
                label="Select size"
                onChange={(e) => setSelectedSize(e.target.value)}
                isRequired={true}
                listboxProps={{ className: "dark text-white" }}
                popoverProps={{ className: "dark" }}
              >
                {sizes.map((size) => (
                  <SelectItem key={size} value={size}>
                    {size}
                  </SelectItem>
                ))}
              </Select>
              <Spacer y={5} />
            </div>
          )}
          <div
            style={{
              display: "flex",
              gap: "1rem",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            {" "}
            <Switch
              checked={isWithHash}
              onChange={(e) => setIsWithHash(e.target.checked)}
              label="IsWithHash"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "0.3rem",
              }}
            >
              <p className="mr-2">Hash</p>
            </Switch>
            <Switch
              checked={isMixed}
              onChange={(e) => setIsMixed(e.target.checked)}
              label="Mixed"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "0.3rem",
              }}
            >
              <p className="mr-2">Mixed</p>
            </Switch>{" "}
            <Switch
              checked={withRandoms}
              onChange={(e) => setWithRandoms(e.target.checked)}
              label="WithRandoms"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "0.3rem",
              }}
            >
              <p className="mr-2">Randoms</p>
            </Switch>
          </div>{" "}
          <Spacer y={3} />
          <Select
            label="Select users"
            selectionMode="multiple"
            placeholder="Select users"
            selectedKeys={selectedUsers}
            onSelectionChange={setSelectedUsers}
            isRequired={true}
            listboxProps={{ className: "dark text-white" }}
            popoverProps={{ className: "dark" }}
          >
            {users.map((user) => (
              <SelectItem key={user} value={user}>
                {user}
              </SelectItem>
            ))}
          </Select>
          <Spacer y={5} />
          <Input
            label="Time of Consumption"
            type="datetime-local"
            underlined
            fullWidth
            value={timeOfConsumption}
            onChange={(e) => setTimeOfConsumption(e.target.value)}
            css={{
              maxWidth: "100%",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          />
          <Spacer y={5} />
          <div style={{ display: "flex" }}>
            {useCoords ? (
              <Input label="Coordinates" isReadOnly value={coords}></Input>
            ) : (
              <Select
                label="Select Location (optional)"
                placeholder="Select an area"
                listboxProps={{ className: "dark text-white" }}
                popoverProps={{ className: "dark" }}
                value={selectedLocation}
                onChange={setSelectedLocation}
              >
                {Object.keys(locations).map((area) => (
                  <SelectSection key={area} title={area}>
                    {locations[area].map((location) => (
                      <SelectItem key={location.id} value={location.id}>
                        {location.name}
                      </SelectItem>
                    ))}
                  </SelectSection>
                ))}
              </Select>
            )}
          </div>
          <Spacer y={5} />
          {geoAccepted && (
            <div>
              <Switch
                checked={useCoords}
                onValueChange={() => setUseCoords(!useCoords)}
                defaultChecked={useCoords}
                defaultSelected={useCoords}
                key={switchKey}
              >
                Use coordinates
              </Switch>
              <Spacer y={5} />
            </div>
          )}
          {!permissionRequested && (
            <div>
              <Button
                style={{
                  width: "100%",
                  backgroundColor:
                    "hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)))",
                  padding: "1.5rem",
                }}
                onClick={requestLocation}
              >
                Request location
              </Button>
              <Spacer y={5} />
            </div>
          )}
          {image !== null && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Input
                label="Selected Image"
                value={image.name}
                isReadOnly={true}
                endContent={
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    style={{ cursor: "pointer" }}
                    onClick={resetImage}
                  />
                }
              />
            </div>
          )}
          {!image && ( // Show this div only if image is null
            <div>
              <Button
                auto
                flat
                style={{
                  width: "100%",
                  backgroundColor:
                    "hsl(var(--nextui-default-100) / var(--nextui-default-100-opacity, var(--tw-bg-opacity)))",
                  padding: "1.5rem",
                }}
                onClick={() => document.getElementById("imageInput").click()}
              >
                Add image
              </Button>
              <input
                type="file"
                id="imageInput"
                style={{ display: "none", width: "100%" }}
                onChange={handleImageChange}
                accept="image/*"
              />
            </div>
          )}
          <Spacer y={5} />
          <Button
            type="submit"
            shadow
            color="primary"
            style={{
              width: "100%",
            }}
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
}

export default JointForm;
