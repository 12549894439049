import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import LifetimeStats from "../LifetimeStats/LifetimeStats";
import WeeklyStats from "../WeeklyStats/WeeklyStats";
import { Button, Tabs, Tab } from "@nextui-org/react";
import LifetimeBySize from "../LifetimeBySize/LifetimeBySize";
import LifetimeByFriends from "../LifetimeByFriends/LifetimeByFriends";
import LifetimeMixed from "../LifetimeMixed/LifetimeMixed";
import Highlights from "../Highlights/Highlights";
import SnusStatsWeekly from "../Snus/SnusStatsWeekly";
import SnusStatsMonthly from "../Snus/SnusStatsMonthly";
import FlavorPieChart from "../Snus/FlavorPieChart";

export default function Home() {
  const [activeTab, setActiveTab] = useState("recent"); // Step 1: Track the active tab
  const [tabStyle] = useState({
    // Destructure the useState array
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "2rem",
  });
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);

  // Define a method to render tab content based on the current active tab
  const renderTabContent = () => {
    switch (activeTab) {
      case "recent":
        return (
          <div style={tabStyle}>
            <LifetimeStats />
            <WeeklyStats />
          </div>
        );
      case "lifetime":
        return (
          <div style={tabStyle}>
            <LifetimeByFriends />
            <LifetimeMixed />
            <LifetimeBySize />
          </div>
        );
      case "snus":
        return (
          <div style={tabStyle}>
            <SnusStatsMonthly />
            <SnusStatsWeekly />
            <FlavorPieChart />
          </div>
        );
      case "highlights":
        return (
          <div style={tabStyle}>
            <Highlights />
          </div>
        );
      default:
        return null; // Render nothing for undefined tabs
    }
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "flexStart",
          padding: "5rem 0",
          justifyContent: "flex-start",
        }}
      >
        <Navbar active={"Stats"} />
        <div
          style={{ display: "flex", width: "100%", flexDirection: "column" }}
        >
          <Tabs
            variant={"solid"}
            aria-label="Tabs variants"
            value={activeTab}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "2rem 0",
            }}
            onSelectionChange={(value) => setActiveTab(value)}
          >
            <Tab
              key="recent"
              title="Recent"
              value="recent"
              style={{ fontSize: "1rem" }}
            />
            <Tab
              key="lifetime"
              title="Lifetime"
              value="lifetime"
              style={{ fontSize: "1rem" }}
            />{" "}
            <Tab
              key="snus"
              title="Snus"
              value="snus"
              style={{ fontSize: "1rem" }}
            />
            <Tab
              key="highlights"
              title="Trophies"
              value="highlights"
              style={{ fontSize: "1rem" }}
            />
          </Tabs>

          {renderTabContent()}

          {isMobile && activeTab === "recent" && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                color="primary"
                style={{
                  width: "90%",
                  borderRadius: "2rem",
                  margin: "2rem 0",
                }}
                onClick={() => {
                  window.location.href = "/createEntry";
                }}
              >
                + Create new entry
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
