import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import axios from "../../axiosInstance";

const BarChart = ({ username = "" }) => {
  const [dynamicStyle, setDynamicStyle] = useState();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Amount consumed",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    // Function to set styles based on window width
    const setInitialStyle = () => {
      const style =
        window.innerWidth < 768
          ? {
              height: "fit-content",
              width: "90%", // Default width for non-mobile devices
              padding: "2rem",
              backgroundColor: "#1e1e1e",
              borderRadius: "2rem", // Adjust the width for mobile devices
            }
          : {
              height: "fit-content",
              width: "50%", // efault width for non-mobile devices
              margin: "0rem 3rem",
              padding: "2rem",
              backgroundColor: "#1e1e1e",
              borderRadius: "2rem",
            };

      setDynamicStyle(style);
    };

    // Set the initial style based on the current window width
    setInitialStyle();
    // Note: We're not adding dynamicStyle to the dependency array to avoid re-running this effect due to style changes
  }, []); // Empty dependency array ensures this effect runs only once on mount

  useEffect(() => {
    const fetchData = async () => {
      try {
        var response = "";
        if (username !== "") {
          response = await axios.get(
            "/stats/getLifetimeBySizeAll?username=" + username
          );
        } else {
          response = await axios.get("/stats/getLifetimeBySizeAll");
        }
        const monthsData = response.data;
        const updatedLabels = ["XS", "S", "M", "L", "XL", "XXL"];
        const updatedData = Object.values(monthsData);

        const backgroundColors = [
          "rgba(156,41,234, 0.5)", // Light Purple
          "rgba(136,64,234, 0.5)", // Medium Purple
          "rgba(116,87,234, 0.5)", // Dark Purple
          "rgba(96,110,234, 0.5)", // Darker Purple
          "rgba(76,133,234, 0.5)", // Darkest Purple
          "rgba(66,156,234, 0.5)", // Purple
          "rgba(46,179,234, 0.5)", // Purple
        ];
        const borderColors = backgroundColors.map((color) =>
          color.replace(" 0.5", " 1")
        );

        setChartData({
          labels: updatedLabels,
          datasets: [
            {
              label: "Amount consumed",
              data: updatedData,
              backgroundColor: backgroundColors,
              borderColor: borderColors,
              borderWidth: 1,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching monthly stats:", error);
      }
    };

    fetchData();
  }, []);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      backgroundColor: "grey", // Here we set the background color to light grey
    },
  };

  return (
    <div style={dynamicStyle}>
      <h1 style={{ textAlign: "center" }}>Consumed by size</h1>
      <Bar data={chartData} options={options} style={{ width: "100%" }} />
    </div>
  );
};

export default BarChart;
